import React from "react";
import SymbolSelector from "../SymbolSelector";
import { activeProfile } from "../../modules/profiles";

const BlankRow = ({ onSelect }) => (
	<tr className="bg-gray-100">
		<td className="p-2" colSpan={2}>
			<SymbolSelector value={null} onChange={onSelect} />
		</td>
		<td colSpan={activeProfile.simpleRuleForm ? 11 : 11} />
	</tr>
);

export default BlankRow;
