import numeral from "numeral";
import books from "../data";

numeral.register("locale", "fr", {
	delimiters: {
		thousands: ".",
		decimal: ",",
	},
});

export const formatPrice = (id, value) =>
	books[id] && books[id].label.startsWith("F_")
		? numeral(value / 100).format("0,0.00")
		: numeral(value / 1000).format("0,0.00");

export const formatQuantity = (value) => numeral(value).format("0,0");
