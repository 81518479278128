import React, { useEffect, useMemo, useRef, useState } from "react";
import { connect } from "react-redux";
import { CircularProgress, Grid, Dialog } from "@material-ui/core";
import api from "../../api";
import LevelDetails from "./LevelDetails";
import books from "../../data";
import { formatPrice, formatQuantity } from "../../modules/formatter";
import classNames from "classnames";
import CloseIcon from "@material-ui/icons/Close";
import LevelModify from "./LevelModify";

const Level = ({ id, side, data, active, onAction }) => {
	const [anchor, setAnchor] = React.useState(null);
	const position = useMemo(() => {
		if (!anchor) return null;
		const { width, height, x, y } = anchor.getBoundingClientRect();
		return { width, left: x, top: y + height };
	}, [anchor]);

	const handleToggle = (event) =>
		setAnchor(anchor === null ? event.target.parentElement : null);

	const handleAction = (e) => {
		e.preventDefault();
		onAction(active, side, data[0]);
	};

	return (
		<React.Fragment>
			<div
				className={classNames(
					"flex border-b border-gray-300 cursor-pointer relative",
					onAction
						? `text-xs ${side === "Buy" ? "pl-6" : "pr-6"}`
						: "text-base",
					active
						? "bg-blue-600 text-white"
						: anchor !== null && "bg-gray-300",
					side === "Buy" ? "flex-row-reverse" : "flex-row"
				)}
			>
				<div
					onClick={handleToggle}
					className={classNames(
						"font-bold",
						onAction ? "py-0.5 px-1" : "py-1 px-2.5",
						side === "Sell" && "flex-grow",
						side === "Buy" && !active && "text-green-600",
						side === "Sell" && !active && "text-red-600"
					)}
				>
					{formatPrice(id, data[0])}
				</div>
				<div
					onClick={handleToggle}
					className={classNames(
						"py-0.5 px-2.5",
						side === "Buy" && "flex-grow"
					)}
				>
					<strong>{formatQuantity(data[1])}</strong>
				</div>
				{onAction && (
					<div
						className={classNames(
							"cursor-pointer w-6 text-black text-sm justify-center items-center flex select-none absolute top-0 bottom-0",
							side === "Sell"
								? "bg-red-600 right-0"
								: "bg-green-600 left-0",
							active
								? "opacity-50 hover:opacity-75"
								: "opacity-25 hover:opacity-50"
						)}
						onDoubleClick={handleAction}
					>
						{active && <CloseIcon fontSize="small" />}
					</div>
				)}

				{anchor !== null && (
					<div
						className="fixed z-30 shadow-lg bg-white"
						style={position}
					>
						<LevelDetails id={id} side={side} price={data[0]} />
					</div>
				)}
			</div>
		</React.Fragment>
	);
};

const Side = ({ id, side, data, sentOrders, onAction, lines }) => {
	const [filteredSentOrders, setFilteredSentOrders] = useState([]);

	useEffect(() => {
		setFilteredSentOrders(
			Object.values(sentOrders)
				.filter(
					(o) =>
						o.order_book_id === id &&
						o.side === side &&
						!o.cancelled &&
						o.executed_so_far < o.quantity
				)
				.map((o) => o.price)
		);
	}, [sentOrders, id, side]);

	return (
		<React.Fragment>
			{(lines ? data.slice(0, parseInt(lines)) : data).map((row) => (
				<Level
					key={row[0]}
					id={id}
					side={side}
					data={row}
					active={filteredSentOrders.indexOf(row[0]) > -1}
					onAction={onAction}
				/>
			))}
		</React.Fragment>
	);
};

const requestData = (id) => api.send("OrderBookLevels", id);

const Levels = ({
	id,
	data,
	sentOrders,
	onClose,
	onAction,
	inline,
	interval,
	lines,
}) => {
	const timer = useRef();

	useEffect(() => {
		requestData(id);
		timer.current = setInterval(() => requestData(id), interval || 1500);

		return () => clearInterval(timer.current);
	}, [id, interval]);

	const WrapperComp = inline ? "div" : Dialog;

	return (
		<WrapperComp title={books[id].label} open onClose={onClose}>
			{process.env.NODE_ENV === "development" && (
				<>
					<LevelModify id={id} side="Buy" />
					<LevelModify id={id} side="Sell" />
				</>
			)}
			{!data && <CircularProgress size={25} />}
			{data && (
				<div style={{ width: onAction ? undefined : 340 }}>
					<Grid container>
						<Grid item xs={6}>
							<Side
								side="Buy"
								id={id}
								data={data.buy}
								sentOrders={sentOrders}
								onAction={onAction}
								lines={lines}
							/>
						</Grid>
						<Grid item xs={6}>
							<Side
								side="Sell"
								id={id}
								data={data.sell}
								sentOrders={sentOrders}
								onAction={onAction}
								lines={lines}
							/>
						</Grid>
					</Grid>
				</div>
			)}
		</WrapperComp>
	);
};

const mapStateToProps = (state, { id }) => ({
	data: state.levels[id],
	sentOrders: state.sentOrders.byId,
});

export default connect(mapStateToProps)(Levels);