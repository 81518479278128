import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { CircularProgress, Grid, Dialog, Popover } from "@material-ui/core";
import api from "../../api";
import books from "../../data";
import { formatPrice } from "../../modules/formatter";
import classNames from "classnames";
import CloseIcon from "@material-ui/icons/Close";

const Trigger = ({ data }) => (
	<div>
		{data?.rule?.action?.order_book[0]} - {data.when} - {data.limit} -{" "}
		{data.action_price}
	</div>
);

const Level = ({ id, side, data, active, onAction }) => {
	const [anchorEl, setAnchorEl] = useState(null);

	const handlePopoverOpen = (event) => setAnchorEl(event.currentTarget);
	const handlePopoverClose = () => setAnchorEl(null);
	const open = Boolean(anchorEl);

	const handleAction = (e) => {
		e.preventDefault();
		onAction(active, side, data[0]);
	};

	return (
		<React.Fragment>
			<div
				className={classNames(
					"flex border-b border-gray-300 cursor-pointer",
					onAction ? "text-sm" : "text-base",
					active ? "bg-blue-600 text-white" : open && "bg-gray-300",
					side === "Buy" ? "flex-row-reverse" : "flex-row"
				)}
			>
				<div
					onClick={handlePopoverOpen}
					className={classNames(
						"font-bold py-0.5 px-2.5",
						onAction ? "py-0.5" : "py-1",
						side === "Sell" && "flex-grow",
						side === "Buy" && !active && "text-green-600",
						side === "Sell" && !active && "text-red-600"
					)}
				>
					{formatPrice(id, data[0])}
				</div>
				<div
					onClick={handlePopoverOpen}
					className={classNames(
						"py-0.5 px-2.5",
						side === "Buy" && "flex-grow"
					)}
				>
					<strong>
						{data[3].length} - {data[5].length}
					</strong>
				</div>
				{onAction && (
					<div
						className={classNames(
							"cursor-pointer w-12 text-black text-sm justify-center items-center flex select-none"
						)}
						onDoubleClick={handleAction}
					>
						{active && <CloseIcon fontSize="small" />}
					</div>
				)}
			</div>

			<Popover
				transitionDuration={0}
				open={open}
				onClose={handlePopoverClose}
				anchorEl={anchorEl}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: side === "Buy" ? "right" : "left",
				}}
				transformOrigin={{
					vertical: "top",
					horizontal: side === "Buy" ? "right" : "left",
				}}
			>
				<div>Below Single Buf: {data[2] !== null ? "Yes" : "No"}</div>
				<div>Above Single Buf: {data[4] !== null ? "Yes" : "No"}</div>
				<div>
					<strong>Below:</strong>
				</div>
				{data[3].map((t) => (
					<Trigger data={t} />
				))}
				<div>
					<strong>Above:</strong>
				</div>
				{data[5].map((t) => (
					<Trigger data={t} />
				))}
			</Popover>
		</React.Fragment>
	);
};

const Side = ({ id, side, data, onAction, lines }) => {
	return (
		<React.Fragment>
			{(lines ? data.slice(0, parseInt(lines)) : data).map((row) => (
				<Level
					key={row[0]}
					id={id}
					side={side}
					data={row}
					onAction={onAction}
				/>
			))}
		</React.Fragment>
	);
};

const requestData = (id) => api.send("OrderBookLevelsWithTriggers", id);

const Levels = ({
	id,
	data,
	sentOrders,
	onClose,
	onAction,
	inline,
	interval,
	lines,
}) => {
	const timer = useRef();

	useEffect(() => {
		requestData(id);
		timer.current = setInterval(() => requestData(id), interval || 1500);

		return () => clearInterval(timer.current);
	}, [id, interval]);

	const WrapperComp = inline ? React.Fragment : Dialog;

	return (
		<WrapperComp title={books[id].label} open onClose={onClose}>
			{!data && <CircularProgress size={25} />}
			{data && (
				<div style={{ width: onAction ? undefined : 340 }}>
					<Grid container>
						<Grid item xs={6}>
							<div>
								Last execution: {data.buyLastExecutionPrice}, Is
								active: {data.buyIsActive ? "Yes" : "No"}
							</div>
							<Side
								side="Buy"
								id={id}
								data={data.buy}
								sentOrders={sentOrders}
								onAction={onAction}
								lines={lines}
							/>
						</Grid>
						<Grid item xs={6}>
							<div>
								Last execution: {data.sellLastExecutionPrice},
								Is active: {data.sellIsActive ? "Yes" : "No"}
							</div>
							<Side
								side="Sell"
								id={id}
								data={data.sell}
								sentOrders={sentOrders}
								onAction={onAction}
								lines={lines}
							/>
						</Grid>
					</Grid>
				</div>
			)}
		</WrapperComp>
	);
};

const mapStateToProps = (state, { id }) => ({
	data: state.levelsWithTriggers[id],
});

export default connect(mapStateToProps)(Levels);