import React from "react";
import { useTable, useBlockLayout, useSortBy } from "react-table";
import { FixedSizeList } from "react-window";
import classNames from "classnames";
import books from "../../data";
import { formatPrice, formatQuantity } from "../../modules/formatter";
import moment from "moment";
import Side from "../Side";
import Actions from "./Actions";
import { AutoSizer } from "react-virtualized";
import { stringifyAccountId } from "../../data/accounts";

const DownArrow = () => (
	<svg
		className="inline w-4 h-4 ml-1"
		fill="none"
		stroke="currentColor"
		viewBox="0 0 24 24"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeWidth="2"
			d="M15 13l-3 3m0 0l-3-3m3 3V8m0 13a9 9 0 110-18 9 9 0 010 18z"
		></path>
	</svg>
);

const UpArrow = () => (
	<svg
		className="inline w-4 h-4 ml-1"
		fill="none"
		stroke="currentColor"
		viewBox="0 0 24 24"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeWidth="2"
			d="M9 11l3-3m0 0l3 3m-3-3v8m0-13a9 9 0 110 18 9 9 0 010-18z"
		></path>
	</svg>
);

const Table = ({ data }) => {
	const sortedData = React.useMemo(() => {
		return data.sort((a, b) =>
			window.BigInt(a.order_id?.toString()) >
			window.BigInt(b.order_id?.toString())
				? 0
				: -1
		);
	}, [data]);

	const columns = React.useMemo(
		() => [
			{
				Header: "Zaman",
				accessor: (row) => (
					<span title={JSON.stringify(row)}>
						{moment(row.created_at * 1000).format("HH:mm:ss")}
					</span>
				),
			},
			{
				Header: "Hesap",
				accessor: (row) => stringifyAccountId(row.account.id),
			},
			{
				Header: "Kod",
				accessor: (row) => (
					<strong>
						{books[row.order_book_id]
							? books[row.order_book_id].label
							: row.order_book_id}
					</strong>
				),
			},
			{
				Header: "A/S",
				accessor: (row) => <Side side={row.side} />,
			},
			{
				Header: "Fiyat",
				accessor: (row) => formatPrice(row.order_book_id, row.price),
			},
			{
				Header: "Miktar",
				accessor: (row) => formatQuantity(row.quantity),
			},
			{
				Header: "Gerçekleşen",
				accessor: (row) => formatQuantity(row.executed_so_far),
			},
			{
				Header: " ",
				accessor: (row) => <Actions order={row} />,
			},
		],
		[]
	);

	// useSortBy
	const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
		useTable(
			{ columns, data: sortedData, autoResetSortBy: false },
			useBlockLayout
		);

	const RenderRow = React.useCallback(
		({ index, style }) => {
			const row = rows[index];
			prepareRow(row);

			return (
				<div
					{...row.getRowProps({ style })}
					id={"order-" + row.original.order_id.toString()}
					className={index % 2 === 0 ? "bg-gray-200" : "bg-white"}
				>
					{row.cells.map((cell) => (
						<div
							{...cell.getCellProps({
								style: { height: 35, display: "flex", flex: 1 },
							})}
							className="w-auto justify-center items-center"
						>
							{cell.render("Cell")}
						</div>
					))}
				</div>
			);
		},
		[prepareRow, rows]
	);

	return (
		<AutoSizer>
			{({ height, width }) => (
				<div
					style={{
						width,
						height: height - 1,
						overflow: "hidden",
					}}
				>
					<div
						{...getTableProps()}
						className="shadow-lg overflow-hidden text-sm bg-gray-50"
					>
						{headerGroups.map((headerGroup) => (
							<div
								{...headerGroup.getHeaderGroupProps({
									style: { width: "100%" },
								})}
							>
								{headerGroup.headers.map((column, i) => (
									<div
										{...column.getHeaderProps({
											// ...column.getSortByToggleProps(),
											style: {
												display: "flex",
												flex: 1,
												cursor: "pointer",
											},
										})}
										className="h-7 pr-4 items-center justify-center bg-gray-50 font-bold text-xs"
									>
										{column.render("Header")}
										{column.isSorted ? (
											column.isSortedDesc ? (
												<DownArrow />
											) : (
												<UpArrow />
											)
										) : (
											""
										)}
									</div>
								))}
							</div>
						))}

						<div className="bg-white h-full">
							<div {...getTableBodyProps()}>
								<FixedSizeList
									height={height - 28}
									itemCount={rows.length}
									itemSize={35}
									width={width}
								>
									{RenderRow}
								</FixedSizeList>
							</div>
						</div>
					</div>
				</div>
			)}
		</AutoSizer>
	);
};

export default Table;