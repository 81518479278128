import React from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { createFilterOptions } from "@material-ui/lab/Autocomplete";
import { TextField } from "@material-ui/core";
import books from "../data";

const filterOptions = createFilterOptions({
	matchFrom: "start",
	limit: 10,
	stringify: (id) => books[id].label,
});

const SymbolSelector = ({ value, onChange }) => (
	<Autocomplete
		filterOptions={filterOptions}
		renderInput={(params) => (
			<TextField
				{...params}
				variant="outlined"
				size="small"
				style={{ width: 150, padding: 0 }}
			/>
		)}
		options={Object.keys(books)}
		autoHighlight
		blurOnSelect
		clearOnEscape
		getOptionLabel={(id) => books[id].label}
		value={value}
		onChange={(event, value) => onChange(parseInt(value))}
		onBlur={(event, value) => null}
	/>
);

export default SymbolSelector;
