import React, { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import books from "../../data";
import Table from "./Table";
import classNames from "classnames";
import { canCancel } from "../../modules/sentOrders";

const sorter = (a, b) => b.id - a.id;

const FilterRow = ({ setFilterOrderBookId, value, label, isActive }) => (
	<div
		onClick={() => setFilterOrderBookId(value === "all" ? null : value)}
		className={classNames(
			"border-b border-gray-300 cursor-pointer p-2 font-semibold text-xs",
			isActive ? "bg-blue-200" : "hover:bg-gray-200"
		)}
	>
		{label}
	</div>
);

const SentOrders = () => {
	const [filterOrderBookId, setFilterOrderBookId] = useState(null);
	const [showCancelled, setShowCancelled] = useState(false);

	const data = useSelector((state) => state.sentOrders.byId);
	const raw = useMemo(() => Object.values(data), [data]);

	const sorted = useMemo(() => {
		let sorted = raw.sort(sorter);

		if (filterOrderBookId) {
			sorted = sorted.filter(
				(o) => o.order_book_id === filterOrderBookId
			);
		}

		if (!showCancelled) {
			sorted = sorted.filter(canCancel);
		}

		return sorted;
	}, [data, showCancelled, filterOrderBookId]);

	const orderBookIds = useMemo(() => {
		const filtered = !showCancelled ? raw.filter(canCancel) : raw;
		return [...new Set(filtered.map((o) => o.order_book_id))];
	}, [raw, showCancelled]);

	return (
		<div className="flex h-full">
			<div className="w-40 bg-gray-100 border-r h-full overflow-y-auto">
				<label className="flex items-center text-xs h-7 px-2">
					<input
						type="checkbox"
						className="form-checkbox mr-1"
						checked={showCancelled}
						onChange={(e) => setShowCancelled(e.target.checked)}
					/>
					İptalleri göster
				</label>
				<FilterRow
					value="all"
					label="Tümü"
					isActive={filterOrderBookId === null}
					setFilterOrderBookId={setFilterOrderBookId}
				/>
				{orderBookIds.map((id) => (
					<FilterRow
						key={id}
						value={id}
						label={books[id].label}
						isActive={filterOrderBookId === id}
						setFilterOrderBookId={setFilterOrderBookId}
					/>
				))}
			</div>
			<div className="flex-grow overflow-hidden">
				<Table data={sorted} />
			</div>
		</div>
	);
};

export default SentOrders;