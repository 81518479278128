import { combineReducers } from "redux";
import { reducer as formReducer } from "redux-form";
import sentOrders from "../modules/sentOrders";
import levelsWithTriggers from "../modules/levelsWithTriggers";
import levels from "../modules/levels";
import levelDetails from "../modules/levelDetails";
import executor from "../modules/executor";
import morningOrders from "../modules/morningOrders";
import triggers from "../modules/triggers";
import rules from "../modules/rules";
import rahsans from "../modules/rahsans";

export default combineReducers({
	form: formReducer,
	sentOrders,
	levelsWithTriggers,
	levels,
	levelDetails,
	executor,
	morningOrders,
	triggers,
	rules,
	rahsans,
});