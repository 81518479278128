import api from "../api";
import { allowedAccounts } from "./profiles";
import SnackbarUtils from "../modules/notifications";

export const submitManualOrder = (values) => {
	const timeInForce = parseInt(values.time_in_force);
	const account = allowedAccounts[parseInt(values.account)];

	// If the time is between 09:55:00 and 10:00:00, we should set a timer to do it on 10:00:02
	// Otherwise, we should send it immediately
	const now = new Date();
	const isBetween = now.getHours() === 9 && now.getMinutes() >= 55;
	const delay = isBetween
		? new Date(now.getFullYear(), now.getMonth(), now.getDate(), 10, 0, 1) -
		  now -
		  800
		: 0;

	if (delay) {
		SnackbarUtils.warning(`Emir alındı. 10:00'da borsaya iletilecek.`);
	}

	setTimeout(() => {
		api.send("SendManualOrder", [
			parseInt(values.order_book_id),
			values.side,
			values.price,
			values.quantity,
			timeInForce,
			account,
			values.rule || null,
		]);
	}, delay);
};