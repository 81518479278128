import SnackBarUtils from "./notifications";
import books from "../data";
import { activeProfile, hasAccessToSentOrder } from "./profiles";

export const SENT_ORDER_LIST_UPDATED = "SENT_ORDER_LIST_UPDATED";
export const SENT_ORDER_UPDATED = "SENT_ORDER_UPDATED";

export const canCancel = (order) =>
	!order.cancelled && order.executed_so_far < order.quantity;

export const updateList = (orders) => ({
	type: SENT_ORDER_LIST_UPDATED,
	payload: orders,
});

export const updateOrder = (order) => (dispatch, useState) => {
	const {
		sentOrders: { byId },
	} = useState();

	// Order exists
	const currentOrder = byId[order.id];

	if (
		activeProfile.simpleRuleForm &&
		currentOrder &&
		order.quantity > 30000
	) {
		if (currentOrder.executed_so_far === 0 && order.executed_so_far > 0) {
			SnackBarUtils.warning(
				`${
					books[order.order_book_id]
						? books[order.order_book_id].label
						: order.order_book_id
				} ${
					order.side === "Buy" ? "bize gelmeye" : "bizden gitmeye"
				} başladı.`
			);
		} else if (
			currentOrder.executed_so_far < order.executed_so_far &&
			order.executed_so_far === order.quantity
		) {
			SnackBarUtils.success(
				`${
					books[order.order_book_id]
						? books[order.order_book_id].label
						: order.order_book_id
				} tamamı gerçekleşti.`
			);
		}
	}

	dispatch({
		type: SENT_ORDER_UPDATED,
		payload: order,
	});
};

const initialState = {
	byId: {},
};

export default function ordersReducer(state = initialState, action) {
	switch (action.type) {
		case "RECONNECT":
			return initialState;
		case SENT_ORDER_LIST_UPDATED:
			let byId = { ...state.byId };

			action.payload.filter(hasAccessToSentOrder).forEach((order) => {
				byId[order.id] = order;
			});

			return { ...state, byId };
		case SENT_ORDER_UPDATED:
			if (!hasAccessToSentOrder(action.payload)) {
				return state;
			}

			return {
				...state,
				byId: {
					...state.byId,
					[action.payload.id]: action.payload,
				},
			};
		default:
			return state;
	}
}
