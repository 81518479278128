import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { CircularProgress, Popover } from "@material-ui/core";
import api from "../../api";
import { formatQuantity } from "../../modules/formatter";
import classNames from "classnames";

export const requestData = (id, side, price) =>
	api.send("OrderBookLevelDetails", [id, side, price]);

const LevelDetails = ({ id, side, price, data, sentOrders }) => {
	const [sentOrderIds, setSentOrderIds] = useState([]);
	const [contextFront, setContextFront] = useState(null);
	const [contextId, setContextId] = useState(null);
	const [anchorEl, setAnchorEl] = useState(null);

	useEffect(() => {
		setSentOrderIds(
			Object.values(sentOrders)
				.filter(
					(o) => o.executed_so_far < o.quantity || o.quantity === 0
				)
				.map((o) => o.order_id.toString())
		);
	}, [sentOrders, id, side, price]);

	const timer = useRef();

	useEffect(() => {
		requestData(id, side, price);
		timer.current = setInterval(() => requestData(id, side, price), 1000);

		return () => clearInterval(timer.current);
	}, [id, side, price]);

	useEffect(() => {
		if (!data) return;
		let front = 0;
		for (let i = 0; i < 40; i++) {
			if (data[i]) {
				if (data[i][0].toString() === contextId) break;
				front += parseInt(data[i][1].toString());
			}
		}

		setContextFront(front);
	}, [contextId, data]);

	return (
		<div className="text-base overflow-auto w-40 max-h-52">
			{!data && <CircularProgress />}
			{data &&
				data.map((row, i) => (
					<div
						id={side}
						key={i}
						title={row[0].toString()}
						className={classNames(
							"bg-white py-1 px-2.5 border-b border-gray-300",
							sentOrderIds.indexOf(row[0].toString()) > -1
								? "text-white bg-blue-600"
								: "text-black"
						)}
					>
						<div
							onMouseEnter={() => {
								const el = document.getElementById(
									`order-${row[0].toString()}`
								);

								if (el)
									el.style.backgroundColor =
										"rgba(191, 219, 254, var(--tw-bg-opacity))";
							}}
							onMouseLeave={() => {
								const el = document.getElementById(
									`order-${row[0].toString()}`
								);

								if (el)
									el.style.backgroundColor = "transparent";
							}}
							onClick={(event) => {
								setAnchorEl(event.currentTarget);
								setContextId(row[0].toString());
							}}
							className="font-bold"
						>
							{formatQuantity(row[1].toString())}
						</div>
					</div>
				))}

			<Popover
				transitionDuration={0}
				open={contextId}
				onClose={() => {
					setContextId(null);
					setAnchorEl(null);
				}}
				anchorEl={anchorEl}
				anchorOrigin={{
					vertical: "center",
					horizontal: "left",
				}}
				transformOrigin={{
					vertical: "center",
					horizontal: "right",
				}}
			>
				<div style={{ padding: "5px 10px" }}>
					<div>
						<strong>
							Önünde:{" "}
							{contextFront && formatQuantity(contextFront)}
						</strong>
					</div>

					{sentOrderIds.indexOf(id) > -1 && (
						<button
							style={{
								borderTop: "1px solid #ddd",
								display: "block",
								paddingTop: 5,
								marginTop: 5,
							}}
							onClick={() =>
								api.send(id, "CancelSentOrder", sentOrders[id])
							}
						>
							İptal et
						</button>
					)}
				</div>
			</Popover>
		</div>
	);
};

const mapStateToProps = (state, { id, side, price }) => ({
	data:
		state.levelDetails[id] &&
		state.levelDetails[id][side] &&
		state.levelDetails[id][side][price],
	sentOrders: state.sentOrders.byId,
});

export default connect(mapStateToProps)(LevelDetails);