import React, { useEffect, useState } from "react";
import api from "../../api";
import store from "../../store";
import {
	TableContainer,
	Dialog,
	DialogTitle,
	Table,
	TableHead,
	TableBody,
	TableCell,
	TableRow,
} from "@material-ui/core";
import books from "../../data";
import { formatPrice, formatQuantity } from "../../modules/formatter";
import Side from "../Side";
import classNames from "classnames";
import { stringifyAccountId } from "../../data/accounts";

async function delayedLoopWithData(orders, cancel) {
	for (let i = 0; i < orders.length; i++) {
		const o = orders[i];
		if (cancel) {
			api.send("SentOrderCancel", o);
		}
		console.log("Iptal edildi:", o.id);
		await new Promise((resolve) => setTimeout(resolve, 50)); // Delay between iterations
	}
}

let localTriggered = false;

const MorningCancel = ({ onClose }) => {
	const [positions, setPositions] = useState([]);
	const [ordersToDelete, setOrdersToDelete] = useState([]);
	const [triggered, setTriggered] = useState(false);

	useEffect(() => {
		const interval = setInterval(() => {
			const activeOrders = Object.values(
				store.getState().sentOrders.byId
			).filter(
				(order) =>
					!order.cancelled && order.executed_so_far < order.quantity
			);

			activeOrders.forEach((order) => {
				api.send("OrderBookLevelDetails", [
					order.order_book_id,
					order.side,
					order.price,
				]);
			});

			// Guncel durumlari al
			const positions = activeOrders
				.map((order) => {
					const levelOrders =
						store.getState().levelDetails[order.order_book_id]?.[
							order.side
						]?.[order.price] ?? [];

					let total = 0,
						index = 0;

					for (const levelOrder of levelOrders) {
						if (
							levelOrder[0].toString() ===
							order.order_id.toString()
						) {
							return { order, total, index };
						}

						total += levelOrder[1];
						index++;
					}

					return null;
				})
				.filter((p) => p !== null)
				.sort((a, b) => {
					if (a.order.order_book_id < b.order.order_book_id) {
						return -1;
					} else if (a.order.order_book_id > b.order.order_book_id) {
						return 1;
					} else {
						return a.total - b.total;
					}
				});

			setPositions(positions);

			// Time check: if time is >= 17:08 PM local time
			if (
				!localTriggered &&
				new Date().toLocaleTimeString("tr-TR", {
					hour: "2-digit",
					minute: "2-digit",
					second: "2-digit",
				}) >= "10:00:02"
			) {
				localTriggered = true;
				setTriggered(true);

				const filtered = Object.values(
					store.getState().sentOrders.byId
				).filter(
					(order) =>
						ordersToDelete.includes(order.id) ||
						!positions.some((p) => p.order.id === order.id)
				);

				delayedLoopWithData(filtered, false);
			}
		}, 1000);

		return () => clearInterval(interval);
	}, [ordersToDelete]);

	return (
		<div style={{ width: 1000 }}>
			<Dialog maxWidth="2xl" open onClose={onClose}>
				<DialogTitle>Sabah Emir Durumu</DialogTitle>
				<TableContainer className="sent-orders" style={{ width: 600 }}>
					<Table size="small" stickyHeader>
						<TableHead>
							<TableRow>
								<TableCell>Hesap</TableCell>
								<TableCell>Tahta</TableCell>
								<TableCell>A/S</TableCell>
								<TableCell>Fiyat</TableCell>
								<TableCell>Sira</TableCell>
								<TableCell>Onundeki Lot</TableCell>
								<TableCell />
							</TableRow>
						</TableHead>
						<TableBody>
							{positions.map((position, i) => (
								<TableRow key={i}>
									<TableCell>
										{position.order.account
											? stringifyAccountId(
													position.order.account.id
											  )
											: ""}
									</TableCell>
									<TableCell title={JSON.stringify(position)}>
										{
											books[position.order.order_book_id]
												?.label
										}
									</TableCell>
									<TableCell>
										<Side side={position.order.side} />
									</TableCell>
									<TableCell>
										{formatPrice(
											position.order.order_book_id,
											position.order.price
										)}
									</TableCell>
									<TableCell>{position.index + 1}</TableCell>
									<TableCell>
										{formatQuantity(position.total)}
									</TableCell>

									<TableCell align="right">
										<button
											className={classNames(
												"text-xs py-0.5 px-1 rounded-md mx-0.5 cursor-pointer",
												ordersToDelete.includes(
													position.order.id
												)
													? "bg-red-500 text-white"
													: "bg-gray-100"
											)}
											onClick={() =>
												setOrdersToDelete(
													(ordersToDelete) =>
														ordersToDelete.includes(
															position.order.id
														)
															? ordersToDelete.filter(
																	(id) =>
																		id !==
																		position
																			.order
																			.id
															  )
															: [
																	...ordersToDelete,
																	position
																		.order
																		.id,
															  ]
												)
											}
										>
											{ordersToDelete.includes(
												position.order.id
											)
												? triggered
													? "Siliniyor..."
													: "Silinecek"
												: triggered
												? ""
												: "10:00'da Sil"}
										</button>
									</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
				</TableContainer>
			</Dialog>
		</div>
	);
};

export default MorningCancel;