import React from "react";
import { Field, getFormValues, reduxForm, submit } from "redux-form";
import PriceField from "./OrderBooks/PriceField";
import QuantityField from "./OrderBooks/QuantityField";
import { selectStyle } from "./RuleForm/index";
import classNames from "classnames";
import { useDispatch, useSelector } from "react-redux";
import Button from "./Button";
import { formatPrice, formatQuantity } from "../modules/formatter";
import books from "../data";
import { stringifyAccountId } from "../data/accounts";
import { allowedAccounts } from "../modules/profiles";
import SymbolField from "./SymbolField";

const Row = ({ children, label }) => (
	<div className="mb-4">
		<label
			htmlFor="quantity"
			className="block text-sm font-semibold text-gray-700 mb-1"
		>
			{label}
		</label>

		{children}
	</div>
);

const sideClasses = {
	Buy: "bg-green-100",
	Sell: "bg-red-100",
	ShortSell: "bg-red-200",
};

const ManualOrderForm = () => {
	const dispatch = useDispatch();
	const values = useSelector(getFormValues("manual-order"));

	const handleSubmitConfirmation = (event) => {
		event.preventDefault();

		if (
			window.confirm(
				`${books[values.order_book_id].label} için ${formatQuantity(
					values.quantity
				)} adet ${formatPrice(
					values.order_book_id,
					values.price
				)} fiyatlı ${values.time_in_force === "3" ? "KİE" : "LİMİT"} ${
					values.side === "Buy"
						? "ALIŞ"
						: values.side === "Sell"
						? "SATIŞ"
						: "AÇIĞA SATIŞ"
				} gönderilecek. Onaylıyor musunuz?`
			)
		) {
			dispatch(submit("manual-order"));
		}
	};

	if (!values) return null;

	return (
		<form
			onSubmit={handleSubmitConfirmation}
			className={classNames("p-4", sideClasses[values.side])}
		>
			<div className="flex">
				<div className="flex-1 pr-2">
					<Row label="Emir Tipi">
						<Field
							component="select"
							name="side"
							className={selectStyle}
						>
							<option value="Buy">Al</option>
							<option value="Sell">Sat</option>
							<option value="ShortSell">Açığa Sat</option>
						</Field>
					</Row>
				</div>

				<div className="flex-1 pl-2">
					<Row label="Hesap">
						<Field
							component="select"
							className={selectStyle}
							name="account"
						>
							{allowedAccounts.map((account, i) => (
								<option key={`${i}`} value={`${i}`}>
									{stringifyAccountId(account.id)}
								</option>
							))}
						</Field>
					</Row>
				</div>
			</div>

			<Row label="Sembol">
				<Field name="order_book_id" component={SymbolField} required />
			</Row>

			<Row label="Miktar">
				<QuantityField
					name="quantity"
					autoFocus={!!values.order_book_id}
					required
				/>
			</Row>
			<Row label="Fiyat">
				<PriceField
					book={books[values.order_book_id]}
					name="price"
					required
				/>
			</Row>

			<div className="flex">
				<div className="flex-1 pr-2">
					<Row label="Emir Tipi">
						<Field
							component="select"
							name="time_in_force"
							className={selectStyle}
						>
							<option value="3">KİE</option>
							<option value="0">LİM</option>
						</Field>
					</Row>
				</div>
			</div>

			<Button className="w-full" type="submit">
				Emri gönder
			</Button>
		</form>
	);
};

export default reduxForm({
	form: "manual-order",
	enableReinitialize: true,
})(ManualOrderForm);