const CLEAR_PLAN = "CLEAR_PLAN";
const UPDATE_PLAN = "UPDATE_PLAN";

export const createRandomQuantities = (total, steps, differentDigits) => {
	let center = Math.round(total / steps),
		bound = 10 ** differentDigits,
		quantities = [],
		tempTotal = 0;

	for (let i = 0; i < steps; i++) {
		const number = Math.round(center + Math.random() * 2 * bound - bound);

		if (number > 0) {
			tempTotal += number;
			quantities.push(number);
		}
	}

	// Fix last one if needed
	quantities[quantities.length - 1] += total - tempTotal;

	return quantities;
};

export const clear = (index) => ({ type: CLEAR_PLAN, payload: index });

export const updatePlan = (
	orderBookId,
	side,
	price,
	totalDuration,
	totalLot,
	steps,
	differentDigits
) => ({
	type: UPDATE_PLAN,
	payload: {
		orderBookId,
		side,
		price,
		millisecondsBetween: Math.round(totalDuration / steps),
		quantities: createRandomQuantities(totalLot, steps, differentDigits),
	},
});

const initialState = [];

export default function executorReducer(state = initialState, action) {
	switch (action.type) {
		case UPDATE_PLAN:
			return [...state, action.payload];
		case CLEAR_PLAN:
			state.splice(action.payload, 1);
			return [...state];
		default:
			return state;
	}
}
