export const stringifyAccountId = (id) => String.fromCharCode.apply(null, id);

export default {
	"DE-40": { account_type: "Derivatives", id: [68, 69, 45, 52, 48] },
	40: { account_type: "Equity", id: [52, 48] },
	598: { account_type: "Equity", id: [53, 57, 56] },
	100: { account_type: "Equity", id: [49, 48, 48] },
	27612: { account_type: "Equity", id: [50, 55, 54, 49, 50] },
	16: { account_type: "Equity", id: [49, 54] },
	1: { account_type: "Equity", id: [49] },
	195: { account_type: "Equity", id: [49, 57, 53] },
	30950: { account_type: "Equity", id: [51, 48, 57, 53, 48] },
};