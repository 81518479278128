import pay1 from "./pay1";
import pay2 from "./pay2";
import viop1 from "./viop1";
import viop2 from "./viop2";

const allBooks = [...pay1, ...pay2, ...viop1, ...viop2];

let books = {};
allBooks.forEach((p) => (books[p.value] = p));

export let booksByCode = {};
allBooks.forEach((p) => (booksByCode[p.label.split(".")[0]] = p));

export default books;
