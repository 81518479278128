import accounts, { stringifyAccountId } from "../data/accounts";

export const profiles = {
	admin: {
		allowedAccountIds: Object.keys(accounts),
		noAccountSentOrders: true,
		defaultShowCancelled: true,
		rahsan: true,
	},
	yigit: {
		allowedAccountIds: ["40"],
		simpleRuleForm: true,
	},
	yigit2: {
		allowedAccountIds: ["40"],
	},
	ahlatci: {
		allowedAccountIds: ["100", "16", "27612"],
		simpleRuleForm: true,
	},
	yfas: {
		allowedAccountIds: ["598"],
		simpleRuleForm: true,
	},
	unlu: {
		allowedAccountIds: ["30950"],
		simpleRuleForm: true,
	},
	piramit: {
		allowedAccountIds: ["195"],
		simpleRuleForm: true,
	},
};

const params = new URLSearchParams(window.location.search);
export const activeProfileId = params.get("user") || "yigit";
export const activeProfile = profiles[activeProfileId];
export const allowedAccounts = activeProfile.allowedAccountIds.map(
	(id) => accounts[id]
);

export const hasAccessToRule = (rule) =>
	activeProfile.allowedAccountIds.indexOf(
		stringifyAccountId(rule?.action?.account?.id)
	) > -1;

export const hasAccessToSentOrder = (order) =>
	!order.account
		? activeProfile.noAccountSentOrders
		: activeProfile.allowedAccountIds.indexOf(
				stringifyAccountId(order.account.id)
		  ) > -1;