import React from "react";
import { reduxForm, Field } from "redux-form";
import QuantityField from "../OrderBooks/QuantityField";
import PriceField from "../OrderBooks/PriceField";
import { Button, Dialog, DialogTitle, Grid } from "@material-ui/core";
import "./form.css";
import SymbolField from "../SymbolField";

export const required = (value) => (value ? undefined : "Required");

const Form = ({ handleSubmit, onClose }) => (
	<Dialog open onClose={onClose}>
		<DialogTitle>Zamanlayıcı</DialogTitle>
		<form className="executor-form" onSubmit={handleSubmit}>
			<Grid container>
				<Grid item xs={6}>
					<label>Sembol</label>
					<Field name="order_book_id" component={SymbolField} />
				</Grid>
				<Grid item xs={6}>
					<label>
						<Field
							name="side"
							component="input"
							type="radio"
							value="Buy"
						/>{" "}
						Alış
					</label>
					<label>
						<Field
							name="side"
							component="input"
							type="radio"
							value="Sell"
						/>{" "}
						Satış
					</label>
					<label>
						<Field
							name="side"
							component="input"
							type="radio"
							value="ShortSell"
						/>{" "}
						Açığa satış
					</label>
				</Grid>
			</Grid>

			<Grid container>
				<Grid item xs={6}>
					<label>Toplam Lot</label>
					<QuantityField name="total_lot" validate={required} />
				</Grid>
				<Grid item xs={6}>
					<label>Fiyat</label>
					<PriceField name="price" validate={required} />
				</Grid>
			</Grid>

			<Grid container>
				<Grid item xs={6}>
					<label>Kaç seferde</label>
					<QuantityField name="steps" validate={required} />
				</Grid>

				<Grid item xs={6}>
					<label>Toplam süre (dk)</label>
					<QuantityField name="total_duration" validate={required} />
				</Grid>
			</Grid>

			<div>
				<label>Farklı hane sayısı</label>
				<QuantityField name="different_digits" validate={required} />
			</div>

			<Button type="submit" color="blue" variant="contained">
				Hazırla
			</Button>
		</form>
	</Dialog>
);

export default reduxForm({
	form: "executor",
	initialValues: {
		side: "Buy",
		different_digits: 3,
	},
})(Form);