export default [
	{ label: "LIDER.E", value: 207288, partition: 0 },
	{ label: "TASCV.V", value: 405939, partition: 0 },
	{ label: "TAAAQ.V", value: 405940, partition: 0 },
	{ label: "KOIPB.V", value: 2369418, partition: 0 },
	{ label: "SANFM.E", value: 77736, partition: 0 },
	{ label: "NXSAA.V", value: 273509, partition: 0 },
	{ label: "KZIHE.V", value: 4335779, partition: 0 },
	{ label: "KZAEC.V", value: 4335780, partition: 0 },
	{ label: "IZAYZ.V", value: 6043028, partition: 0 },
	{ label: "TPAFT.V", value: 4338660, partition: 0 },
	{ label: "KZSAX.V", value: 4335781, partition: 0 },
	{ label: "IZSEB.V", value: 6043029, partition: 0 },
	{ label: "SSIIK.V", value: 929926, partition: 0 },
	{ label: "SIATV.V", value: 929928, partition: 0 },
	{ label: "SHSPO.V", value: 3420266, partition: 0 },
	{ label: "PDSAM.V", value: 2239880, partition: 0 },
	{ label: "SANKO.E", value: 77756, partition: 0 },
	{ label: "VBTYZ.E", value: 210193, partition: 0 },
	{ label: "PBIOK.V", value: 1715912, partition: 0 },
	{ label: "PBAEH.V", value: 1715914, partition: 0 },
	{ label: "YPICE.V", value: 800394, partition: 0 },
	{ label: "TAIFC.V", value: 2896306, partition: 0 },
	{ label: "YBAFS.V", value: 800396, partition: 0 },
	{ label: "KHAUT.V", value: 4859790, partition: 0 },
	{ label: "IYIVA.V", value: 8533395, partition: 0 },
	{ label: "SBIXG.V", value: 1453934, partition: 0 },
	{ label: "IZAZS.V", value: 8533396, partition: 0 },
	{ label: "KZSPM.V", value: 6826149, partition: 0 },
	{ label: "TLIKE.V", value: 405979, partition: 0 },
	{ label: "TLAEV.V", value: 405980, partition: 0 },
	{ label: "SAAPE.V", value: 1453937, partition: 0 },
	{ label: "SSIIG.V", value: 3420294, partition: 0 },
	{ label: "SARKY.E", value: 77776, partition: 0 },
	{ label: "SIAEV.V", value: 3420296, partition: 0 },
	{ label: "TOAQU.V", value: 2372341, partition: 0 },
	{ label: "KDSDD.V", value: 5449993, partition: 0 },
	{ label: "NAIAO.V", value: 535541, partition: 0 },
	{ label: "VWSBB.V", value: 3552735, partition: 0 },
	{ label: "SPSCE.V", value: 7220583, partition: 0 },
	{ label: "PTISF.V", value: 2239914, partition: 0 },
	{ label: "PESQX.V", value: 2239917, partition: 0 },
	{ label: "NVSAC.V", value: 535553, partition: 0 },
	{ label: "TKSDU.V", value: 2634345, partition: 0 },
	{ label: "PBAEJ.V", value: 4206282, partition: 0 },
	{ label: "SASA.E", value: 77796, partition: 0 },
	{ label: "PLSAK.V", value: 1977936, partition: 0 },
	{ label: "YPICI.V", value: 3290762, partition: 0 },
	{ label: "TAAQD.V", value: 5386676, partition: 0 },
	{ label: "YBAFM.V", value: 3290764, partition: 0 },
	{ label: "SLISJ.V", value: 929989, partition: 0 },
	{ label: "KDIID.V", value: 1845511, partition: 0 },
	{ label: "SLARW.V", value: 929990, partition: 0 },
	{ label: "KRAET.V", value: 1845512, partition: 0 },
	{ label: "KDSDS.V", value: 1845513, partition: 0 },
	{ label: "TLAEP.V", value: 2896348, partition: 0 },
	{ label: "TOIZC.V", value: 4862706, partition: 0 },
	{ label: "TOSCR.V", value: 4862708, partition: 0 },
	{ label: "SISDL.V", value: 5910665, partition: 0 },
	{ label: "TOARD.V", value: 4862709, partition: 0 },
	{ label: "NAIPJ.V", value: 3025909, partition: 0 },
	{ label: "SAYAS.E", value: 77816, partition: 0 },
	{ label: "PEAGC.V", value: 4730284, partition: 0 },
	{ label: "SPISE.V", value: 3158350, partition: 0 },
	{ label: "SDTTR.E", value: 77821, partition: 0 },
	{ label: "TKAUE.V", value: 5124712, partition: 0 },
	{ label: "IYIIE.V", value: 1324435, partition: 0 },
	{ label: "IZAZD.V", value: 1324436, partition: 0 },
	{ label: "SEKFK.E", value: 77836, partition: 0 },
	{ label: "TLIVM.V", value: 5386715, partition: 0 },
	{ label: "KRAEX.V", value: 4335880, partition: 0 },
	{ label: "AXADB.V", value: 1324446, partition: 0 },
	{ label: "PGASE.V", value: 2239968, partition: 0 },
	{ label: "SLACV.V", value: 3420358, partition: 0 },
	{ label: "SASQH.V", value: 6434672, partition: 0 },
	{ label: "TLAUD.V", value: 5386716, partition: 0 },
	{ label: "NASBU.V", value: 5516276, partition: 0 },
	{ label: "PESQV.V", value: 7220653, partition: 0 },
	{ label: "PBIWK.V", value: 3092168, partition: 0 },
	{ label: "SEKUR.E", value: 77856, partition: 0 },
	{ label: "KZIHM.V", value: 2107555, partition: 0 },
	{ label: "TRIAJ.V", value: 2110434, partition: 0 },
	{ label: "IYIUH.V", value: 3814803, partition: 0 },
	{ label: "KZASX.V", value: 2107556, partition: 0 },
	{ label: "MANAS.E", value: 207418, partition: 0 },
	{ label: "TSSCG.V", value: 406072, partition: 0 },
	{ label: "PGIMA.V", value: 4730335, partition: 0 },
	{ label: "PGADQ.V", value: 4730336, partition: 0 },
	{ label: "AXSAU.V", value: 3814815, partition: 0 },
	{ label: "SELEC.E", value: 77876, partition: 0 },
	{ label: "NASQA.V", value: 8006644, partition: 0 },
	{ label: "TAIFK.V", value: 668082, partition: 0 },
	{ label: "TASCX.V", value: 668083, partition: 0 },
	{ label: "TAAAS.V", value: 668084, partition: 0 },
	{ label: "KOIPD.V", value: 2631562, partition: 0 },
	{ label: "NXSAC.V", value: 535653, partition: 0 },
	{ label: "KZIHG.V", value: 4597923, partition: 0 },
	{ label: "KZAEE.V", value: 4597924, partition: 0 },
	{ label: "IZAZB.V", value: 6305172, partition: 0 },
	{ label: "TPAFV.V", value: 4600804, partition: 0 },
	{ label: "KZSAZ.V", value: 4597925, partition: 0 },
	{ label: "IZSED.V", value: 6305173, partition: 0 },
	{ label: "SELGD.E", value: 77896, partition: 0 },
	{ label: "SSIIM.V", value: 1192070, partition: 0 },
	{ label: "TPSDB.V", value: 4600807, partition: 0 },
	{ label: "SIATX.V", value: 1192072, partition: 0 },
	{ label: "SHSBC.V", value: 3682410, partition: 0 },
	{ label: "SELVA.E", value: 77906, partition: 0 },
	{ label: "PWSAK.V", value: 1978046, partition: 0 },
	{ label: "PDSAN.V", value: 2502024, partition: 0 },
	{ label: "TKIDP.V", value: 406118, partition: 0 },
	{ label: "TKAEY.V", value: 406120, partition: 0 },
	{ label: "PBIOM.V", value: 1978056, partition: 0 },
	{ label: "PBATE.V", value: 1978058, partition: 0 },
	{ label: "PBSAK.V", value: 1978059, partition: 0 },
	{ label: "YPIRB.V", value: 1062538, partition: 0 },
	{ label: "TAIFE.V", value: 3158450, partition: 0 },
	{ label: "YBAUP.V", value: 1062540, partition: 0 },
	{ label: "KHAUV.V", value: 5121934, partition: 0 },
	{ label: "SEYKM.E", value: 77926, partition: 0 },
	{ label: "SOIAB.V", value: 1716078, partition: 0 },
	{ label: "TLIKG.V", value: 668123, partition: 0 },
	{ label: "TLAEX.V", value: 668124, partition: 0 },
	{ label: "SSIII.V", value: 3682438, partition: 0 },
	{ label: "SIATR.V", value: 3682440, partition: 0 },
	{ label: "TOAQW.V", value: 2634485, partition: 0 },
	{ label: "KDSDF.V", value: 5712137, partition: 0 },
	{ label: "NAIPM.V", value: 797685, partition: 0 },
	{ label: "SILVR.E", value: 77936, partition: 0 },
	{ label: "VWSBD.V", value: 3814879, partition: 0 },
	{ label: "SPSCG.V", value: 7482727, partition: 0 },
	{ label: "PTISH.V", value: 2502058, partition: 0 },
	{ label: "NVSPA.V", value: 797697, partition: 0 },
	{ label: "TKAES.V", value: 2896488, partition: 0 },
	{ label: "TKSDW.V", value: 2896489, partition: 0 },
	{ label: "PBAEL.V", value: 4468426, partition: 0 },
	{ label: "PLSAM.V", value: 2240080, partition: 0 },
	{ label: "YPIRG.V", value: 3552906, partition: 0 },
	{ label: "YBAUJ.V", value: 3552908, partition: 0 },
	{ label: "SISE.E", value: 77956, partition: 0 },
	{ label: "SLISL.V", value: 1192133, partition: 0 },
	{ label: "KDIIF.V", value: 2107655, partition: 0 },
	{ label: "SLARY.V", value: 1192134, partition: 0 },
	{ label: "KRAEV.V", value: 2107656, partition: 0 },
	{ label: "KDSQW.V", value: 2107657, partition: 0 },
	{ label: "TLAER.V", value: 3158492, partition: 0 },
	{ label: "TOIZE.V", value: 5124850, partition: 0 },
	{ label: "TOSCT.V", value: 5124852, partition: 0 },
	{ label: "SISQX.V", value: 6172809, partition: 0 },
	{ label: "TOARF.V", value: 5124853, partition: 0 },
	{ label: "NAIPL.V", value: 3288053, partition: 0 },
	{ label: "PEAUU.V", value: 4992428, partition: 0 },
	{ label: "SPIGC.V", value: 3420494, partition: 0 },
	{ label: "SKBNK.E", value: 77976, partition: 0 },
	{ label: "TKAUG.V", value: 5386856, partition: 0 },
	{ label: "TKSDH.V", value: 5386857, partition: 0 },
	{ label: "MERCN.E", value: 207538, partition: 0 },
	{ label: "IYIIG.V", value: 1586579, partition: 0 },
	{ label: "IZAZF.V", value: 1586580, partition: 0 },
	{ label: "KRAEZ.V", value: 4598024, partition: 0 },
	{ label: "SLARS.V", value: 3682502, partition: 0 },
	{ label: "AXADD.V", value: 1586590, partition: 0 },
	{ label: "PGADI.V", value: 2502112, partition: 0 },
	{ label: "SPSCI.V", value: 273767, partition: 0 },
	{ label: "NASBW.V", value: 5778420, partition: 0 },
	{ label: "TYIAG.V", value: 1782374, partition: 0 },
	{ label: "PBIWM.V", value: 3354312, partition: 0 },
	{ label: "KHIDQ.V", value: 403338, partition: 0 },
	{ label: "KHAFP.V", value: 403342, partition: 0 },
	{ label: "KZITF.V", value: 2369699, partition: 0 },
	{ label: "TRIPG.V", value: 2372578, partition: 0 },
	{ label: "IYIVG.V", value: 4076947, partition: 0 },
	{ label: "SKTAS.E", value: 78016, partition: 0 },
	{ label: "KZASZ.V", value: 2369700, partition: 0 },
	{ label: "IZAJW.V", value: 4076948, partition: 0 },
	{ label: "TPSDM.V", value: 2372583, partition: 0 },
	{ label: "PGIZI.V", value: 4992479, partition: 0 },
	{ label: "PGASN.V", value: 4992480, partition: 0 },
	{ label: "AXASF.V", value: 4076958, partition: 0 },
	{ label: "AXSPH.V", value: 4076959, partition: 0 },
	{ label: "TAISF.V", value: 930226, partition: 0 },
	{ label: "TAAPP.V", value: 930228, partition: 0 },
	{ label: "KHIDU.V", value: 2893706, partition: 0 },
	{ label: "SMRTG.E", value: 78046, partition: 0 },
	{ label: "NXSPA.V", value: 797797, partition: 0 },
	{ label: "KZISO.V", value: 4860067, partition: 0 },
	{ label: "KHAFJ.V", value: 2893710, partition: 0 },
	{ label: "KZATB.V", value: 4860068, partition: 0 },
	{ label: "IZAKL.V", value: 6567316, partition: 0 },
	{ label: "TPAUN.V", value: 4862948, partition: 0 },
	{ label: "KZSPJ.V", value: 4860069, partition: 0 },
	{ label: "IZSEF.V", value: 6567317, partition: 0 },
	{ label: "TOILM.V", value: 406258, partition: 0 },
	{ label: "SSIUH.V", value: 1454214, partition: 0 },
	{ label: "TPSDD.V", value: 4862951, partition: 0 },
	{ label: "TOSCV.V", value: 406260, partition: 0 },
	{ label: "SNGYO.E", value: 78056, partition: 0 },
	{ label: "SHSBE.V", value: 3944554, partition: 0 },
	{ label: "PWSAM.V", value: 2240190, partition: 0 },
	{ label: "PDSAP.V", value: 2764168, partition: 0 },
	{ label: "PTICX.V", value: 273834, partition: 0 },
	{ label: "PEAFO.V", value: 273836, partition: 0 },
	{ label: "SNKRN.E", value: 78066, partition: 0 },
	{ label: "PEBAA.V", value: 273838, partition: 0 },
	{ label: "TKIDR.V", value: 668262, partition: 0 },
	{ label: "YEOTK.E", value: 210503, partition: 0 },
	{ label: "TKAFA.V", value: 668264, partition: 0 },
	{ label: "PBIWN.V", value: 2240200, partition: 0 },
	{ label: "PBATG.V", value: 2240202, partition: 0 },
	{ label: "PBSAM.V", value: 2240203, partition: 0 },
	{ label: "TAIFG.V", value: 3420594, partition: 0 },
	{ label: "YPIRD.V", value: 1324682, partition: 0 },
	{ label: "SNPAM.E", value: 78076, partition: 0 },
	{ label: "KHIXX.V", value: 5384074, partition: 0 },
	{ label: "YBAUR.V", value: 1324684, partition: 0 },
	{ label: "KHAUX.V", value: 5384078, partition: 0 },
	{ label: "SOIAD.V", value: 1978222, partition: 0 },
	{ label: "TLIKO.V", value: 930267, partition: 0 },
	{ label: "TLATU.V", value: 930268, partition: 0 },
	{ label: "SSIUD.V", value: 3944582, partition: 0 },
	{ label: "TOILB.V", value: 2896626, partition: 0 },
	{ label: "SNICA.E", value: 78086, partition: 0 },
	{ label: "TSSBW.V", value: 5648952, partition: 0 },
	{ label: "SIATT.V", value: 3944584, partition: 0 },
	{ label: "TOABY.V", value: 2896629, partition: 0 },
	{ label: "KDSDH.V", value: 5974281, partition: 0 },
	{ label: "NAIPO.V", value: 1059829, partition: 0 },
	{ label: "VWSPP.V", value: 4077023, partition: 0 },
	{ label: "SPSPY.V", value: 7744871, partition: 0 },
	{ label: "PTISJ.V", value: 2764202, partition: 0 },
	{ label: "TKAEU.V", value: 3158632, partition: 0 },
	{ label: "TKSRA.V", value: 3158633, partition: 0 },
	{ label: "MIATK.E", value: 207658, partition: 0 },
	{ label: "PBAEN.V", value: 4730570, partition: 0 },
	{ label: "PLSAN.V", value: 2502224, partition: 0 },
	{ label: "YPIRI.V", value: 3815050, partition: 0 },
	{ label: "YBAUL.V", value: 3815052, partition: 0 },
	{ label: "PGIMB.V", value: 273887, partition: 0 },
	{ label: "SLISN.V", value: 1454277, partition: 0 },
	{ label: "KDIUE.V", value: 2369799, partition: 0 },
	{ label: "SODSN.E", value: 78116, partition: 0 },
	{ label: "SLASA.V", value: 1454278, partition: 0 },
	{ label: "KRATT.V", value: 2369800, partition: 0 },
	{ label: "SASCO.V", value: 4468592, partition: 0 },
	{ label: "TLAET.V", value: 3420636, partition: 0 },
	{ label: "TOIZG.V", value: 5386994, partition: 0 },
	{ label: "SISDN.V", value: 6434953, partition: 0 },
	{ label: "TOARH.V", value: 5386997, partition: 0 },
	{ label: "SOKE.E", value: 78121, partition: 0 },
	{ label: "NAIAR.V", value: 3550197, partition: 0 },
	{ label: "SOKM.E", value: 78126, partition: 0 },
	{ label: "PTISD.V", value: 5254570, partition: 0 },
	{ label: "PEAUW.V", value: 5254572, partition: 0 },
	{ label: "SPIGE.V", value: 3682638, partition: 0 },
	{ label: "PESDC.V", value: 5254573, partition: 0 },
	{ label: "TKSDJ.V", value: 5649001, partition: 0 },
	{ label: "SONME.E", value: 78136, partition: 0 },
	{ label: "YPICA.V", value: 210570, partition: 0 },
	{ label: "IYIII.V", value: 1848723, partition: 0 },
	{ label: "IZAZH.V", value: 1848724, partition: 0 },
	{ label: "IZSEN.V", value: 1848725, partition: 0 },
	{ label: "KRATX.V", value: 4860168, partition: 0 },
	{ label: "SLARU.V", value: 3944646, partition: 0 },
	{ label: "AXADF.V", value: 1848734, partition: 0 },
	{ label: "PGADK.V", value: 2764256, partition: 0 },
	{ label: "AXSAI.V", value: 1848735, partition: 0 },
	{ label: "NASBY.V", value: 6040564, partition: 0 },
	{ label: "TYIAI.V", value: 2044518, partition: 0 },
	{ label: "KHIDS.V", value: 665482, partition: 0 },
	{ label: "KHAFR.V", value: 665486, partition: 0 },
	{ label: "KZITH.V", value: 2631843, partition: 0 },
	{ label: "TRIPI.V", value: 2634722, partition: 0 },
	{ label: "IYIVI.V", value: 4339091, partition: 0 },
	{ label: "IZAJY.V", value: 4339092, partition: 0 },
	{ label: "TPSDO.V", value: 2634727, partition: 0 },
	{ label: "SRVGY.E", value: 78176, partition: 0 },
	{ label: "PGIZK.V", value: 5254623, partition: 0 },
	{ label: "PGASP.V", value: 5254624, partition: 0 },
	{ label: "AXADM.V", value: 4339102, partition: 0 },
	{ label: "AXSPJ.V", value: 4339103, partition: 0 },
	{ label: "SUMAS.E", value: 78186, partition: 0 },
	{ label: "SUNTK.E", value: 78189, partition: 0 },
	{ label: "SUWEN.E", value: 78191, partition: 0 },
	{ label: "TAISH.V", value: 1192370, partition: 0 },
	{ label: "KHIDW.V", value: 3155850, partition: 0 },
	{ label: "TAAPR.V", value: 1192372, partition: 0 },
	{ label: "KZITB.V", value: 5122211, partition: 0 },
	{ label: "KHAFL.V", value: 3155854, partition: 0 },
	{ label: "KZATD.V", value: 5122212, partition: 0 },
	{ label: "IZAKN.V", value: 6829460, partition: 0 },
	{ label: "TPAUP.V", value: 5125092, partition: 0 },
	{ label: "KZSBB.V", value: 5122213, partition: 0 },
	{ label: "IZSRJ.V", value: 6829461, partition: 0 },
	{ label: "TOILO.V", value: 668402, partition: 0 },
	{ label: "SSIUJ.V", value: 1716358, partition: 0 },
	{ label: "TPSDF.V", value: 5125095, partition: 0 },
	{ label: "TOSCX.V", value: 668404, partition: 0 },
	{ label: "SPSBU.V", value: 5516647, partition: 0 },
	{ label: "PDSPE.V", value: 3026312, partition: 0 },
	{ label: "PWSAN.V", value: 2502334, partition: 0 },
	{ label: "PTICZ.V", value: 535978, partition: 0 },
	{ label: "PEAFT.V", value: 535980, partition: 0 },
	{ label: "TKIXV.V", value: 930406, partition: 0 },
	{ label: "TKATX.V", value: 930408, partition: 0 },
	{ label: "PBIWP.V", value: 2502344, partition: 0 },
	{ label: "PBATI.V", value: 2502346, partition: 0 },
	{ label: "PBSAN.V", value: 2502347, partition: 0 },
	{ label: "TAISB.V", value: 3682738, partition: 0 },
	{ label: "YPICK.V", value: 1586826, partition: 0 },
	{ label: "TATGD.E", value: 78236, partition: 0 },
	{ label: "SOIPA.V", value: 2240366, partition: 0 },
	{ label: "TLILB.V", value: 1192411, partition: 0 },
	{ label: "TLATW.V", value: 1192412, partition: 0 },
	{ label: "TOILD.V", value: 3158770, partition: 0 },
	{ label: "TSSBY.V", value: 5911096, partition: 0 },
	{ label: "SIAFB.V", value: 4206728, partition: 0 },
	{ label: "TOACA.V", value: 3158773, partition: 0 },
	{ label: "KDSQT.V", value: 6236425, partition: 0 },
	{ label: "MTRKS.E", value: 207803, partition: 0 },
	{ label: "SPSQA.V", value: 8007015, partition: 0 },
	{ label: "PTIDD.V", value: 3026346, partition: 0 },
	{ label: "SPIGL.V", value: 1454414, partition: 0 },
	{ label: "TAVHL.E", value: 78256, partition: 0 },
	{ label: "TKAEW.V", value: 3420776, partition: 0 },
	{ label: "PBATK.V", value: 4992714, partition: 0 },
	{ label: "PLSAP.V", value: 2764368, partition: 0 },
	{ label: "YBAUN.V", value: 4077196, partition: 0 },
	{ label: "KDIUG.V", value: 2631943, partition: 0 },
	{ label: "PGIMD.V", value: 536031, partition: 0 },
	{ label: "SLIHJ.V", value: 1716421, partition: 0 },
	{ label: "KRATV.V", value: 2631944, partition: 0 },
	{ label: "SASCQ.V", value: 4730736, partition: 0 },
	{ label: "TLATQ.V", value: 3682780, partition: 0 },
	{ label: "SISDP.V", value: 6697097, partition: 0 },
	{ label: "TBORG.E", value: 78276, partition: 0 },
	{ label: "NASCK.V", value: 3812340, partition: 0 },
	{ label: "NAIAT.V", value: 3812341, partition: 0 },
	{ label: "SPIRN.V", value: 3944782, partition: 0 },
	{ label: "PESDE.V", value: 5516717, partition: 0 },
	{ label: "PBIOH.V", value: 1388232, partition: 0 },
	{ label: "TKSDL.V", value: 5911145, partition: 0 },
	{ label: "KZIGO.V", value: 403619, partition: 0 },
	{ label: "TPICW.V", value: 406498, partition: 0 },
	{ label: "IYIIK.V", value: 2110867, partition: 0 },
	{ label: "TPAFO.V", value: 406500, partition: 0 },
	{ label: "IZSEP.V", value: 2110869, partition: 0 },
	{ label: "TCELL.E", value: 78296, partition: 0 },
	{ label: "SLIHD.V", value: 4206789, partition: 0 },
	{ label: "PGILH.V", value: 3026399, partition: 0 },
	{ label: "KRATZ.V", value: 5122312, partition: 0 },
	{ label: "SLADB.V", value: 4206790, partition: 0 },
	{ label: "PGASH.V", value: 3026400, partition: 0 },
	{ label: "AXASC.V", value: 2110878, partition: 0 },
	{ label: "AXSAK.V", value: 2110879, partition: 0 },
	{ label: "NASCA.V", value: 6302708, partition: 0 },
	{ label: "TYIPF.V", value: 2306662, partition: 0 },
	{ label: "KHIXO.V", value: 927626, partition: 0 },
	{ label: "NATEN.E", value: 207878, partition: 0 },
	{ label: "TRIAA.V", value: 2896866, partition: 0 },
	{ label: "KHAUO.V", value: 927630, partition: 0 },
	{ label: "IYIIN.V", value: 4601235, partition: 0 },
	{ label: "KZADR.V", value: 2893988, partition: 0 },
	{ label: "IZAZK.V", value: 4601236, partition: 0 },
	{ label: "TPAFI.V", value: 2896868, partition: 0 },
	{ label: "TPSDQ.V", value: 2896871, partition: 0 },
	{ label: "AXADO.V", value: 4601246, partition: 0 },
	{ label: "AXSAX.V", value: 4601247, partition: 0 },
	{ label: "TAISJ.V", value: 1454514, partition: 0 },
	{ label: "KHIDY.V", value: 3417994, partition: 0 },
	{ label: "TAAPT.V", value: 1454516, partition: 0 },
	{ label: "TRIPE.V", value: 5387234, partition: 0 },
	{ label: "KZITD.V", value: 5384355, partition: 0 },
	{ label: "KHAFN.V", value: 3417998, partition: 0 },
	{ label: "KZATF.V", value: 5384356, partition: 0 },
	{ label: "IZAKP.V", value: 7091604, partition: 0 },
	{ label: "TPAUR.V", value: 5387236, partition: 0 },
	{ label: "KZSBD.V", value: 5384357, partition: 0 },
	{ label: "IZSEH.V", value: 7091605, partition: 0 },
	{ label: "TOIZH.V", value: 930546, partition: 0 },
	{ label: "SSIUL.V", value: 1978502, partition: 0 },
	{ label: "TPSQR.V", value: 5387239, partition: 0 },
	{ label: "TSSBU.V", value: 3682872, partition: 0 },
	{ label: "SPSBW.V", value: 5778791, partition: 0 },
	{ label: "PWSAP.V", value: 2764478, partition: 0 },
	{ label: "TERA.E", value: 78371, partition: 0 },
	{ label: "PTIDB.V", value: 798122, partition: 0 },
	{ label: "PEAFV.V", value: 798124, partition: 0 },
	{ label: "TEKTU.E", value: 78376, partition: 0 },
	{ label: "TKIXX.V", value: 1192550, partition: 0 },
	{ label: "TKATZ.V", value: 1192552, partition: 0 },
	{ label: "TETMT.E", value: 78382, partition: 0 },
	{ label: "PBSAP.V", value: 2764491, partition: 0 },
	{ label: "TAISD.V", value: 3944882, partition: 0 },
	{ label: "YPICM.V", value: 1848970, partition: 0 },
	{ label: "TEZOL.E", value: 78386, partition: 0 },
	{ label: "SOIPC.V", value: 2502510, partition: 0 },
	{ label: "KDIHH.V", value: 403719, partition: 0 },
	{ label: "TLIVD.V", value: 1454555, partition: 0 },
	{ label: "TOILF.V", value: 3420914, partition: 0 },
	{ label: "KDBAB.V", value: 403722, partition: 0 },
	{ label: "TSSPV.V", value: 6173240, partition: 0 },
	{ label: "SIAFD.V", value: 4468872, partition: 0 },
	{ label: "TOACC.V", value: 3420917, partition: 0 },
	{ label: "KDSDJ.V", value: 6498569, partition: 0 },
	{ label: "PTIDF.V", value: 3288490, partition: 0 },
	{ label: "PEAFR.V", value: 3288492, partition: 0 },
	{ label: "SPIGN.V", value: 1716558, partition: 0 },
	{ label: "TKATT.V", value: 3682920, partition: 0 },
	{ label: "PBATM.V", value: 5254858, partition: 0 },
	{ label: "PLSPE.V", value: 3026512, partition: 0 },
	{ label: "TGSAS.E", value: 78416, partition: 0 },
	{ label: "YBAFU.V", value: 4339340, partition: 0 },
	{ label: "OBASE.E", value: 207978, partition: 0 },
	{ label: "KDIHL.V", value: 2894087, partition: 0 },
	{ label: "JANTS.E", value: 75546, partition: 0 },
	{ label: "PGIMF.V", value: 798175, partition: 0 },
	{ label: "SLIHL.V", value: 1978565, partition: 0 },
	{ label: "SASCS.V", value: 4992880, partition: 0 },
	{ label: "TLATS.V", value: 3944924, partition: 0 },
	{ label: "SISDR.V", value: 6959241, partition: 0 },
	{ label: "NASCM.V", value: 4074484, partition: 0 },
	{ label: "NAIPR.V", value: 4074485, partition: 0 },
	{ label: "KAPLM.E", value: 75556, partition: 0 },
	{ label: "THYAO.E", value: 78436, partition: 0 },
	{ label: "SPISA.V", value: 4206926, partition: 0 },
	{ label: "PESDG.V", value: 5778861, partition: 0 },
	{ label: "VERTU.E", value: 144662, partition: 0 },
	{ label: "TKSQX.V", value: 6173289, partition: 0 },
	{ label: "KZIHB.V", value: 665763, partition: 0 },
	{ label: "TPICY.V", value: 668642, partition: 0 },
	{ label: "IYIUA.V", value: 2373011, partition: 0 },
	{ label: "TPAFQ.V", value: 668644, partition: 0 },
	{ label: "IZSRN.V", value: 2373013, partition: 0 },
	{ label: "KAREL.E", value: 75576, partition: 0 },
	{ label: "KDIUC.V", value: 5384455, partition: 0 },
	{ label: "SLIHF.V", value: 4468933, partition: 0 },
	{ label: "PGILJ.V", value: 3288543, partition: 0 },
	{ label: "KRAUB.V", value: 5384456, partition: 0 },
	{ label: "SLADD.V", value: 4468934, partition: 0 },
	{ label: "AXADG.V", value: 2373022, partition: 0 },
	{ label: "PGASJ.V", value: 3288544, partition: 0 },
	{ label: "AXSAM.V", value: 2373023, partition: 0 },
	{ label: "NASPW.V", value: 6564852, partition: 0 },
	{ label: "ORCAY.E", value: 208023, partition: 0 },
	{ label: "TYIPH.V", value: 2568806, partition: 0 },
	{ label: "KARSN.E", value: 75596, partition: 0 },
	{ label: "TKFEN.E", value: 78476, partition: 0 },
	{ label: "KHIXQ.V", value: 1189770, partition: 0 },
	{ label: "TRIAC.V", value: 3159010, partition: 0 },
	{ label: "KHAUQ.V", value: 1189774, partition: 0 },
	{ label: "IYIJA.V", value: 4863379, partition: 0 },
	{ label: "KZADT.V", value: 3156132, partition: 0 },
	{ label: "TPAFK.V", value: 3159012, partition: 0 },
	{ label: "TPSQU.V", value: 3159015, partition: 0 },
	{ label: "AXASL.V", value: 4863390, partition: 0 },
	{ label: "AXSAZ.V", value: 4863391, partition: 0 },
	{ label: "KARTN.E", value: 75616, partition: 0 },
	{ label: "TKNSA.E", value: 78496, partition: 0 },
	{ label: "TAIEN.V", value: 1716658, partition: 0 },
	{ label: "KHIXU.V", value: 3680138, partition: 0 },
	{ label: "TAAAV.V", value: 1716660, partition: 0 },
	{ label: "KHAUK.V", value: 3680142, partition: 0 },
	{ label: "IZAKR.V", value: 7353748, partition: 0 },
	{ label: "KZSBF.V", value: 5646501, partition: 0 },
	{ label: "KATMR.E", value: 75636, partition: 0 },
	{ label: "IZSEJ.V", value: 7353749, partition: 0 },
	{ label: "SASDA.V", value: 274288, partition: 0 },
	{ label: "SAAAA.V", value: 274289, partition: 0 },
	{ label: "TOIZJ.V", value: 1192690, partition: 0 },
	{ label: "TPSDH.V", value: 5649383, partition: 0 },
	{ label: "TSSCI.V", value: 3945016, partition: 0 },
	{ label: "KAYSE.E", value: 75641, partition: 0 },
	{ label: "KCAER.E", value: 75646, partition: 0 },
	{ label: "SPSBY.V", value: 6040935, partition: 0 },
	{ label: "PWSPE.V", value: 3026622, partition: 0 },
	{ label: "TLMAN.E", value: 78526, partition: 0 },
	{ label: "PTIRW.V", value: 1060266, partition: 0 },
	{ label: "PEAFX.V", value: 1060268, partition: 0 },
	{ label: "TKIXZ.V", value: 1454694, partition: 0 },
	{ label: "TKAUB.V", value: 1454696, partition: 0 },
	{ label: "KCHOL.E", value: 75656, partition: 0 },
	{ label: "TMPOL.E", value: 78536, partition: 0 },
	{ label: "PBSPE.V", value: 3026635, partition: 0 },
	{ label: "YPICO.V", value: 2111114, partition: 0 },
	{ label: "TASCN.V", value: 4207027, partition: 0 },
	{ label: "TAAAZ.V", value: 4207028, partition: 0 },
	{ label: "SOIPE.V", value: 2764654, partition: 0 },
	{ label: "KDIHJ.V", value: 665863, partition: 0 },
	{ label: "TLIVF.V", value: 1716699, partition: 0 },
	{ label: "TOIYN.V", value: 3683058, partition: 0 },
	{ label: "TSSCA.V", value: 6435384, partition: 0 },
	{ label: "SIAFF.V", value: 4731016, partition: 0 },
	{ label: "TOAQZ.V", value: 3683061, partition: 0 },
	{ label: "KDSDL.V", value: 6760713, partition: 0 },
	{ label: "KENT.E", value: 75676, partition: 0 },
	{ label: "TMSN.E", value: 78556, partition: 0 },
	{ label: "TPYGI.F2", value: 78559, partition: 0 },
	{ label: "PTISA.V", value: 3550634, partition: 0 },
	{ label: "TPYTI.F2", value: 78561, partition: 0 },
	{ label: "PEAUJ.V", value: 3550636, partition: 0 },
	{ label: "SPISH.V", value: 1978702, partition: 0 },
	{ label: "TKATV.V", value: 3945064, partition: 0 },
	{ label: "YBAFW.V", value: 4601484, partition: 0 },
	{ label: "KERVN.E", value: 75696, partition: 0 },
	{ label: "TOASO.E", value: 78576, partition: 0 },
	{ label: "SBIXK.V", value: 5255022, partition: 0 },
	{ label: "SLITE.V", value: 2240709, partition: 0 },
	{ label: "KDIHN.V", value: 3156231, partition: 0 },
	{ label: "PGIZN.V", value: 1060319, partition: 0 },
	{ label: "TLIKI.V", value: 4207067, partition: 0 },
	{ label: "KRAEO.V", value: 3156232, partition: 0 },
	{ label: "SASQE.V", value: 5255024, partition: 0 },
	{ label: "TLAEZ.V", value: 4207068, partition: 0 },
	{ label: "SISQZ.V", value: 7221385, partition: 0 },
	{ label: "NASQB.V", value: 4336628, partition: 0 },
	{ label: "NAIPT.V", value: 4336629, partition: 0 },
	{ label: "KERVT.E", value: 75716, partition: 0 },
	{ label: "PESQS.V", value: 6041005, partition: 0 },
	{ label: "TRCAS.E", value: 78596, partition: 0 },
	{ label: "TKSDN.V", value: 6435433, partition: 0 },
	{ label: "KZISJ.V", value: 927907, partition: 0 },
	{ label: "TPIXJ.V", value: 930786, partition: 0 },
	{ label: "IYIUC.V", value: 2635155, partition: 0 },
	{ label: "TPAUI.V", value: 930788, partition: 0 },
	{ label: "KGYO.E", value: 75731, partition: 0 },
	{ label: "SLIHH.V", value: 4731077, partition: 0 },
	{ label: "PGIZC.V", value: 3550687, partition: 0 },
	{ label: "SLADF.V", value: 4731078, partition: 0 },
	{ label: "PGASL.V", value: 3550688, partition: 0 },
	{ label: "AXADI.V", value: 2635166, partition: 0 },
	{ label: "AXSAO.V", value: 2635167, partition: 0 },
	{ label: "TRGYO.E", value: 78616, partition: 0 },
	{ label: "NASCB.V", value: 6826996, partition: 0 },
	{ label: "TYIPJ.V", value: 2830950, partition: 0 },
	{ label: "TRILC.E", value: 78626, partition: 0 },
	{ label: "KHIXS.V", value: 1451914, partition: 0 },
	{ label: "TRIAE.V", value: 3421154, partition: 0 },
	{ label: "KHAUS.V", value: 1451918, partition: 0 },
	{ label: "IYIJC.V", value: 5125523, partition: 0 },
	{ label: "KZADV.V", value: 3418276, partition: 0 },
	{ label: "IZAKA.V", value: 5125524, partition: 0 },
	{ label: "TPAFM.V", value: 3421156, partition: 0 },
	{ label: "AXASN.V", value: 5125534, partition: 0 },
	{ label: "SPSCK.V", value: 3812711, partition: 0 },
	{ label: "PDSAG.V", value: 1322376, partition: 0 },
	{ label: "KLGYO.E", value: 75776, partition: 0 },
	{ label: "TYIPD.V", value: 5321318, partition: 0 },
	{ label: "TAIFA.V", value: 1978802, partition: 0 },
	{ label: "TAAAX.V", value: 1978804, partition: 0 },
	{ label: "KLKIM.E", value: 75786, partition: 0 },
	{ label: "KHAUM.V", value: 3942286, partition: 0 },
	{ label: "OZSUB.E", value: 208223, partition: 0 },
	{ label: "SBIDQ.V", value: 536430, partition: 0 },
	{ label: "IZAZL.V", value: 7615892, partition: 0 },
	{ label: "KZSPL.V", value: 5908645, partition: 0 },
	{ label: "IZSEL.V", value: 7615893, partition: 0 },
	{ label: "SASDC.V", value: 536432, partition: 0 },
	{ label: "TOIZL.V", value: 1454834, partition: 0 },
	{ label: "SSITM.V", value: 2502790, partition: 0 },
	{ label: "SAAAC.V", value: 536433, partition: 0 },
	{ label: "TPSDJ.V", value: 5911527, partition: 0 },
	{ label: "TSSCK.V", value: 4207160, partition: 0 },
	{ label: "SIAES.V", value: 2502792, partition: 0 },
	{ label: "SISDT.V", value: 2502793, partition: 0 },
	{ label: "KLMSN.E", value: 75796, partition: 0 },
	{ label: "TSGYO.E", value: 78676, partition: 0 },
	{ label: "VWSBI.V", value: 2635231, partition: 0 },
	{ label: "SPSCA.V", value: 6303079, partition: 0 },
	{ label: "PTIRY.V", value: 1322410, partition: 0 },
	{ label: "PAPIL.E", value: 208238, partition: 0 },
	{ label: "PEAUP.V", value: 1322412, partition: 0 },
	{ label: "PESDO.V", value: 1322413, partition: 0 },
	{ label: "YPIRM.V", value: 2373258, partition: 0 },
	{ label: "KLNMA.E", value: 75816, partition: 0 },
	{ label: "TASCP.V", value: 4469171, partition: 0 },
	{ label: "TSKB.E", value: 78696, partition: 0 },
	{ label: "TAABB.V", value: 4469172, partition: 0 },
	{ label: "KLRHO.E", value: 75821, partition: 0 },
	{ label: "SBIDU.V", value: 3026798, partition: 0 },
	{ label: "KDITI.V", value: 928007, partition: 0 },
	{ label: "TLIVH.V", value: 1978843, partition: 0 },
	{ label: "PCILT.E", value: 208258, partition: 0 },
	{ label: "TOIZA.V", value: 3945202, partition: 0 },
	{ label: "KLSYN.E", value: 75826, partition: 0 },
	{ label: "TSSCC.V", value: 6697528, partition: 0 },
	{ label: "SIAUB.V", value: 4993160, partition: 0 },
	{ label: "TOARB.V", value: 3945205, partition: 0 },
	{ label: "KDSDN.V", value: 7022857, partition: 0 },
	{ label: "KLSER.E", value: 75831, partition: 0 },
	{ label: "PTISC.V", value: 3812778, partition: 0 },
	{ label: "KNFRT.E", value: 75836, partition: 0 },
	{ label: "TSPOR.E", value: 78716, partition: 0 },
	{ label: "PEAUL.V", value: 3812780, partition: 0 },
	{ label: "SPISJ.V", value: 2240846, partition: 0 },
	{ label: "TKAFC.V", value: 4207208, partition: 0 },
	{ label: "KMPUR.E", value: 75846, partition: 0 },
	{ label: "IYIJG.V", value: 406931, partition: 0 },
	{ label: "IZAKE.V", value: 406932, partition: 0 },
	{ label: "IZBAB.V", value: 406934, partition: 0 },
	{ label: "YBAUT.V", value: 4863628, partition: 0 },
	{ label: "PGIQA.V", value: 1322463, partition: 0 },
	{ label: "SLITG.V", value: 2502853, partition: 0 },
	{ label: "KDIIA.V", value: 3418375, partition: 0 },
	{ label: "TLIKK.V", value: 4469211, partition: 0 },
	{ label: "PGADC.V", value: 1322464, partition: 0 },
	{ label: "KRAEQ.V", value: 3418376, partition: 0 },
	{ label: "TTKOM.E", value: 78736, partition: 0 },
	{ label: "SASCU.V", value: 5517168, partition: 0 },
	{ label: "TLAFB.V", value: 4469212, partition: 0 },
	{ label: "KONTR.E", value: 75866, partition: 0 },
	{ label: "PESDI.V", value: 6303149, partition: 0 },
	{ label: "KONYA.E", value: 75876, partition: 0 },
	{ label: "TKSDP.V", value: 6697577, partition: 0 },
	{ label: "TTRAK.E", value: 78756, partition: 0 },
	{ label: "KZISL.V", value: 1190051, partition: 0 },
	{ label: "TPIXL.V", value: 1192930, partition: 0 },
	{ label: "IYIVB.V", value: 2897299, partition: 0 },
	{ label: "TPAUK.V", value: 1192932, partition: 0 },
	{ label: "KONKA.E", value: 75886, partition: 0 },
	{ label: "TUCLK.E", value: 78766, partition: 0 },
	{ label: "SLITA.V", value: 4993221, partition: 0 },
	{ label: "PGIZE.V", value: 3812831, partition: 0 },
	{ label: "SLASC.V", value: 4993222, partition: 0 },
	{ label: "AXADK.V", value: 2897310, partition: 0 },
	{ label: "AXSPF.V", value: 2897311, partition: 0 },
	{ label: "KOPOL.E", value: 75891, partition: 0 },
	{ label: "NASCD.V", value: 7089140, partition: 0 },
	{ label: "KORDS.E", value: 75896, partition: 0 },
	{ label: "TYIAB.V", value: 3093094, partition: 0 },
	{ label: "KOIAB.V", value: 1714058, partition: 0 },
	{ label: "TRIPB.V", value: 3683298, partition: 0 },
	{ label: "IYIJE.V", value: 5387667, partition: 0 },
	{ label: "KZASS.V", value: 3680420, partition: 0 },
	{ label: "IZAKC.V", value: 5387668, partition: 0 },
	{ label: "TPAUE.V", value: 3683300, partition: 0 },
	{ label: "SSIHO.V", value: 274566, partition: 0 },
	{ label: "KOZAA.E", value: 75916, partition: 0 },
	{ label: "TUKAS.E", value: 78796, partition: 0 },
	{ label: "SIAEW.V", value: 274568, partition: 0 },
	{ label: "SSBAA.V", value: 274570, partition: 0 },
	{ label: "VWSBG.V", value: 407007, partition: 0 },
	{ label: "AXASP.V", value: 5387678, partition: 0 },
	{ label: "SPSCM.V", value: 4074855, partition: 0 },
	{ label: "PDSAI.V", value: 1584520, partition: 0 },
	{ label: "KOZAL.E", value: 75936, partition: 0 },
	{ label: "TUPRS.E", value: 78816, partition: 0 },
	{ label: "TAIRV.V", value: 2240946, partition: 0 },
	{ label: "TAAPU.V", value: 2240948, partition: 0 },
	{ label: "KHAFT.V", value: 4204430, partition: 0 },
	{ label: "SBIDS.V", value: 798574, partition: 0 },
	{ label: "IZAZN.V", value: 7878036, partition: 0 },
	{ label: "KZSBH.V", value: 6170789, partition: 0 },
	{ label: "IZSRL.V", value: 7878037, partition: 0 },
	{ label: "TURGG.E", value: 78826, partition: 0 },
	{ label: "SASDE.V", value: 798576, partition: 0 },
	{ label: "SSITO.V", value: 2764934, partition: 0 },
	{ label: "SAAAE.V", value: 798577, partition: 0 },
	{ label: "TPSDL.V", value: 6173671, partition: 0 },
	{ label: "TSSPZ.V", value: 4469304, partition: 0 },
	{ label: "SIATZ.V", value: 2764936, partition: 0 },
	{ label: "TOABU.V", value: 1716981, partition: 0 },
	{ label: "SISDV.V", value: 2764937, partition: 0 },
	{ label: "VWSAX.V", value: 2897375, partition: 0 },
	{ label: "SPSPW.V", value: 6565223, partition: 0 },
	{ label: "TURSG.E", value: 78836, partition: 0 },
	{ label: "PTIDH.V", value: 1584554, partition: 0 },
	{ label: "PEAUR.V", value: 1584556, partition: 0 },
	{ label: "PESDQ.V", value: 1584557, partition: 0 },
	{ label: "UFUK.E", value: 78846, partition: 0 },
	{ label: "PLSAG.V", value: 1322576, partition: 0 },
	{ label: "YPIRO.V", value: 2635402, partition: 0 },
	{ label: "TASCQ.V", value: 4731315, partition: 0 },
	{ label: "TAABD.V", value: 4731316, partition: 0 },
	{ label: "ULAS.E", value: 78856, partition: 0 },
	{ label: "KDITK.V", value: 1190151, partition: 0 },
	{ label: "SBIDW.V", value: 3288942, partition: 0 },
	{ label: "SLIGN.V", value: 274629, partition: 0 },
	{ label: "TLIVN.V", value: 2240987, partition: 0 },
	{ label: "SLACW.V", value: 274630, partition: 0 },
	{ label: "SSIUF.V", value: 5255302, partition: 0 },
	{ label: "TOILG.V", value: 4207346, partition: 0 },
	{ label: "TSSCE.V", value: 6959672, partition: 0 },
	{ label: "SIAUD.V", value: 5255304, partition: 0 },
	{ label: "TOSCN.V", value: 4207348, partition: 0 },
	{ label: "SISDG.V", value: 5255305, partition: 0 },
	{ label: "TOACD.V", value: 4207349, partition: 0 },
	{ label: "KDSQV.V", value: 7285001, partition: 0 },
	{ label: "NAIAI.V", value: 2370549, partition: 0 },
	{ label: "PEAUN.V", value: 4074924, partition: 0 },
	{ label: "SPIGH.V", value: 2502990, partition: 0 },
	{ label: "KRDMA.E", value: 75996, partition: 0 },
	{ label: "MTSAE.V", value: 404202, partition: 0 },
	{ label: "ULKER.E", value: 78876, partition: 0 },
	{ label: "TKAFE.V", value: 4469352, partition: 0 },
	{ label: "ULUFA.E", value: 78879, partition: 0 },
	{ label: "IYIJI.V", value: 669075, partition: 0 },
	{ label: "ULUSE.E", value: 78881, partition: 0 },
	{ label: "IZAKG.V", value: 669076, partition: 0 },
	{ label: "IZBAD.V", value: 669078, partition: 0 },
	{ label: "ULUUN.E", value: 78884, partition: 0 },
	{ label: "YBAUV.V", value: 5125772, partition: 0 },
	{ label: "UMPAS.E", value: 78886, partition: 0 },
	{ label: "KDITO.V", value: 3680519, partition: 0 },
	{ label: "SLITI.V", value: 2764997, partition: 0 },
	{ label: "TLIKM.V", value: 4731355, partition: 0 },
	{ label: "KRATO.V", value: 3680520, partition: 0 },
	{ label: "PGADE.V", value: 1584608, partition: 0 },
	{ label: "SASCW.V", value: 5779312, partition: 0 },
	{ label: "TLAFD.V", value: 4731356, partition: 0 },
	{ label: "KRDMB.E", value: 76016, partition: 0 },
	{ label: "PLTUR.E", value: 208453, partition: 0 },
	{ label: "PESDK.V", value: 6565293, partition: 0 },
	{ label: "UNLU.E", value: 78906, partition: 0 },
	{ label: "TKSDR.V", value: 6959721, partition: 0 },
	{ label: "KZISN.V", value: 1452195, partition: 0 },
	{ label: "TPIXN.V", value: 1455074, partition: 0 },
	{ label: "KZADX.V", value: 1452196, partition: 0 },
	{ label: "IYIVD.V", value: 3159443, partition: 0 },
	{ label: "TPAUM.V", value: 1455076, partition: 0 },
	{ label: "KRDMD.E", value: 76036, partition: 0 },
	{ label: "USAK.E", value: 78916, partition: 0 },
	{ label: "SLITC.V", value: 5255365, partition: 0 },
	{ label: "PGIZG.V", value: 4074975, partition: 0 },
	{ label: "SLASE.V", value: 5255366, partition: 0 },
	{ label: "PGASG.V", value: 4074976, partition: 0 },
	{ label: "AXASH.V", value: 3159454, partition: 0 },
	{ label: "AXSAP.V", value: 3159455, partition: 0 },
	{ label: "KRGYO.E", value: 76046, partition: 0 },
	{ label: "NASCF.V", value: 7351284, partition: 0 },
	{ label: "KRONT.E", value: 76056, partition: 0 },
	{ label: "TYIAD.V", value: 3355238, partition: 0 },
	{ label: "KOIAD.V", value: 1976202, partition: 0 },
	{ label: "KRPLS.E", value: 76066, partition: 0 },
	{ label: "IYIUJ.V", value: 5649811, partition: 0 },
	{ label: "KZASU.V", value: 3942564, partition: 0 },
	{ label: "IZAYW.V", value: 5649812, partition: 0 },
	{ label: "TPAUG.V", value: 3945444, partition: 0 },
	{ label: "KZSAU.V", value: 3942565, partition: 0 },
	{ label: "SSIIB.V", value: 536710, partition: 0 },
	{ label: "SIAEY.V", value: 536712, partition: 0 },
	{ label: "SHSAY.V", value: 3027050, partition: 0 },
	{ label: "SPSQB.V", value: 4336999, partition: 0 },
	{ label: "PDSAJ.V", value: 1846664, partition: 0 },
	{ label: "PRDGS.E", value: 208518, partition: 0 },
	{ label: "PBAEE.V", value: 1322698, partition: 0 },
	{ label: "TAIRX.V", value: 2503090, partition: 0 },
	{ label: "YPICB.V", value: 407178, partition: 0 },
	{ label: "TAAPW.V", value: 2503092, partition: 0 },
	{ label: "YBAFP.V", value: 407180, partition: 0 },
	{ label: "KRSTL.E", value: 76096, partition: 0 },
	{ label: "USDTR.F", value: 78976, partition: 0 },
	{ label: "KHAFV.V", value: 4466574, partition: 0 },
	{ label: "IYIUM.V", value: 8140179, partition: 0 },
	{ label: "SBIXD.V", value: 1060718, partition: 0 },
	{ label: "IZAZP.V", value: 8140180, partition: 0 },
	{ label: "KZSBJ.V", value: 6432933, partition: 0 },
	{ label: "SASQI.V", value: 1060720, partition: 0 },
	{ label: "SAAPB.V", value: 1060721, partition: 0 },
	{ label: "SSIUB.V", value: 3027078, partition: 0 },
	{ label: "TPSQT.V", value: 6435815, partition: 0 },
	{ label: "TOABW.V", value: 1979125, partition: 0 },
	{ label: "SISDX.V", value: 3027081, partition: 0 },
	{ label: "VWSAZ.V", value: 3159519, partition: 0 },
	{ label: "SPSCB.V", value: 6827367, partition: 0 },
	{ label: "PTIDJ.V", value: 1846698, partition: 0 },
	{ label: "PESDS.V", value: 1846701, partition: 0 },
	{ label: "KRTEK.E", value: 76116, partition: 0 },
	{ label: "PLSAI.V", value: 1584720, partition: 0 },
	{ label: "YPICF.V", value: 2897546, partition: 0 },
	{ label: "KRVGD.E", value: 76126, partition: 0 },
	{ label: "TASCS.V", value: 4993459, partition: 0 },
	{ label: "TAAQA.V", value: 4993460, partition: 0 },
	{ label: "YBAFJ.V", value: 2897548, partition: 0 },
	{ label: "SBIXH.V", value: 3551086, partition: 0 },
	{ label: "SLIHA.V", value: 536773, partition: 0 },
	{ label: "KDITM.V", value: 1452295, partition: 0 },
	{ label: "SLACY.V", value: 536774, partition: 0 },
	{ label: "KRAEN.V", value: 1452296, partition: 0 },
	{ label: "TLIYA.V", value: 2503131, partition: 0 },
	{ label: "KDSDP.V", value: 1452297, partition: 0 },
	{ label: "TLATY.V", value: 2503132, partition: 0 },
	{ label: "TOILI.V", value: 4469490, partition: 0 },
	{ label: "KSTUR.E", value: 76136, partition: 0 },
	{ label: "TSSPX.V", value: 7221816, partition: 0 },
	{ label: "TOSCP.V", value: 4469492, partition: 0 },
	{ label: "SISDI.V", value: 5517449, partition: 0 },
	{ label: "TOACF.V", value: 4469493, partition: 0 },
	{ label: "NAIAK.V", value: 2632693, partition: 0 },
	{ label: "PEAFZ.V", value: 4337068, partition: 0 },
	{ label: "SPIGJ.V", value: 2765134, partition: 0 },
	{ label: "MTSAG.V", value: 666346, partition: 0 },
	{ label: "TKAFG.V", value: 4731496, partition: 0 },
	{ label: "IYIJK.V", value: 931219, partition: 0 },
	{ label: "IZAKI.V", value: 931220, partition: 0 },
	{ label: "UZERB.E", value: 79036, partition: 0 },
	{ label: "YPIRK.V", value: 5387914, partition: 0 },
	{ label: "IZBPB.V", value: 931222, partition: 0 },
	{ label: "YBAUX.V", value: 5387916, partition: 0 },
	{ label: "QUAGR.E", value: 208595, partition: 0 },
	{ label: "TLIVJ.V", value: 4993499, partition: 0 },
	{ label: "KRATQ.V", value: 3942664, partition: 0 },
	{ label: "PGADG.V", value: 1846752, partition: 0 },
	{ label: "SLACS.V", value: 3027142, partition: 0 },
	{ label: "SASCY.V", value: 6041456, partition: 0 },
	{ label: "TLAUA.V", value: 4993500, partition: 0 },
	{ label: "KUVVA.E", value: 76171, partition: 0 },
	{ label: "KUTPO.E", value: 76176, partition: 0 },
	{ label: "VAKBN.E", value: 79056, partition: 0 },
	{ label: "PESDM.V", value: 6827437, partition: 0 },
	{ label: "PBIOI.V", value: 2698952, partition: 0 },
	{ label: "TKSQZ.V", value: 7221865, partition: 0 },
	{ label: "KZIHJ.V", value: 1714339, partition: 0 },
	{ label: "TRIAG.V", value: 1717218, partition: 0 },
	{ label: "IYIUE.V", value: 3421587, partition: 0 },
	{ label: "KZADZ.V", value: 1714340, partition: 0 },
	{ label: "KUYAS.E", value: 76196, partition: 0 },
	{ label: "VAKFN.E", value: 79076, partition: 0 },
	{ label: "PGILM.V", value: 4337119, partition: 0 },
	{ label: "PGADN.V", value: 4337120, partition: 0 },
	{ label: "AXASJ.V", value: 3421598, partition: 0 },
	{ label: "AXSAR.V", value: 3421599, partition: 0 },
	{ label: "RNPOL.E", value: 208638, partition: 0 },
	{ label: "NASCH.V", value: 7613428, partition: 0 },
	{ label: "KZBGY.E", value: 76206, partition: 0 },
	{ label: "TYIPA.V", value: 3617382, partition: 0 },
	{ label: "TAIFH.V", value: 274866, partition: 0 },
	{ label: "TASCU.V", value: 274867, partition: 0 },
	{ label: "TAAAP.V", value: 274868, partition: 0 },
	{ label: "KOIPA.V", value: 2238346, partition: 0 },
	{ label: "VAKKO.E", value: 79096, partition: 0 },
	{ label: "KZIHD.V", value: 4204707, partition: 0 },
	{ label: "IYIUL.V", value: 5911955, partition: 0 },
	{ label: "KZAEB.V", value: 4204708, partition: 0 },
	{ label: "IZAYY.V", value: 5911956, partition: 0 },
	{ label: "TPAFS.V", value: 4207588, partition: 0 },
	{ label: "KZSAW.V", value: 4204709, partition: 0 },
	{ label: "SSIIJ.V", value: 798854, partition: 0 },
	{ label: "LIDFA.E", value: 76226, partition: 0 },
	{ label: "SIAFA.V", value: 798856, partition: 0 },
	{ label: "SHSBA.V", value: 3289194, partition: 0 },
	{ label: "PDSAL.V", value: 2108808, partition: 0 },
	{ label: "LINK.E", value: 76236, partition: 0 },
	{ label: "VANGD.E", value: 79116, partition: 0 },
	{ label: "PBAEG.V", value: 1584842, partition: 0 },
	{ label: "YPICD.V", value: 669322, partition: 0 },
	{ label: "TAIRZ.V", value: 2765234, partition: 0 },
	{ label: "YBAFR.V", value: 669324, partition: 0 },
	{ label: "TAAPY.V", value: 2765236, partition: 0 },
	{ label: "KHAFX.V", value: 4728718, partition: 0 },
	{ label: "IYIUO.V", value: 8402323, partition: 0 },
	{ label: "SBIXF.V", value: 1322862, partition: 0 },
	{ label: "IZAZR.V", value: 8402324, partition: 0 },
	{ label: "KZSBL.V", value: 6695077, partition: 0 },
	{ label: "LKMNH.E", value: 76256, partition: 0 },
	{ label: "TLAEU.V", value: 274908, partition: 0 },
	{ label: "VERUS.E", value: 79136, partition: 0 },
	{ label: "SAAPD.V", value: 1322865, partition: 0 },
	{ label: "SSIIF.V", value: 3289222, partition: 0 },
	{ label: "SIAEU.V", value: 3289224, partition: 0 },
	{ label: "TOAQT.V", value: 2241269, partition: 0 },
	{ label: "SISRB.V", value: 3289225, partition: 0 },
	{ label: "KDSDC.V", value: 5318921, partition: 0 },
	{ label: "NASCJ.V", value: 404468, partition: 0 },
	{ label: "NAIAN.V", value: 404469, partition: 0 },
	{ label: "VWSPO.V", value: 3421663, partition: 0 },
	{ label: "SPSCD.V", value: 7089511, partition: 0 },
	{ label: "PTIDL.V", value: 2108842, partition: 0 },
	{ label: "PESQW.V", value: 2108845, partition: 0 },
	{ label: "NVSAB.V", value: 404481, partition: 0 },
	{ label: "LOGO.E", value: 76276, partition: 0 },
	{ label: "TKSDT.V", value: 2503273, partition: 0 },
	{ label: "VESBE.E", value: 79156, partition: 0 },
	{ label: "PLSAJ.V", value: 1846864, partition: 0 },
	{ label: "YPICH.V", value: 3159690, partition: 0 },
	{ label: "TASQE.V", value: 5255603, partition: 0 },
	{ label: "TAAQC.V", value: 5255604, partition: 0 },
	{ label: "YBAFL.V", value: 3159692, partition: 0 },
	{ label: "SBIXJ.V", value: 3813230, partition: 0 },
	{ label: "SLIHC.V", value: 798917, partition: 0 },
	{ label: "KDIIC.V", value: 1714439, partition: 0 },
	{ label: "SLADA.V", value: 798918, partition: 0 },
	{ label: "KRAES.V", value: 1714440, partition: 0 },
	{ label: "TLIYC.V", value: 2765275, partition: 0 },
	{ label: "KDSDR.V", value: 1714441, partition: 0 },
	{ label: "TOILK.V", value: 4731634, partition: 0 },
	{ label: "TOSCQ.V", value: 4731636, partition: 0 },
	{ label: "SISDK.V", value: 5779593, partition: 0 },
	{ label: "TOACH.V", value: 4731637, partition: 0 },
	{ label: "LUKSK.E", value: 76296, partition: 0 },
	{ label: "NAIPI.V", value: 2894837, partition: 0 },
	{ label: "VESTL.E", value: 79176, partition: 0 },
	{ label: "MACKO.E", value: 76301, partition: 0 },
	{ label: "PEAGB.V", value: 4599212, partition: 0 },
	{ label: "SPISD.V", value: 3027278, partition: 0 },
	{ label: "SAFKR.E", value: 208738, partition: 0 },
	{ label: "MAKIM.E", value: 76306, partition: 0 },
	{ label: "TKAUD.V", value: 4993640, partition: 0 },
	{ label: "IYIJM.V", value: 1193363, partition: 0 },
	{ label: "IZAKK.V", value: 1193364, partition: 0 },
	{ label: "MAALT.E", value: 76316, partition: 0 },
	{ label: "SBICZ.V", value: 208750, partition: 0 },
	{ label: "VKFYO.E", value: 79196, partition: 0 },
	{ label: "TLIVL.V", value: 5255643, partition: 0 },
	{ label: "KRAEW.V", value: 4204808, partition: 0 },
	{ label: "PGASD.V", value: 2108896, partition: 0 },
	{ label: "SLACU.V", value: 3289286, partition: 0 },
	{ label: "SASQG.V", value: 6303600, partition: 0 },
	{ label: "TLAUC.V", value: 5255644, partition: 0 },
	{ label: "MAGEN.E", value: 76326, partition: 0 },
	{ label: "MAKTK.E", value: 76336, partition: 0 },
	{ label: "PESQU.V", value: 7089581, partition: 0 },
	{ label: "VKGYO.E", value: 79216, partition: 0 },
	{ label: "PBIWJ.V", value: 2961096, partition: 0 },
	{ label: "KZIHL.V", value: 1976483, partition: 0 },
	{ label: "TRIAI.V", value: 1979362, partition: 0 },
	{ label: "IYIUG.V", value: 3683731, partition: 0 },
	{ label: "KZASW.V", value: 1976484, partition: 0 },
	{ label: "TSSCF.V", value: 275000, partition: 0 },
	{ label: "PGILO.V", value: 4599263, partition: 0 },
	{ label: "PGADP.V", value: 4599264, partition: 0 },
	{ label: "AXSAT.V", value: 3683743, partition: 0 },
	{ label: "MARKA.E", value: 76356, partition: 0 },
	{ label: "VKING.E", value: 79236, partition: 0 },
	{ label: "NASPZ.V", value: 7875572, partition: 0 },
	{ label: "TAIFJ.V", value: 537010, partition: 0 },
	{ label: "TASCW.V", value: 537011, partition: 0 },
	{ label: "TAAAR.V", value: 537012, partition: 0 },
	{ label: "KOIPC.V", value: 2500490, partition: 0 },
	{ label: "NXSAB.V", value: 404581, partition: 0 },
	{ label: "KZIHF.V", value: 4466851, partition: 0 },
	{ label: "MARTI.E", value: 76376, partition: 0 },
	{ label: "KZAED.V", value: 4466852, partition: 0 },
	{ label: "IZAZA.V", value: 6174100, partition: 0 },
	{ label: "TPAFU.V", value: 4469732, partition: 0 },
	{ label: "KZSAY.V", value: 4466853, partition: 0 },
	{ label: "IZSEC.V", value: 6174101, partition: 0 },
	{ label: "SSIIL.V", value: 1060998, partition: 0 },
	{ label: "TPSDA.V", value: 4469735, partition: 0 },
	{ label: "SIATW.V", value: 1061000, partition: 0 },
	{ label: "SHSBB.V", value: 3551338, partition: 0 },
	{ label: "MAVI.E", value: 76386, partition: 0 },
	{ label: "PWSAJ.V", value: 1846974, partition: 0 },
	{ label: "PDSPD.V", value: 2370952, partition: 0 },
	{ label: "TKAEX.V", value: 275048, partition: 0 },
	{ label: "PBIOL.V", value: 1846984, partition: 0 },
	{ label: "PBAEI.V", value: 1846986, partition: 0 },
	{ label: "PBSAJ.V", value: 1846987, partition: 0 },
	{ label: "YPIRA.V", value: 931466, partition: 0 },
	{ label: "TAIFD.V", value: 3027378, partition: 0 },
	{ label: "YBAUO.V", value: 931468, partition: 0 },
	{ label: "MEDTR.E", value: 76406, partition: 0 },
	{ label: "KHAUU.V", value: 4990862, partition: 0 },
	{ label: "SOIAA.V", value: 1585006, partition: 0 },
	{ label: "KZSPN.V", value: 6957221, partition: 0 },
	{ label: "TLIKF.V", value: 537051, partition: 0 },
	{ label: "TLAEW.V", value: 537052, partition: 0 },
	{ label: "SSIIH.V", value: 3551366, partition: 0 },
	{ label: "SIATQ.V", value: 3551368, partition: 0 },
	{ label: "MEGAP.E", value: 76416, partition: 0 },
	{ label: "TOAQV.V", value: 2503413, partition: 0 },
	{ label: "KDSDE.V", value: 5581065, partition: 0 },
	{ label: "NAIAP.V", value: 666613, partition: 0 },
	{ label: "VWSBC.V", value: 3683807, partition: 0 },
	{ label: "SPSCF.V", value: 7351655, partition: 0 },
	{ label: "PTISG.V", value: 2370986, partition: 0 },
	{ label: "NVSAD.V", value: 666625, partition: 0 },
	{ label: "TKSDV.V", value: 2765417, partition: 0 },
	{ label: "PBAEK.V", value: 4337354, partition: 0 },
	{ label: "PLSAL.V", value: 2109008, partition: 0 },
	{ label: "YPICJ.V", value: 3421834, partition: 0 },
	{ label: "YBAFN.V", value: 3421836, partition: 0 },
	{ label: "SLISK.V", value: 1061061, partition: 0 },
	{ label: "KDIIE.V", value: 1976583, partition: 0 },
	{ label: "SLARX.V", value: 1061062, partition: 0 },
	{ label: "KRAEU.V", value: 1976584, partition: 0 },
	{ label: "KDSDT.V", value: 1976585, partition: 0 },
	{ label: "TLAEQ.V", value: 3027420, partition: 0 },
	{ label: "TOIZD.V", value: 4993778, partition: 0 },
	{ label: "TOSCS.V", value: 4993780, partition: 0 },
	{ label: "SISQW.V", value: 6041737, partition: 0 },
	{ label: "TOARE.V", value: 4993781, partition: 0 },
	{ label: "NAIPK.V", value: 3156981, partition: 0 },
	{ label: "MEPET.E", value: 76456, partition: 0 },
	{ label: "YAPRK.E", value: 79336, partition: 0 },
	{ label: "PEAUT.V", value: 4861356, partition: 0 },
	{ label: "SPISF.V", value: 3289422, partition: 0 },
	{ label: "TKAUF.V", value: 5255784, partition: 0 },
	{ label: "TKSDG.V", value: 5255785, partition: 0 },
	{ label: "SEGYO.E", value: 208898, partition: 0 },
	{ label: "IYIIF.V", value: 1455507, partition: 0 },
	{ label: "IZAZE.V", value: 1455508, partition: 0 },
	{ label: "MERIT.E", value: 76476, partition: 0 },
	{ label: "KRAEY.V", value: 4466952, partition: 0 },
	{ label: "SLARR.V", value: 3551430, partition: 0 },
	{ label: "YATAS.E", value: 79356, partition: 0 },
	{ label: "AXADC.V", value: 1455518, partition: 0 },
	{ label: "PGADH.V", value: 2371040, partition: 0 },
	{ label: "NASBV.V", value: 5647348, partition: 0 },
	{ label: "PBIWL.V", value: 3223240, partition: 0 },
	{ label: "MERKO.E", value: 76496, partition: 0 },
	{ label: "YAYLA.E", value: 79376, partition: 0 },
	{ label: "KHAFO.V", value: 272270, partition: 0 },
	{ label: "KZITE.V", value: 2238627, partition: 0 },
	{ label: "TRIPF.V", value: 2241506, partition: 0 },
	{ label: "IYIVF.V", value: 3945875, partition: 0 },
	{ label: "KHBAA.V", value: 272271, partition: 0 },
	{ label: "KZASY.V", value: 2238628, partition: 0 },
	{ label: "IZAJV.V", value: 3945876, partition: 0 },
	{ label: "PGIZH.V", value: 4861407, partition: 0 },
	{ label: "PGASM.V", value: 4861408, partition: 0 },
	{ label: "AXASE.V", value: 3945886, partition: 0 },
	{ label: "AXSAV.V", value: 3945887, partition: 0 },
	{ label: "TAIFL.V", value: 799154, partition: 0 },
	{ label: "TASQF.V", value: 799155, partition: 0 },
	{ label: "TAAAT.V", value: 799156, partition: 0 },
	{ label: "KOIPE.V", value: 2762634, partition: 0 },
	{ label: "NXSAD.V", value: 666725, partition: 0 },
	{ label: "KZIHH.V", value: 4728995, partition: 0 },
	{ label: "KZAEF.V", value: 4728996, partition: 0 },
	{ label: "IZAZC.V", value: 6436244, partition: 0 },
	{ label: "TPAFW.V", value: 4731876, partition: 0 },
	{ label: "KZSPI.V", value: 4728997, partition: 0 },
	{ label: "IZSEE.V", value: 6436245, partition: 0 },
	{ label: "TOILL.V", value: 275186, partition: 0 },
	{ label: "SSIIN.V", value: 1323142, partition: 0 },
	{ label: "TPSDC.V", value: 4731879, partition: 0 },
	{ label: "METRO.E", value: 76536, partition: 0 },
	{ label: "YBTAS.E", value: 79416, partition: 0 },
	{ label: "TOSCU.V", value: 275188, partition: 0 },
	{ label: "SHSBD.V", value: 3813482, partition: 0 },
	{ label: "PWSAL.V", value: 2109118, partition: 0 },
	{ label: "PDSAO.V", value: 2633096, partition: 0 },
	{ label: "TKIDQ.V", value: 537190, partition: 0 },
	{ label: "TKAEZ.V", value: 537192, partition: 0 },
	{ label: "PBION.V", value: 2109128, partition: 0 },
	{ label: "METUR.E", value: 76556, partition: 0 },
	{ label: "PBATF.V", value: 2109130, partition: 0 },
	{ label: "YESIL.E", value: 79436, partition: 0 },
	{ label: "PBSAL.V", value: 2109131, partition: 0 },
	{ label: "YPIRC.V", value: 1193610, partition: 0 },
	{ label: "TAIFF.V", value: 3289522, partition: 0 },
	{ label: "KHIXW.V", value: 5253002, partition: 0 },
	{ label: "YBAUQ.V", value: 1193612, partition: 0 },
	{ label: "KHAUW.V", value: 5253006, partition: 0 },
	{ label: "SOIAC.V", value: 1847150, partition: 0 },
	{ label: "TLIKN.V", value: 799195, partition: 0 },
	{ label: "TLAEY.V", value: 799196, partition: 0 },
	{ label: "SSIUC.V", value: 3813510, partition: 0 },
	{ label: "TSSBV.V", value: 5517880, partition: 0 },
	{ label: "SIATS.V", value: 3813512, partition: 0 },
	{ label: "TOAQX.V", value: 2765557, partition: 0 },
	{ label: "KDSDG.V", value: 5843209, partition: 0 },
	{ label: "NAIPN.V", value: 928757, partition: 0 },
	{ label: "VWSBE.V", value: 3945951, partition: 0 },
	{ label: "MGROS.E", value: 76576, partition: 0 },
	{ label: "SPSCH.V", value: 7613799, partition: 0 },
	{ label: "YGGYO.E", value: 79456, partition: 0 },
	{ label: "PTISI.V", value: 2633130, partition: 0 },
	{ label: "TKAET.V", value: 3027560, partition: 0 },
	{ label: "TKSDX.V", value: 3027561, partition: 0 },
	{ label: "PBAEM.V", value: 4599498, partition: 0 },
	{ label: "PLSPD.V", value: 2371152, partition: 0 },
	{ label: "YPIRH.V", value: 3683978, partition: 0 },
	{ label: "YBAUK.V", value: 3683980, partition: 0 },
	{ label: "MIPAZ.E", value: 76596, partition: 0 },
	{ label: "YGYO.E", value: 79476, partition: 0 },
	{ label: "SLISM.V", value: 1323205, partition: 0 },
	{ label: "KDIUD.V", value: 2238727, partition: 0 },
	{ label: "SLARZ.V", value: 1323206, partition: 0 },
	{ label: "KRATS.V", value: 2238728, partition: 0 },
	{ label: "TLAES.V", value: 3289564, partition: 0 },
	{ label: "KDSQX.V", value: 2238729, partition: 0 },
	{ label: "TOIZF.V", value: 5255922, partition: 0 },
	{ label: "TOSQE.V", value: 5255924, partition: 0 },
	{ label: "SISDM.V", value: 6303881, partition: 0 },
	{ label: "TOARG.V", value: 5255925, partition: 0 },
	{ label: "NAIAQ.V", value: 3419125, partition: 0 },
	{ label: "PEAUV.V", value: 5123500, partition: 0 },
	{ label: "SPIGD.V", value: 3551566, partition: 0 },
	{ label: "MMCAS.E", value: 76616, partition: 0 },
	{ label: "YKBNK.E", value: 79496, partition: 0 },
	{ label: "TKSDI.V", value: 5517929, partition: 0 },
	{ label: "IYIIH.V", value: 1717651, partition: 0 },
	{ label: "IZAZG.V", value: 1717652, partition: 0 },
	{ label: "KRAFA.V", value: 4729096, partition: 0 },
	{ label: "SLART.V", value: 3813574, partition: 0 },
	{ label: "AXADE.V", value: 1717662, partition: 0 },
	{ label: "PGADJ.V", value: 2633184, partition: 0 },
	{ label: "SPSCJ.V", value: 404839, partition: 0 },
	{ label: "MNDRS.E", value: 76636, partition: 0 },
	{ label: "NASBX.V", value: 5909492, partition: 0 },
	{ label: "MNDTR.E", value: 76641, partition: 0 },
	{ label: "TYIAH.V", value: 1913446, partition: 0 },
	{ label: "MPARK.E", value: 76646, partition: 0 },
	{ label: "YKSLN.E", value: 79526, partition: 0 },
	{ label: "KHIDR.V", value: 534410, partition: 0 },
	{ label: "KHAFQ.V", value: 534414, partition: 0 },
	{ label: "KZITG.V", value: 2500771, partition: 0 },
	{ label: "TRIPH.V", value: 2503650, partition: 0 },
	{ label: "IYIVH.V", value: 4208019, partition: 0 },
	{ label: "KZATA.V", value: 2500772, partition: 0 },
	{ label: "IZAJX.V", value: 4208020, partition: 0 },
	{ label: "YONGA.E", value: 79536, partition: 0 },
	{ label: "TPSDN.V", value: 2503655, partition: 0 },
	{ label: "PGIZJ.V", value: 5123551, partition: 0 },
	{ label: "PGASO.V", value: 5123552, partition: 0 },
	{ label: "AXADL.V", value: 4208030, partition: 0 },
	{ label: "AXSPI.V", value: 4208031, partition: 0 },
	{ label: "MPATA.F1", value: 76666, partition: 0 },
	{ label: "MRGYO.E", value: 76676, partition: 0 },
	{ label: "YUNSA.E", value: 79556, partition: 0 },
	{ label: "SMART.E", value: 209111, partition: 0 },
	{ label: "TAISG.V", value: 1061298, partition: 0 },
	{ label: "KHIDV.V", value: 3024778, partition: 0 },
	{ label: "TAAPQ.V", value: 1061300, partition: 0 },
	{ label: "KZITA.V", value: 4991139, partition: 0 },
	{ label: "KHAFK.V", value: 3024782, partition: 0 },
	{ label: "MOBTL.E", value: 76686, partition: 0 },
	{ label: "KZATC.V", value: 4991140, partition: 0 },
	{ label: "IZAKM.V", value: 6698388, partition: 0 },
	{ label: "TPAUO.V", value: 4994020, partition: 0 },
	{ label: "KZSBA.V", value: 4991141, partition: 0 },
	{ label: "IZSEG.V", value: 6698389, partition: 0 },
	{ label: "TOILN.V", value: 537330, partition: 0 },
	{ label: "SSIUI.V", value: 1585286, partition: 0 },
	{ label: "TPSDE.V", value: 4994023, partition: 0 },
	{ label: "TOSCW.V", value: 537332, partition: 0 },
	{ label: "SHSPP.V", value: 4075626, partition: 0 },
	{ label: "MRSHL.E", value: 76696, partition: 0 },
	{ label: "YYAPI.E", value: 79576, partition: 0 },
	{ label: "PWSPD.V", value: 2371262, partition: 0 },
	{ label: "PDSAQ.V", value: 2895240, partition: 0 },
	{ label: "PTICY.V", value: 404906, partition: 0 },
	{ label: "YYLGD.E", value: 79581, partition: 0 },
	{ label: "PEAFP.V", value: 404908, partition: 0 },
	{ label: "PEBAB.V", value: 404910, partition: 0 },
	{ label: "TKIDS.V", value: 799334, partition: 0 },
	{ label: "ZEDUR.E", value: 79586, partition: 0 },
	{ label: "TKAFB.V", value: 799336, partition: 0 },
	{ label: "PBIWO.V", value: 2371272, partition: 0 },
	{ label: "PBATH.V", value: 2371274, partition: 0 },
	{ label: "PBSPD.V", value: 2371275, partition: 0 },
	{ label: "TAISA.V", value: 3551666, partition: 0 },
	{ label: "YPIRE.V", value: 1455754, partition: 0 },
	{ label: "YBAUS.V", value: 1455756, partition: 0 },
	{ label: "ZOREN.E", value: 79596, partition: 0 },
	{ label: "SOIAE.V", value: 2109294, partition: 0 },
	{ label: "TLILA.V", value: 1061339, partition: 0 },
	{ label: "ZRGYO.E", value: 79601, partition: 0 },
	{ label: "TLATV.V", value: 1061340, partition: 0 },
	{ label: "SSIUE.V", value: 4075654, partition: 0 },
	{ label: "TOILC.V", value: 3027698, partition: 0 },
	{ label: "TSSBX.V", value: 5780024, partition: 0 },
	{ label: "SIATU.V", value: 4075656, partition: 0 },
	{ label: "TOABZ.V", value: 3027701, partition: 0 },
	{ label: "MSGYO.E", value: 76726, partition: 0 },
	{ label: "KDSQS.V", value: 6105353, partition: 0 },
	{ label: "ZPX30.F", value: 79606, partition: 0 },
	{ label: "ZPT10.F", value: 79607, partition: 0 },
	{ label: "NAIPP.V", value: 1190901, partition: 0 },
	{ label: "SPSPZ.V", value: 7875943, partition: 0 },
	{ label: "ZELOT.F", value: 79611, partition: 0 },
	{ label: "PTIDC.V", value: 2895274, partition: 0 },
	{ label: "SPIGK.V", value: 1323342, partition: 0 },
	{ label: "MTRYO.E", value: 76736, partition: 0 },
	{ label: "ZPLIB.F", value: 79616, partition: 0 },
	{ label: "TKAEV.V", value: 3289704, partition: 0 },
	{ label: "TKSRB.V", value: 3289705, partition: 0 },
	{ label: "PBATJ.V", value: 4861642, partition: 0 },
	{ label: "PLSAO.V", value: 2633296, partition: 0 },
	{ label: "ZPBDL.F", value: 79626, partition: 0 },
	{ label: "YBAUM.V", value: 3946124, partition: 0 },
	{ label: "PGIMC.V", value: 404959, partition: 0 },
	{ label: "SLIHI.V", value: 1585349, partition: 0 },
	{ label: "KDIUF.V", value: 2500871, partition: 0 },
	{ label: "KRATU.V", value: 2500872, partition: 0 },
	{ label: "SASCP.V", value: 4599664, partition: 0 },
	{ label: "TLATP.V", value: 3551708, partition: 0 },
	{ label: "ZGOLD.F", value: 79636, partition: 0 },
	{ label: "SISDO.V", value: 6566025, partition: 0 },
	{ label: "NAIAS.V", value: 3681269, partition: 0 },
	{ label: "PTISE.V", value: 5385642, partition: 0 },
	{ label: "Z30EA.F", value: 79646, partition: 0 },
	{ label: "PEAUX.V", value: 5385644, partition: 0 },
	{ label: "SPIGF.V", value: 3813710, partition: 0 },
	{ label: "PESDD.V", value: 5385645, partition: 0 },
	{ label: "Z30KE.F", value: 79648, partition: 0 },
	{ label: "PBIOG.V", value: 1257160, partition: 0 },
	{ label: "Z30KP.F", value: 79650, partition: 0 },
	{ label: "TKSDK.V", value: 5780073, partition: 0 },
	{ label: "KZIGN.V", value: 272547, partition: 0 },
	{ label: "MZHLD.E", value: 76776, partition: 0 },
	{ label: "IYIIJ.V", value: 1979795, partition: 0 },
	{ label: "ZRE20.F", value: 79656, partition: 0 },
	{ label: "TPAFN.V", value: 275428, partition: 0 },
	{ label: "IZAZI.V", value: 1979796, partition: 0 },
	{ label: "IZSEO.V", value: 1979797, partition: 0 },
	{ label: "ZSR25.F", value: 79661, partition: 0 },
	{ label: "PGILG.V", value: 2895327, partition: 0 },
	{ label: "KRATY.V", value: 4991240, partition: 0 },
	{ label: "SLARV.V", value: 4075718, partition: 0 },
	{ label: "PGADL.V", value: 2895328, partition: 0 },
	{ label: "ZTM15.F", value: 79666, partition: 0 },
	{ label: "AXASB.V", value: 1979806, partition: 0 },
	{ label: "AXSAJ.V", value: 1979807, partition: 0 },
	{ label: "NASBZ.V", value: 6171636, partition: 0 },
	{ label: "NETAS.E", value: 76796, partition: 0 },
	{ label: "TYIAJ.V", value: 2175590, partition: 0 },
	{ label: "KHIDT.V", value: 796554, partition: 0 },
	{ label: "KZITI.V", value: 2762915, partition: 0 },
	{ label: "TRIPJ.V", value: 2765794, partition: 0 },
	{ label: "KHAFS.V", value: 796558, partition: 0 },
	{ label: "IYIIM.V", value: 4470163, partition: 0 },
	{ label: "IZAZJ.V", value: 4470164, partition: 0 },
	{ label: "TPSDP.V", value: 2765799, partition: 0 },
	{ label: "NIBAS.E", value: 76816, partition: 0 },
	{ label: "PGIZL.V", value: 5385695, partition: 0 },
	{ label: "PGASQ.V", value: 5385696, partition: 0 },
	{ label: "AXADN.V", value: 4470174, partition: 0 },
	{ label: "AXSAW.V", value: 4470175, partition: 0 },
	{ label: "NTGAZ.E", value: 76831, partition: 0 },
	{ label: "TAISI.V", value: 1323442, partition: 0 },
	{ label: "NTHOL.E", value: 76836, partition: 0 },
	{ label: "KHIDX.V", value: 3286922, partition: 0 },
	{ label: "TAAPS.V", value: 1323444, partition: 0 },
	{ label: "TRIPD.V", value: 5256162, partition: 0 },
	{ label: "KZITC.V", value: 5253283, partition: 0 },
	{ label: "KHAFM.V", value: 3286926, partition: 0 },
	{ label: "KZATE.V", value: 5253284, partition: 0 },
	{ label: "IZAKO.V", value: 6960532, partition: 0 },
	{ label: "TPAUQ.V", value: 5256164, partition: 0 },
	{ label: "KZSBC.V", value: 5253285, partition: 0 },
	{ label: "IZSRK.V", value: 6960533, partition: 0 },
	{ label: "TNZTP.E", value: 209278, partition: 0 },
	{ label: "TOIMA.V", value: 799474, partition: 0 },
	{ label: "SSIUK.V", value: 1847430, partition: 0 },
	{ label: "TPSQQ.V", value: 5256167, partition: 0 },
	{ label: "TSSBT.V", value: 3551800, partition: 0 },
	{ label: "TOSQF.V", value: 799476, partition: 0 },
	{ label: "SPSBV.V", value: 5647719, partition: 0 },
	{ label: "PWSAO.V", value: 2633406, partition: 0 },
	{ label: "PTIDA.V", value: 667050, partition: 0 },
	{ label: "PEAFU.V", value: 667052, partition: 0 },
	{ label: "TKIXW.V", value: 1061478, partition: 0 },
	{ label: "TKATY.V", value: 1061480, partition: 0 },
	{ label: "PBIWQ.V", value: 2633416, partition: 0 },
	{ label: "PBSAO.V", value: 2633419, partition: 0 },
	{ label: "TAISC.V", value: 3813810, partition: 0 },
	{ label: "YPICL.V", value: 1717898, partition: 0 },
	{ label: "KDIHG.V", value: 272647, partition: 0 },
	{ label: "SOIPB.V", value: 2371438, partition: 0 },
	{ label: "NUGYO.E", value: 76876, partition: 0 },
	{ label: "TLIKH.V", value: 1323483, partition: 0 },
	{ label: "TOILE.V", value: 3289842, partition: 0 },
	{ label: "KDBAA.V", value: 272650, partition: 0 },
	{ label: "TSSPU.V", value: 6042168, partition: 0 },
	{ label: "SIAFC.V", value: 4337800, partition: 0 },
	{ label: "TOACB.V", value: 3289845, partition: 0 },
	{ label: "KDSDI.V", value: 6367497, partition: 0 },
	{ label: "PTIDE.V", value: 3157418, partition: 0 },
	{ label: "PEAFQ.V", value: 3157420, partition: 0 },
	{ label: "SPIGM.V", value: 1585486, partition: 0 },
	{ label: "TKATS.V", value: 3551848, partition: 0 },
	{ label: "NUHCM.E", value: 76896, partition: 0 },
	{ label: "PBATL.V", value: 5123786, partition: 0 },
	{ label: "PLSAQ.V", value: 2895440, partition: 0 },
	{ label: "YBAFT.V", value: 4208268, partition: 0 },
	{ label: "KDIUH.V", value: 2763015, partition: 0 },
	{ label: "PGIME.V", value: 667103, partition: 0 },
	{ label: "SLIHK.V", value: 1847493, partition: 0 },
	{ label: "KRATW.V", value: 2763016, partition: 0 },
	{ label: "SASCR.V", value: 4861808, partition: 0 },
	{ label: "TLATR.V", value: 3813852, partition: 0 },
	{ label: "SISDQ.V", value: 6828169, partition: 0 },
	{ label: "NASCL.V", value: 3943412, partition: 0 },
	{ label: "NAIPQ.V", value: 3943413, partition: 0 },
	{ label: "ODAS.E", value: 76916, partition: 0 },
	{ label: "SPIRO.V", value: 4075854, partition: 0 },
	{ label: "PESDF.V", value: 5647789, partition: 0 },
	{ label: "TKSQW.V", value: 6042217, partition: 0 },
	{ label: "KZIHA.V", value: 534691, partition: 0 },
	{ label: "TPICX.V", value: 537570, partition: 0 },
	{ label: "OMDBE.F2", value: 76931, partition: 0 },
	{ label: "IYIIL.V", value: 2241939, partition: 0 },
	{ label: "TPAFP.V", value: 537572, partition: 0 },
	{ label: "OMGIO.F2", value: 76933, partition: 0 },
	{ label: "IZSEQ.V", value: 2241941, partition: 0 },
	{ label: "TLIKD.V", value: 209371, partition: 0 },
	{ label: "KDIUB.V", value: 5253383, partition: 0 },
	{ label: "SLIHE.V", value: 4337861, partition: 0 },
	{ label: "PGILI.V", value: 3157471, partition: 0 },
	{ label: "KRAUA.V", value: 5253384, partition: 0 },
	{ label: "SLADC.V", value: 4337862, partition: 0 },
	{ label: "PGASI.V", value: 3157472, partition: 0 },
	{ label: "ONCSM.E", value: 76942, partition: 0 },
	{ label: "AXASD.V", value: 2241950, partition: 0 },
	{ label: "AXSAL.V", value: 2241951, partition: 0 },
	{ label: "NASPV.V", value: 6433780, partition: 0 },
	{ label: "TYIPG.V", value: 2437734, partition: 0 },
	{ label: "ORGE.E", value: 76956, partition: 0 },
	{ label: "KHIXP.V", value: 1058698, partition: 0 },
	{ label: "TRIAB.V", value: 3027938, partition: 0 },
	{ label: "KHAUP.V", value: 1058702, partition: 0 },
	{ label: "IYIIO.V", value: 4732307, partition: 0 },
	{ label: "KZADS.V", value: 3025060, partition: 0 },
	{ label: "TDGYO.E", value: 209398, partition: 0 },
	{ label: "TPAFJ.V", value: 3027940, partition: 0 },
	{ label: "TPSDR.V", value: 3027943, partition: 0 },
	{ label: "AXADP.V", value: 4732318, partition: 0 },
	{ label: "AXSAY.V", value: 4732319, partition: 0 },
	{ label: "ORMA.E", value: 76976, partition: 0 },
	{ label: "TAIEM.V", value: 1585586, partition: 0 },
	{ label: "KHIXT.V", value: 3549066, partition: 0 },
	{ label: "TAAAU.V", value: 1585588, partition: 0 },
	{ label: "KHAUJ.V", value: 3549070, partition: 0 },
	{ label: "OSMEN.E", value: 76996, partition: 0 },
	{ label: "IZAKQ.V", value: 7222676, partition: 0 },
	{ label: "KZSBE.V", value: 5515429, partition: 0 },
	{ label: "IZSEI.V", value: 7222677, partition: 0 },
	{ label: "TOIZI.V", value: 1061618, partition: 0 },
	{ label: "TPSDG.V", value: 5518311, partition: 0 },
	{ label: "TSSCH.V", value: 3813944, partition: 0 },
	{ label: "SPSBX.V", value: 5909863, partition: 0 },
	{ label: "PWSAQ.V", value: 2895550, partition: 0 },
	{ label: "PTIRV.V", value: 929194, partition: 0 },
	{ label: "PEAFW.V", value: 929196, partition: 0 },
	{ label: "TKIXY.V", value: 1323622, partition: 0 },
	{ label: "OSTIM.E", value: 77016, partition: 0 },
	{ label: "TKAUA.V", value: 1323624, partition: 0 },
	{ label: "PBSAQ.V", value: 2895563, partition: 0 },
	{ label: "TAISE.V", value: 4075954, partition: 0 },
	{ label: "YPICN.V", value: 1980042, partition: 0 },
	{ label: "TASCM.V", value: 4075955, partition: 0 },
	{ label: "SOIPD.V", value: 2633582, partition: 0 },
	{ label: "KDIHI.V", value: 534791, partition: 0 },
	{ label: "TLIVE.V", value: 1585627, partition: 0 },
	{ label: "TOIYM.V", value: 3551986, partition: 0 },
	{ label: "TSSBZ.V", value: 6304312, partition: 0 },
	{ label: "SIAFE.V", value: 4599944, partition: 0 },
	{ label: "TOAQY.V", value: 3551989, partition: 0 },
	{ label: "OTKAR.E", value: 77036, partition: 0 },
	{ label: "KDSDK.V", value: 6629641, partition: 0 },
	{ label: "OTTO.E", value: 77041, partition: 0 },
	{ label: "PTIDG.V", value: 3419562, partition: 0 },
	{ label: "PEAFS.V", value: 3419564, partition: 0 },
	{ label: "SPISG.V", value: 1847630, partition: 0 },
	{ label: "OYAKC.E", value: 77046, partition: 0 },
	{ label: "TKATU.V", value: 3813992, partition: 0 },
	{ label: "PBATN.V", value: 5385930, partition: 0 },
	{ label: "OYAYO.E", value: 77056, partition: 0 },
	{ label: "YBAFV.V", value: 4470412, partition: 0 },
	{ label: "SLIHM.V", value: 2109637, partition: 0 },
	{ label: "KDIHM.V", value: 3025159, partition: 0 },
	{ label: "PGIZM.V", value: 929247, partition: 0 },
	{ label: "SASCT.V", value: 5123952, partition: 0 },
	{ label: "TLATT.V", value: 4075996, partition: 0 },
	{ label: "SISQY.V", value: 7090313, partition: 0 },
	{ label: "NASCN.V", value: 4205556, partition: 0 },
	{ label: "NAIPS.V", value: 4205557, partition: 0 },
	{ label: "OYLUM.E", value: 77076, partition: 0 },
	{ label: "TKICZ.V", value: 209510, partition: 0 },
	{ label: "SPISB.V", value: 4337998, partition: 0 },
	{ label: "PESDH.V", value: 5909933, partition: 0 },
	{ label: "TKSDM.V", value: 6304361, partition: 0 },
	{ label: "KZIHC.V", value: 796835, partition: 0 },
	{ label: "TPICZ.V", value: 799714, partition: 0 },
	{ label: "IYIUB.V", value: 2504083, partition: 0 },
	{ label: "OYYAT.E", value: 77086, partition: 0 },
	{ label: "TPAFR.V", value: 799716, partition: 0 },
	{ label: "IZSRO.V", value: 2504085, partition: 0 },
	{ label: "SLIHG.V", value: 4600005, partition: 0 },
	{ label: "PGILK.V", value: 3419615, partition: 0 },
	{ label: "SLADE.V", value: 4600006, partition: 0 },
	{ label: "AXADH.V", value: 2504094, partition: 0 },
	{ label: "PGASK.V", value: 3419616, partition: 0 },
	{ label: "AXSAN.V", value: 2504095, partition: 0 },
	{ label: "NASPX.V", value: 6695924, partition: 0 },
	{ label: "TYIPI.V", value: 2699878, partition: 0 },
	{ label: "KHIXR.V", value: 1320842, partition: 0 },
	{ label: "OZGYO.E", value: 77116, partition: 0 },
	{ label: "TRIAD.V", value: 3290082, partition: 0 },
	{ label: "KHAUR.V", value: 1320846, partition: 0 },
	{ label: "IYIJB.V", value: 4994451, partition: 0 },
	{ label: "KZADU.V", value: 3287204, partition: 0 },
	{ label: "IZAJZ.V", value: 4994452, partition: 0 },
	{ label: "TPAFL.V", value: 3290084, partition: 0 },
	{ label: "TPSQV.V", value: 3290087, partition: 0 },
	{ label: "AXASM.V", value: 4994462, partition: 0 },
	{ label: "AXSPK.V", value: 4994463, partition: 0 },
	{ label: "PDSAF.V", value: 1191304, partition: 0 },
	{ label: "OZKGY.E", value: 77136, partition: 0 },
	{ label: "KARYE.E", value: 206693, partition: 0 },
	{ label: "TYIPC.V", value: 5190246, partition: 0 },
	{ label: "TAIEO.V", value: 1847730, partition: 0 },
	{ label: "OZRDN.E", value: 77146, partition: 0 },
	{ label: "KHIXV.V", value: 3811210, partition: 0 },
	{ label: "TAAAW.V", value: 1847732, partition: 0 },
	{ label: "KHAUL.V", value: 3811214, partition: 0 },
	{ label: "SBIDP.V", value: 405358, partition: 0 },
	{ label: "IZAKS.V", value: 7484820, partition: 0 },
	{ label: "KZSPK.V", value: 5777573, partition: 0 },
	{ label: "IZSEK.V", value: 7484821, partition: 0 },
	{ label: "SASDB.V", value: 405360, partition: 0 },
	{ label: "SSIID.V", value: 2371718, partition: 0 },
	{ label: "SAAAB.V", value: 405361, partition: 0 },
	{ label: "TOIZK.V", value: 1323762, partition: 0 },
	{ label: "TPSDI.V", value: 5780455, partition: 0 },
	{ label: "PAGYO.E", value: 77156, partition: 0 },
	{ label: "TSSCJ.V", value: 4076088, partition: 0 },
	{ label: "SIAER.V", value: 2371720, partition: 0 },
	{ label: "SISDS.V", value: 2371721, partition: 0 },
	{ label: "VWSBH.V", value: 2504159, partition: 0 },
	{ label: "PAMEL.E", value: 77162, partition: 0 },
	{ label: "SPSBZ.V", value: 6172007, partition: 0 },
	{ label: "PTIRX.V", value: 1191338, partition: 0 },
	{ label: "PEAUO.V", value: 1191340, partition: 0 },
	{ label: "TYIAF.V", value: 1585766, partition: 0 },
	{ label: "KHIDP.V", value: 206730, partition: 0 },
	{ label: "PARSN.E", value: 77176, partition: 0 },
	{ label: "YPIRL.V", value: 2242186, partition: 0 },
	{ label: "TASCO.V", value: 4338099, partition: 0 },
	{ label: "TAABA.V", value: 4338100, partition: 0 },
	{ label: "PASEU.E", value: 77181, partition: 0 },
	{ label: "SBIDT.V", value: 2895726, partition: 0 },
	{ label: "KDIHK.V", value: 796935, partition: 0 },
	{ label: "TLIVG.V", value: 1847771, partition: 0 },
	{ label: "TOIYO.V", value: 3814130, partition: 0 },
	{ label: "TSSCB.V", value: 6566456, partition: 0 },
	{ label: "SIAUA.V", value: 4862088, partition: 0 },
	{ label: "TOARA.V", value: 3814133, partition: 0 },
	{ label: "KDSDM.V", value: 6891785, partition: 0 },
	{ label: "PEGYO.E", value: 77196, partition: 0 },
	{ label: "PTISB.V", value: 3681706, partition: 0 },
	{ label: "PEAUK.V", value: 3681708, partition: 0 },
	{ label: "SPISI.V", value: 2109774, partition: 0 },
	{ label: "TKATW.V", value: 4076136, partition: 0 },
	{ label: "PEKGY.E", value: 77206, partition: 0 },
	{ label: "IYIJF.V", value: 275859, partition: 0 },
	{ label: "IZAKD.V", value: 275860, partition: 0 },
	{ label: "IZBAA.V", value: 275862, partition: 0 },
	{ label: "YBAFX.V", value: 4732556, partition: 0 },
	{ label: "PENGD.E", value: 77216, partition: 0 },
	{ label: "SBIXL.V", value: 5386094, partition: 0 },
	{ label: "SLITF.V", value: 2371781, partition: 0 },
	{ label: "KDIHO.V", value: 3287303, partition: 0 },
	{ label: "PGIZO.V", value: 1191391, partition: 0 },
	{ label: "TLIKJ.V", value: 4338139, partition: 0 },
	{ label: "KRAEP.V", value: 3287304, partition: 0 },
	{ label: "SASQF.V", value: 5386096, partition: 0 },
	{ label: "TLAFA.V", value: 4338140, partition: 0 },
	{ label: "PENTA.E", value: 77221, partition: 0 },
	{ label: "NASQC.V", value: 4467700, partition: 0 },
	{ label: "PESQT.V", value: 6172077, partition: 0 },
	{ label: "PETKM.E", value: 77236, partition: 0 },
	{ label: "TKSDO.V", value: 6566505, partition: 0 },
	{ label: "KZISK.V", value: 1058979, partition: 0 },
	{ label: "TPIXK.V", value: 1061858, partition: 0 },
	{ label: "IYIUD.V", value: 2766227, partition: 0 },
	{ label: "TPAUJ.V", value: 1061860, partition: 0 },
	{ label: "KFEIN.E", value: 206798, partition: 0 },
	{ label: "SLISO.V", value: 4862149, partition: 0 },
	{ label: "PGIZD.V", value: 3681759, partition: 0 },
	{ label: "SLASB.V", value: 4862150, partition: 0 },
	{ label: "AXADJ.V", value: 2766238, partition: 0 },
	{ label: "AXSPE.V", value: 2766239, partition: 0 },
	{ label: "PETUN.E", value: 77256, partition: 0 },
	{ label: "NASCC.V", value: 6958068, partition: 0 },
	{ label: "KIMMR.E", value: 206818, partition: 0 },
	{ label: "TYIAA.V", value: 2962022, partition: 0 },
	{ label: "KOIAA.V", value: 1582986, partition: 0 },
	{ label: "TRIPA.V", value: 3552226, partition: 0 },
	{ label: "IYIJD.V", value: 5256595, partition: 0 },
	{ label: "KZASR.V", value: 3549348, partition: 0 },
	{ label: "IZAKB.V", value: 5256596, partition: 0 },
	{ label: "TPAUD.V", value: 3552228, partition: 0 },
	{ label: "PGSUS.E", value: 77276, partition: 0 },
	{ label: "VWSBF.V", value: 275935, partition: 0 },
	{ label: "AXASO.V", value: 5256606, partition: 0 },
	{ label: "SPSCL.V", value: 3943783, partition: 0 },
	{ label: "PDSAH.V", value: 1453448, partition: 0 },
	{ label: "TYIPE.V", value: 5452390, partition: 0 },
	{ label: "TAIFB.V", value: 2109874, partition: 0 },
	{ label: "TAAAY.V", value: 2109876, partition: 0 },
	{ label: "KHAUN.V", value: 4073358, partition: 0 },
	{ label: "SBIDR.V", value: 667502, partition: 0 },
	{ label: "IZAZM.V", value: 7746964, partition: 0 },
	{ label: "KZSBG.V", value: 6039717, partition: 0 },
	{ label: "IZSEM.V", value: 7746965, partition: 0 },
	{ label: "SASDD.V", value: 667504, partition: 0 },
	{ label: "SSITN.V", value: 2633862, partition: 0 },
	{ label: "SAAAD.V", value: 667505, partition: 0 },
	{ label: "TPSDK.V", value: 6042599, partition: 0 },
	{ label: "TSSPY.V", value: 4338232, partition: 0 },
	{ label: "SIATY.V", value: 2633864, partition: 0 },
	{ label: "TOABT.V", value: 1585909, partition: 0 },
	{ label: "SISDU.V", value: 2633865, partition: 0 },
	{ label: "PINSU.E", value: 77316, partition: 0 },
	{ label: "VWSPQ.V", value: 2766303, partition: 0 },
	{ label: "SPSPV.V", value: 6434151, partition: 0 },
	{ label: "PTIRZ.V", value: 1453482, partition: 0 },
	{ label: "PEAUQ.V", value: 1453484, partition: 0 },
	{ label: "PESDP.V", value: 1453485, partition: 0 },
	{ label: "PLSAF.V", value: 1191504, partition: 0 },
	{ label: "YPIRN.V", value: 2504330, partition: 0 },
	{ label: "TASQD.V", value: 4600243, partition: 0 },
	{ label: "TAABC.V", value: 4600244, partition: 0 },
	{ label: "PKART.E", value: 77336, partition: 0 },
	{ label: "SBIDV.V", value: 3157870, partition: 0 },
	{ label: "KDITJ.V", value: 1059079, partition: 0 },
	{ label: "TLILC.V", value: 2109915, partition: 0 },
	{ label: "TOIZB.V", value: 4076274, partition: 0 },
	{ label: "TSSCD.V", value: 6828600, partition: 0 },
	{ label: "SIAUC.V", value: 5124232, partition: 0 },
	{ label: "TOSCM.V", value: 4076276, partition: 0 },
	{ label: "TOARC.V", value: 4076277, partition: 0 },
	{ label: "KDSQU.V", value: 7153929, partition: 0 },
	{ label: "PEAUM.V", value: 3943852, partition: 0 },
	{ label: "SPIGG.V", value: 2371918, partition: 0 },
	{ label: "PKENT.E", value: 77356, partition: 0 },
	{ label: "MTSAD.V", value: 273130, partition: 0 },
	{ label: "TKAFD.V", value: 4338280, partition: 0 },
	{ label: "IYIJH.V", value: 538003, partition: 0 },
	{ label: "IZAKF.V", value: 538004, partition: 0 },
	{ label: "IZBAC.V", value: 538006, partition: 0 },
	{ label: "YBAUU.V", value: 4994700, partition: 0 },
	{ label: "KDITN.V", value: 3549447, partition: 0 },
	{ label: "PGIQB.V", value: 1453535, partition: 0 },
	{ label: "SLITH.V", value: 2633925, partition: 0 },
	{ label: "TLIKL.V", value: 4600283, partition: 0 },
	{ label: "KRATN.V", value: 3549448, partition: 0 },
	{ label: "PGADD.V", value: 1453536, partition: 0 },
	{ label: "SASCV.V", value: 5648240, partition: 0 },
	{ label: "TLAFC.V", value: 4600284, partition: 0 },
	{ label: "PESDJ.V", value: 6434221, partition: 0 },
	{ label: "TKSDQ.V", value: 6828649, partition: 0 },
	{ label: "KZISM.V", value: 1321123, partition: 0 },
	{ label: "TPIXM.V", value: 1324002, partition: 0 },
	{ label: "KZADW.V", value: 1321124, partition: 0 },
	{ label: "IYIVC.V", value: 3028371, partition: 0 },
	{ label: "PNSUT.E", value: 77396, partition: 0 },
	{ label: "TPAUL.V", value: 1324004, partition: 0 },
	{ label: "SLITB.V", value: 5124293, partition: 0 },
	{ label: "PGIZF.V", value: 3943903, partition: 0 },
	{ label: "PNLSN.E", value: 77406, partition: 0 },
	{ label: "SLASD.V", value: 5124294, partition: 0 },
	{ label: "PGASF.V", value: 3943904, partition: 0 },
	{ label: "AXASG.V", value: 3028382, partition: 0 },
	{ label: "AXSPG.V", value: 3028383, partition: 0 },
	{ label: "NASCE.V", value: 7220212, partition: 0 },
	{ label: "POLHO.E", value: 77416, partition: 0 },
	{ label: "TYIAC.V", value: 3224166, partition: 0 },
	{ label: "KOIAC.V", value: 1845130, partition: 0 },
	{ label: "TRIPC.V", value: 3814370, partition: 0 },
	{ label: "IYIUI.V", value: 5518739, partition: 0 },
	{ label: "KZAST.V", value: 3811492, partition: 0 },
	{ label: "IZAYV.V", value: 5518740, partition: 0 },
	{ label: "TPAUF.V", value: 3814372, partition: 0 },
	{ label: "SSIIA.V", value: 405638, partition: 0 },
	{ label: "SIAEX.V", value: 405640, partition: 0 },
	{ label: "POLTK.E", value: 77436, partition: 0 },
	{ label: "SSBAB.V", value: 405642, partition: 0 },
	{ label: "SHSAX.V", value: 2895978, partition: 0 },
	{ label: "SPSCN.V", value: 4205927, partition: 0 },
	{ label: "PDSPC.V", value: 1715592, partition: 0 },
	{ label: "TAIRW.V", value: 2372018, partition: 0 },
	{ label: "PRKAB.E", value: 77456, partition: 0 },
	{ label: "TPICV.V", value: 209890, partition: 0 },
	{ label: "TAAPV.V", value: 2372020, partition: 0 },
	{ label: "YBAFO.V", value: 276108, partition: 0 },
	{ label: "TUREX.E", value: 209893, partition: 0 },
	{ label: "KHAFU.V", value: 4335502, partition: 0 },
	{ label: "SBIXC.V", value: 929646, partition: 0 },
	{ label: "IZAZO.V", value: 8009108, partition: 0 },
	{ label: "KZSBI.V", value: 6301861, partition: 0 },
	{ label: "IZSRM.V", value: 8009109, partition: 0 },
	{ label: "SASDF.V", value: 929648, partition: 0 },
	{ label: "SAAPA.V", value: 929649, partition: 0 },
	{ label: "SSIUA.V", value: 2896006, partition: 0 },
	{ label: "TPSQS.V", value: 6304743, partition: 0 },
	{ label: "TOABV.V", value: 1848053, partition: 0 },
	{ label: "SISDW.V", value: 2896009, partition: 0 },
	{ label: "VWSAY.V", value: 3028447, partition: 0 },
	{ label: "SPSPX.V", value: 6696295, partition: 0 },
	{ label: "PTIDI.V", value: 1715626, partition: 0 },
	{ label: "PRKME.E", value: 77476, partition: 0 },
	{ label: "PEAUS.V", value: 1715628, partition: 0 },
	{ label: "PESDR.V", value: 1715629, partition: 0 },
	{ label: "PLSAH.V", value: 1453648, partition: 0 },
	{ label: "YPIRP.V", value: 2766474, partition: 0 },
	{ label: "TASCR.V", value: 4862387, partition: 0 },
	{ label: "TAAPZ.V", value: 4862388, partition: 0 },
	{ label: "KDITL.V", value: 1321223, partition: 0 },
	{ label: "SBIDX.V", value: 3420014, partition: 0 },
	{ label: "SLIGO.V", value: 405701, partition: 0 },
	{ label: "KRAEM.V", value: 1321224, partition: 0 },
	{ label: "TLIVO.V", value: 2372059, partition: 0 },
	{ label: "PRZMA.E", value: 77496, partition: 0 },
	{ label: "SLACX.V", value: 405702, partition: 0 },
	{ label: "KDSDO.V", value: 1321225, partition: 0 },
	{ label: "TLATX.V", value: 2372060, partition: 0 },
	{ label: "SSIUG.V", value: 5386374, partition: 0 },
	{ label: "TOILH.V", value: 4338418, partition: 0 },
	{ label: "TSSPW.V", value: 7090744, partition: 0 },
	{ label: "SIAUE.V", value: 5386376, partition: 0 },
	{ label: "TOSCO.V", value: 4338420, partition: 0 },
	{ label: "SISDH.V", value: 5386377, partition: 0 },
	{ label: "TOACE.V", value: 4338421, partition: 0 },
	{ label: "NAIAJ.V", value: 2501621, partition: 0 },
	{ label: "PSDTC.E", value: 77506, partition: 0 },
	{ label: "PSGYO.E", value: 77508, partition: 0 },
	{ label: "PEAFY.V", value: 4205996, partition: 0 },
	{ label: "SPIGI.V", value: 2634062, partition: 0 },
	{ label: "MTSAF.V", value: 535274, partition: 0 },
	{ label: "TKAFF.V", value: 4600424, partition: 0 },
	{ label: "QNBFB.E", value: 77516, partition: 0 },
	{ label: "IYIJJ.V", value: 800147, partition: 0 },
	{ label: "QNBFL.E", value: 77518, partition: 0 },
	{ label: "IZAKH.V", value: 800148, partition: 0 },
	{ label: "QPERP.F1", value: 77520, partition: 0 },
	{ label: "YPIRJ.V", value: 5256842, partition: 0 },
	{ label: "IZBPA.V", value: 800150, partition: 0 },
	{ label: "YBAUW.V", value: 5256844, partition: 0 },
	{ label: "RALYH.E", value: 77526, partition: 0 },
	{ label: "KDIUA.V", value: 3811591, partition: 0 },
	{ label: "TLIVI.V", value: 4862427, partition: 0 },
	{ label: "KRATP.V", value: 3811592, partition: 0 },
	{ label: "PGADF.V", value: 1715680, partition: 0 },
	{ label: "SLACR.V", value: 2896070, partition: 0 },
	{ label: "SASCX.V", value: 5910384, partition: 0 },
	{ label: "TLATZ.V", value: 4862428, partition: 0 },
	{ label: "RAYSG.E", value: 77536, partition: 0 },
	{ label: "PESDL.V", value: 6696365, partition: 0 },
	{ label: "TKSQY.V", value: 7090793, partition: 0 },
	{ label: "KZIHI.V", value: 1583267, partition: 0 },
	{ label: "TRIAF.V", value: 1586146, partition: 0 },
	{ label: "KZADY.V", value: 1583268, partition: 0 },
	{ label: "IYIVE.V", value: 3290515, partition: 0 },
	{ label: "SLITD.V", value: 5386437, partition: 0 },
	{ label: "PGILL.V", value: 4206047, partition: 0 },
	{ label: "SLASF.V", value: 5386438, partition: 0 },
	{ label: "PGADM.V", value: 4206048, partition: 0 },
	{ label: "AXASI.V", value: 3290526, partition: 0 },
	{ label: "AXSAQ.V", value: 3290527, partition: 0 },
	{ label: "NASCG.V", value: 7482356, partition: 0 },
	{ label: "TYIAE.V", value: 3486310, partition: 0 },
	{ label: "RODRG.E", value: 77576, partition: 0 },
	{ label: "KOIAE.V", value: 2107274, partition: 0 },
	{ label: "IYIUK.V", value: 5780883, partition: 0 },
	{ label: "KZASV.V", value: 4073636, partition: 0 },
	{ label: "IZAYX.V", value: 5780884, partition: 0 },
	{ label: "TPAUH.V", value: 4076516, partition: 0 },
	{ label: "KZSAV.V", value: 4073637, partition: 0 },
	{ label: "RPAVS.F1", value: 77586, partition: 0 },
	{ label: "SSIIC.V", value: 667782, partition: 0 },
	{ label: "RPFGY.F1", value: 77588, partition: 0 },
	{ label: "SIAEZ.V", value: 667784, partition: 0 },
	{ label: "SHSAZ.V", value: 3158122, partition: 0 },
	{ label: "SPSQC.V", value: 4468071, partition: 0 },
	{ label: "PDSAK.V", value: 1977736, partition: 0 },
	{ label: "ROYAL.E", value: 77596, partition: 0 },
	{ label: "RTALB.E", value: 77606, partition: 0 },
	{ label: "PBAEF.V", value: 1453770, partition: 0 },
	{ label: "YPICC.V", value: 538250, partition: 0 },
	{ label: "TAIRY.V", value: 2634162, partition: 0 },
	{ label: "YBAFQ.V", value: 538252, partition: 0 },
	{ label: "TAAPX.V", value: 2634164, partition: 0 },
	{ label: "KHAFW.V", value: 4597646, partition: 0 },
	{ label: "RYGYO.E", value: 77616, partition: 0 },
	{ label: "IYIUN.V", value: 8271251, partition: 0 },
	{ label: "SBIXE.V", value: 1191790, partition: 0 },
	{ label: "IZAZQ.V", value: 8271252, partition: 0 },
	{ label: "KZSBK.V", value: 6564005, partition: 0 },
	{ label: "SASQJ.V", value: 1191792, partition: 0 },
	{ label: "SAAPC.V", value: 1191793, partition: 0 },
	{ label: "SSIIE.V", value: 3158150, partition: 0 },
	{ label: "SIAET.V", value: 3158152, partition: 0 },
	{ label: "TOABX.V", value: 2110197, partition: 0 },
	{ label: "SISRA.V", value: 3158153, partition: 0 },
	{ label: "NASCI.V", value: 273396, partition: 0 },
	{ label: "NAIAM.V", value: 273397, partition: 0 },
	{ label: "RUBNS.E", value: 77626, partition: 0 },
	{ label: "VWSBA.V", value: 3290591, partition: 0 },
	{ label: "SPSCC.V", value: 6958439, partition: 0 },
	{ label: "PTIDK.V", value: 1977770, partition: 0 },
	{ label: "PESDT.V", value: 1977773, partition: 0 },
	{ label: "RYSAS.E", value: 77636, partition: 0 },
	{ label: "NVSAA.V", value: 273409, partition: 0 },
	{ label: "TKSDS.V", value: 2372201, partition: 0 },
	{ label: "PLSPC.V", value: 1715792, partition: 0 },
	{ label: "YPICG.V", value: 3028618, partition: 0 },
	{ label: "TASCT.V", value: 5124531, partition: 0 },
	{ label: "TAAQB.V", value: 5124532, partition: 0 },
	{ label: "YBAFK.V", value: 3028620, partition: 0 },
	{ label: "SBIXI.V", value: 3682158, partition: 0 },
	{ label: "SLIHB.V", value: 667845, partition: 0 },
	{ label: "KDIIB.V", value: 1583367, partition: 0 },
	{ label: "SLACZ.V", value: 667846, partition: 0 },
	{ label: "KRAER.V", value: 1583368, partition: 0 },
	{ label: "TLIYB.V", value: 2634203, partition: 0 },
	{ label: "KDSDQ.V", value: 1583369, partition: 0 },
	{ label: "TOILJ.V", value: 4600562, partition: 0 },
	{ label: "TOSQD.V", value: 4600564, partition: 0 },
	{ label: "SISDJ.V", value: 5648521, partition: 0 },
	{ label: "TOACG.V", value: 4600565, partition: 0 },
	{ label: "NAIAL.V", value: 2763765, partition: 0 },
	{ label: "KTLEV.E", value: 207218, partition: 0 },
	{ label: "PEAGA.V", value: 4468140, partition: 0 },
	{ label: "SPISC.V", value: 2896206, partition: 0 },
	{ label: "MTSPC.V", value: 797418, partition: 0 },
	{ label: "TKAUC.V", value: 4862568, partition: 0 },
	{ label: "IYIJL.V", value: 1062291, partition: 0 },
	{ label: "IZAKJ.V", value: 1062292, partition: 0 },
	{ label: "SAHOL.E", value: 77676, partition: 0 },
	{ label: "KTSKR.E", value: 207238, partition: 0 },
	{ label: "TLIVK.V", value: 5124571, partition: 0 },
	{ label: "KRATR.V", value: 4073736, partition: 0 },
	{ label: "PGASC.V", value: 1977824, partition: 0 },
	{ label: "SLACT.V", value: 3158214, partition: 0 },
	{ label: "SASCZ.V", value: 6172528, partition: 0 },
	{ label: "TLAUB.V", value: 5124572, partition: 0 },
	{ label: "SAMAT.E", value: 77696, partition: 0 },
	{ label: "PESDN.V", value: 6958509, partition: 0 },
	{ label: "PBIOJ.V", value: 2830024, partition: 0 },
	{ label: "KZIHK.V", value: 1845411, partition: 0 },
	{ label: "TRIAH.V", value: 1848290, partition: 0 },
	{ label: "IYIUF.V", value: 3552659, partition: 0 },
	{ label: "KZAEA.V", value: 1845412, partition: 0 },
	{ label: "PGILN.V", value: 4468191, partition: 0 },
	{ label: "SANEL.E", value: 77716, partition: 0 },
	{ label: "PGADO.V", value: 4468192, partition: 0 },
	{ label: "AXASK.V", value: 3552670, partition: 0 },
	{ label: "AXSAS.V", value: 3552671, partition: 0 },
	{ label: "NASPY.V", value: 7744500, partition: 0 },
	{ label: "TYIPB.V", value: 3748454, partition: 0 },
	{ label: "TAIFI.V", value: 405938, partition: 0 },
	{ label: "TATEN.E", value: 78226, partition: 0 },
	{ label: "KZGYO.E", value: 76201, partition: 0 },
	{ label: "BAHKM.E", value: 71906, partition: 0 },
	{ label: "DCTTR.E", value: 203943, partition: 0 },
];