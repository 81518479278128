import React from "react";
import { red, green } from "@material-ui/core/colors";

const Side = ({ side }) => (
	<div
		className="side-label"
		style={{
			color: (side === "Buy" ? green : red)[500],
			fontWeight: "bold",
		}}
	>
		{side === "Buy"
			? "ALIŞ"
			: side === "ShortSell"
			? "AÇIĞA SATIŞ"
			: "SATIŞ"}
	</div>
);

export default Side;
