import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import books from "../data";
import {
	Table,
	TableHead,
	TableBody,
	TableRow,
	TableCell,
	TableContainer,
	Button,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import moment from "moment";
import api from "../api";
import Side from "./Side";
import "./SentOrders.css";
import { formatPrice, formatQuantity } from "../modules/formatter";
import { activeProfile } from "../modules/profiles";
import ReplaceSentOrderForm from "./ReplaceSentOrderForm";
import Modal, { ModalHeader } from "./Modal";
import { stringifyAccountId } from "../data/accounts";
import { BigNumber } from "json-bignum";

const sorter = (a, b) => BigNumber(b.order_id).cmp(a.id);

const SentOrders = ({ byId }) => {
	const [showCancelled, setShowCancelled] = useState(
		!!activeProfile.defaultShowCancelled
	);
	const [showReplaceForm, setShowReplaceForm] = useState(null);
	const [data, setData] = useState([]);

	const handleCloseReplaceForm = () => setShowReplaceForm(null);

	useEffect(() => {
		setData(Object.values(byId).sort(sorter));
	}, [byId]);

	return (
		<>
			<Modal show={!!showReplaceForm} onClose={handleCloseReplaceForm}>
				<ModalHeader
					title="Emri Düzenle"
					onClose={handleCloseReplaceForm}
				/>
				<ReplaceSentOrderForm
					onSubmit={(values) => {
						api.send("ReplaceSentOrder", [
							values.order_book_id,
							values.initial_token,
							values.price,
							values.quantity,
						]);

						setShowReplaceForm(null);
					}}
					initialValues={showReplaceForm || {}}
				/>
			</Modal>

			<div className="h-full flex">
				<div className="bg-gray-300 w-36">Test dsafksd jfaksj fkas</div>

				<div className="flex-grow">
					<TableContainer
						className="sent-orders"
						style={{ maxHeight: "100%" }}
					>
						<Table size="small" stickyHeader>
							<TableHead>
								<TableRow>
									<TableCell>Zaman</TableCell>
									<TableCell>Hesap</TableCell>
									<TableCell>Kod</TableCell>
									<TableCell>A/S</TableCell>
									<TableCell>Fiyat</TableCell>
									<TableCell>Gerçekleşen</TableCell>
									<TableCell>Miktar</TableCell>
									<TableCell
										style={{
											width: 250,
											textAlign: "right",
										}}
									>
										<label>
											İptaller{" "}
											<input
												type="checkbox"
												onChange={(e) =>
													setShowCancelled(
														e.target.checked
													)
												}
												checked={showCancelled}
											/>
										</label>
									</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{data.map((order) => {
									const canCancel =
										!order.cancelled &&
										order.executed_so_far < order.quantity;

									return (
										(showCancelled || canCancel) &&
										(showCancelled ||
											order.quantity > 1000) && (
											<TableRow
												key={order.id}
												id={
													"order-" +
													order.order_id.toString()
												}
											>
												<TableCell
													title={JSON.stringify(
														order
													)}
												>
													{moment(
														order.created_at * 1000
													).format("HH:mm:ss")}{" "}
													({order.id})
												</TableCell>
												<TableCell>
													{order.account
														? stringifyAccountId(
																order.account.id
														  )
														: ""}
												</TableCell>
												<TableCell>
													{books[order.order_book_id]
														? books[
																order
																	.order_book_id
														  ].label
														: order.order_book_id}
												</TableCell>
												<TableCell>
													<Side side={order.side} />
												</TableCell>
												<TableCell>
													{formatPrice(
														order.order_book_id,
														order.price
													)}
												</TableCell>
												<TableCell>
													{formatQuantity(
														order.executed_so_far
													)}
												</TableCell>
												<TableCell>
													{formatQuantity(
														order.quantity
													)}
												</TableCell>

												<TableCell>
													{order.reject_code ? (
														<strong className="text-red-600">
															Hata:{" "}
															{order.reject_code}
														</strong>
													) : (
														<>
															<Button
																disabled={
																	!canCancel
																}
																size="small"
																onClick={() =>
																	setShowReplaceForm(
																		order
																	)
																}
															>
																Düzenle
															</Button>
															<Button
																disabled={
																	!canCancel
																}
																startIcon={
																	<DeleteIcon
																		style={{
																			fontSize: 18,
																		}}
																	/>
																}
																size="small"
																onClick={() => {
																	if (
																		window.confirm(
																			"Emri iptal etmek istediğinize emin misiniz?"
																		)
																	) {
																		api.send(
																			"SentOrderCancel",
																			order
																		);
																	}
																}}
															>
																İptal et
															</Button>
														</>
													)}
												</TableCell>
											</TableRow>
										)
									);
								})}
							</TableBody>
						</Table>
					</TableContainer>
				</div>
			</div>
		</>
	);
};

const mapStateToProps = (state) => state.sentOrders;

export default connect(mapStateToProps)(SentOrders);