export const MORNING_ORDERS_UPDATE = "MORNING_ORDERS_UPDATE";

const initialState = [];

export const updateMorningOrders = (data) => ({
	type: MORNING_ORDERS_UPDATE,
	payload: data,
});

export default function levelDetailsReducer(state = initialState, action) {
	switch (action.type) {
		case MORNING_ORDERS_UPDATE:
			return action.payload;
		default:
			return state;
	}
}
