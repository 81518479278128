import React from "react";
import OldSentOrders from "../../components/OldSentOrders";
import Split from "react-split";
import OrderBooks from "../../components/OrderBooks";
import SentOrders from "../../components/SentOrders";

const Home = () => {
	return (
		<Split
			sizes={[70, 30]}
			expandToMin={false}
			gutterSize={7}
			gutterAlign="center"
			dragInterval={1}
			direction="vertical"
			cursor="col-resize"
			style={{ height: "100%" }}
		>
			<div className="overflow-auto">
				<OrderBooks />
			</div>

			<div className="overflow-auto border-t">
				<SentOrders />
			</div>
		</Split>
	);
};

export default Home;