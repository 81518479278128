import React from "react";
import { Field } from "redux-form";
import { activeProfile } from "../../modules/profiles";
import classNames from "classnames";

const NumericField = ({ input, label, ...otherProps }) => {
	const handleKeyPress = (event) => {
		if (event.key === "+") {
			input.onChange(`${input.value}000`);
			event.preventDefault();
		}
	};

	return (
		<input
			placeholder={label}
			onKeyPress={handleKeyPress}
			{...input}
			{...otherProps}
			autoComplete="off"
			type="text"
			className={classNames(
				"block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md disabled:opacity-75 disabled:pointer-events-none",
				activeProfile.simpleRuleForm ? "py-0" : "py-1"
			)}
		/>
	);
};

const QuantityField = (props) => (
	<Field
		parse={(value) => parseInt(value.replace(/ /g, "")) || 0}
		format={(value) =>
			value > 0
				? new Intl.NumberFormat("en-EN")
						.format(value)
						.replace(/,/g, " ")
				: ""
		}
		component={NumericField}
		onFocus={(e) => e.target.select()}
		{...props}
	/>
);

export default QuantityField;
