import React from "react";
import { getFormValues, reduxForm, submit } from "redux-form";
import PriceField from "./OrderBooks/PriceField";
import QuantityField from "./OrderBooks/QuantityField";
import classNames from "classnames";
import { useDispatch, useSelector } from "react-redux";
import Button from "./Button";
import books from "../data";

const Row = ({ children, label }) => (
	<div className="mb-4">
		<label
			htmlFor="quantity"
			className="block text-sm font-semibold text-gray-700 mb-1"
		>
			{label}
		</label>

		{children}
	</div>
);

const sideClasses = {
	Buy: "bg-green-100",
	Sell: "bg-red-100",
	ShortSell: "bg-red-200",
};

const ReplaceSentOrderForm = () => {
	const dispatch = useDispatch();
	const values = useSelector(getFormValues("replace-sent-order"));

	const handleSubmitConfirmation = (event) => {
		event.preventDefault();

		if (window.confirm("Emin misiniz?")) {
			dispatch(submit("replace-sent-order"));
		}
	};

	if (!values) return null;

	return (
		<form
			onSubmit={handleSubmitConfirmation}
			className={classNames("p-4", sideClasses[values.side])}
		>
			<Row label="Miktar">
				<QuantityField name="quantity" required autoFocus />
			</Row>
			<Row label="Fiyat">
				<PriceField
					book={books[values.order_book_id]}
					name="price"
					required
				/>
			</Row>

			<Button className="w-full" type="submit">
				Emri gönder
			</Button>
		</form>
	);
};

export default reduxForm({
	form: "replace-sent-order",
	enableReinitialize: true,
})(ReplaceSentOrderForm);
