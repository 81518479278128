import { useSnackbar } from "notistack";
import React from "react";
import { activeProfileId } from "./profiles";

const InnerSnackbarUtilsConfigurator = (props) => {
	props.setUseSnackbarRef(useSnackbar());
	return null;
};

let useSnackbarRef;
const setUseSnackbarRef = (useSnackbarRefProp) => {
	useSnackbarRef = useSnackbarRefProp;
};

export const SnackbarUtilsConfigurator = () => {
	return (
		<InnerSnackbarUtilsConfigurator setUseSnackbarRef={setUseSnackbarRef} />
	);
};

export default {
	success(msg, options = {}) {
		this.toast(msg, {
			...options,
			anchorOrigin: {
				horizontal: "right",
				vertical: activeProfileId === "yigit" ? "bottom" : "top",
			},
			autoHideDuration: 3500,
			variant: "success",
		});
	},
	warning(msg, options = {}) {
		this.toast(msg, {
			...options,
			anchorOrigin: {
				horizontal: "right",
				vertical: activeProfileId === "yigit" ? "bottom" : "top",
			},
			autoHideDuration: 3500,
			variant: "warning",
		});
	},
	info(msg, options = {}) {
		this.toast(msg, {
			...options,
			anchorOrigin: {
				horizontal: "right",
				vertical: activeProfileId === "yigit" ? "bottom" : "top",
			},
			autoHideDuration: 3500,
			variant: "info",
		});
	},
	error(msg, options = {}) {
		this.toast(msg, {
			...options,
			anchorOrigin: {
				horizontal: "right",
				vertical: activeProfileId === "yigit" ? "bottom" : "top",
			},
			autoHideDuration: 3500,
			variant: "error",
		});
	},
	toast(msg, options = {}) {
		useSnackbarRef.enqueueSnackbar(msg, options);
	},
};
