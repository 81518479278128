import React from "react";
import { Field } from "redux-form";
import NumberFormat from "react-number-format";
import classNames from "classnames";
import { activeProfile } from "../../modules/profiles";

function NumericField(props) {
	const {
		inputRef,
		input: { onChange, value },
		...other
	} = props;

	const handleKeyPress = (event) => {
		if (event.key === "ArrowDown" && value > 0) {
			onChange(value - 0.01);
			event.preventDefault();
		} else if (event.key === "ArrowUp" && value > 0) {
			onChange(value + 0.01);
			event.preventDefault();
		}
	};

	return (
		<NumberFormat
			{...other}
			value={value}
			getInputRef={inputRef}
			onValueChange={({ value }) => onChange(value)}
			onKeyDown={handleKeyPress}
			onFocus={(e) => e.target.select()}
			decimalScale={props.decimalScale || 2}
			fixedDecimalScale={props.decimalScale || 2}
			decimalSeparator=","
			allowedDecimalSeparators={[",", "."]}
			className={classNames(
				"px-2 block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md disabled:opacity-75 disabled:pointer-events-none",
				activeProfile.simpleRuleForm ? "py-0" : "py-1"
			)}
		/>
	);
}

export const DecimalField = (props) => {
	return (
		<Field
			parse={(value) => parseFloat(value)}
			format={(value) => value}
			component={NumericField}
			placeholder={props.label}
			{...props}
		/>
	);
};

const PriceField = (props) => {
	const coeff = props.book && props.book.label.startsWith("F_") ? 100 : 1000;

	return (
		<Field
			parse={(value) => (value === "" ? "" : Math.round(value * coeff))}
			format={(value) => (value === "" ? "" : value / coeff)}
			component={NumericField}
			placeholder={props.label}
			{...props}
		/>
	);
};

export default PriceField;
