import books from "../data";
import { stringifyAccountId } from "../data/accounts";
import { activeProfile, allowedAccounts, hasAccessToRule } from "./profiles";

export const RULES_REMOVE = "RULES_REMOVE";
export const RULES_ADD = "RULES_ADD";
export const RULES_UPDATE = "RULES_UPDATE";
export const RULES_ACTIVATE = "RULES_ACTIVATE";
export const RULES_DEACTIVATE = "RULES_DEACTIVATE";
export const RULES_READY = "RULES_READY";
export const RULES_UPDATE_FILTER = "RULES_UPDATE_FILTER";

export const add = (triggers) => ({ type: RULES_ADD, payload: triggers });
export const remove = (ids) => ({ type: RULES_REMOVE, payload: ids });
export const update = (rules) => ({ type: RULES_UPDATE, payload: rules });
export const activate = (ids) => ({ type: RULES_ACTIVATE, payload: ids });
export const deactivate = (ids) => ({ type: RULES_DEACTIVATE, payload: ids });
export const ready = () => ({ type: RULES_READY });
export const updateFilter = (filter) => ({
	type: RULES_UPDATE_FILTER,
	payload: filter,
});

const generateStopPoints = (rule, stopTriggerAbove = false) => {
	let base = rule.condition.price;
	let step = 10;

	if (books[rule.condition.order_book[0]].label.startsWith("F_")) {
		// VIOP
		if (base > 1300) {
			// Endeks
			step = 25;
		} else {
			// Pay vade
			step = 1;
		}
	} else {
		// Pay
		if (base >= 100000) {
			step = 100;
		} else if (base >= 50000) {
			step = 50;
		} else if (base >= 20000) {
			step = 20;
		} else {
			step = 10;
		}
	}

	// Yukarı doğru artması gereken stoplar
	if (rule.condition.order_book[1] === "Buy") {
		step = step * -1;
	}

	// Bazen alışa satışa gelirse diye stop kuruyorsak
	// Direkt bir alt kademeden başlaması lazım
	if (stopTriggerAbove) {
		base -= step;
	}

	return [base, base - step, base - step * 2, base - step * 3];
};

export const prepareBlankRule = (orderBookId, conditionSide, actionSide) => ({
	id: 0,
	meta: null,
	condition: {
		order_book: [orderBookId, conditionSide],
		price: 0,
		limit: 0,
		limit_percentage: null,
	},
	action: {
		order_book: [orderBookId, actionSide],
		time_in_force: 0,
		account: allowedAccounts[0],
		price: 0,
		quantity: 0,
	},
	auto_exit: null,
});

export const prepareRuleForForm = (rule) => {
	let initialValues = JSON.parse(JSON.stringify(rule));

	// Yüzdelik gösterim sunucuda 0.3 şeklinde ama arayüzde 30 şeklinde
	if (initialValues.condition.limit_percentage !== null) {
		initialValues.condition.limit_percentage *= 100;
	}

	initialValues.action.account = "0";
	initialValues.auto_exit_bool = rule.auto_exit !== null;

	allowedAccounts.forEach((acc, i) => {
		if (
			stringifyAccountId(acc.id) ===
			stringifyAccountId(rule.action.account.id)
		) {
			initialValues.action.account = `${i}`;
		}
	});

	return initialValues;
};

export const prepareRuleForExistingSentOrder = (order, values) => {
	const conditionOrderBookId = parseInt(values.order_book_id);
	const differentConditionActionOrderBooks =
		conditionOrderBookId !== order.order_book_id;

	let rule = {
		id: 0,
		meta: differentConditionActionOrderBooks ? 2 : null,
		condition: {
			order_book: [
				conditionOrderBookId,
				order.side === "Buy" ? "Sell" : "Buy",
			],
			price: values.price,
			limit: Math.max(1, parseInt(values.limit) || 0),
			limit_percentage: null,
		},
		action: {
			order_book: [order.order_book_id, order.side],
			price: order.price,
			quantity: order.quantity,
			time_in_force: 0,
			account: allowedAccounts[parseInt(values.account)],
		},
	};

	rule.auto_exit = generateStopPoints(
		rule,
		differentConditionActionOrderBooks
	);

	return rule;
};

export const prepareRuleForApi = (values) => {
	let rule = JSON.parse(JSON.stringify(values));

	// Account
	rule.action.account = allowedAccounts[parseInt(rule.action.account)];

	// Auto-exit
	if (rule.auto_exit_bool) {
		rule.auto_exit = generateStopPoints(rule);
	} else {
		rule.auto_exit = null;
	}

	// Limit percentage
	rule.condition.limit_percentage = rule.condition.limit_percentage
		? Math.round(rule.condition.limit_percentage) / 100
		: null;

	// Type cast
	rule.condition.order_book[0] = parseInt(rule.condition.order_book[0]);
	rule.action.time_in_force = parseInt(rule.action.time_in_force);
	rule.action.order_book[0] = parseInt(rule.action.order_book[0]);

	// Simple form'da condition ve action gibi seçenekler yok
	if (activeProfile.simpleRuleForm) {
		rule.condition.order_book[1] =
			rule.action.order_book[1] === "Buy" ? "Sell" : "Buy";
		rule.action.order_book[0] = rule.condition.order_book[0];
		rule.action.price = rule.condition.price;
	}

	return { ...rule, auto_exit_bool: undefined };
};

const initialState = {
	ready: false,
	activeIds: [],
	ids: [],
	byId: {},
	filter: {
		conditionOrderBookId: null,
		conditionOrderBookSide: null,
		actionOrderBookId: null,
		quantity: null,
	},
};

export default function ordersReducer(state = initialState, action) {
	switch (action.type) {
		case "RECONNECT":
			return initialState;
		case RULES_UPDATE_FILTER:
			return { ...state, filter: action.payload };
		case RULES_READY:
			return { ...state, ready: true };
		case RULES_ADD:
		case RULES_UPDATE:
			const affectedRules = action.payload.filter(hasAccessToRule);

			if (affectedRules.length === 0) {
				return state;
			}

			let byId = { ...state.byId };
			let ids = [...state.ids];
			affectedRules.forEach((t) => {
				if (ids.indexOf(t.id) === -1) {
					ids.push(t.id);
				}

				byId[t.id] = t;
			});

			return { ...state, byId, ids };
		case RULES_REMOVE:
			const affectedIds = action.payload.filter(
				(id) => state.byId[id] && hasAccessToRule(state.byId[id])
			);

			if (affectedIds.length === 0) {
				return state;
			}

			const removedIds = state.ids.filter(
				(id) => affectedIds.indexOf(id) === -1
			);
			let removedById = { ...state.byId };
			affectedIds.forEach((id) => delete removedById[id]);

			return { ...state, byId: removedById, ids: removedIds };
		case RULES_ACTIVATE:
			return {
				...state,
				activeIds: [...state.activeIds, ...action.payload],
			};
		case RULES_DEACTIVATE:
			return {
				...state,
				activeIds: state.activeIds.filter(
					(id) => action.payload.indexOf(id) === -1
				),
			};
		default:
			return state;
	}
}
