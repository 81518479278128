import React from "react";

const Header = ({ title, onClose }) => {
	return (
		<div className="border-b border-gray-200">
			<div className="flex justify-between relative bg-gray-900">
				<h3
					className="capitalize text-lg pr-10 leading-6 font-semibold text-white py-2 px-4"
					id="modal-headline"
				>
					{title}
				</h3>
				<div className="absolute right-2 top-2 h-full">
					<button
						onClick={onClose}
						type="button"
						className="flex h-full justify-end w-10 text-gray-400 hover:text-gray-500 focus:outline-none focus:text-gray-500 transition ease-in-out duration-150"
						aria-label="Close"
					>
						<svg
							className="h-6 w-6"
							fill="none"
							viewBox="0 0 24 24"
							stroke="currentColor"
						>
							<path
								strokeLinecap="round"
								strokeLinejoin="round"
								strokeWidth="2"
								d="M6 18L18 6M6 6l12 12"
							/>
						</svg>
					</button>
				</div>
			</div>
		</div>
	);
};

export default Header;
