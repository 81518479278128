export default [
	{ label: "F_ASELS1222", value: 857968, partition: 2 },
	{ label: "F_ISFIN1022", value: 665080, partition: 2 },
	{ label: "F_HALKB1122", value: 18621464, partition: 2 },
	{ label: "F_FROTO1022", value: 2761132, partition: 2 },
	{ label: "F_FROTO1122", value: 5251500, partition: 2 },
	{ label: "F_ECILC1222", value: 466612, partition: 2 },
	{ label: "F_EKGYO1222", value: 1318800, partition: 2 },
	{ label: "F_DOHOL1122", value: 728636, partition: 2 },
	{ label: "F_ECILC1022", value: 728756, partition: 2 },
	{ label: "F_EREGL1222", value: 1318960, partition: 2 },
	{ label: "F_ISGYO1022", value: 1189408, partition: 2 },
	{ label: "F_DOHOL1022", value: 990780, partition: 2 },
	{ label: "F_ASELS1022", value: 7739248, partition: 2 },
	{ label: "F_HALKB1022", value: 7218200, partition: 2 },
	{ label: "F_BIMAS1222", value: 858508, partition: 2 },
	{ label: "F_ARCLK1122", value: 3282640, partition: 2 },
	{ label: "F_AEFES1122", value: 726096, partition: 2 },
	{ label: "F_ISCTR1022", value: 10298852, partition: 2 },
	{ label: "F_ENJSA1122", value: 729066, partition: 2 },
	{ label: "F_EREGL1122", value: 1843248, partition: 2 },
	{ label: "F_ENJSA1022", value: 991210, partition: 2 },
	{ label: "F_CCOLA1222", value: 596844, partition: 2 },
	{ label: "F_AKBNK1122", value: 3740812, partition: 2 },
	{ label: "F_ALKIM1122", value: 726556, partition: 2 },
	{ label: "F_AEFES1222", value: 398416, partition: 2 },
	{ label: "F_GUBRF1222", value: 1057776, partition: 2 },
	{ label: "F_AKBNK1022", value: 8983692, partition: 2 },
	{ label: "F_ISGYO1122", value: 1386016, partition: 2 },
	{ label: "F_BIMAS1022", value: 4397452, partition: 2 },
	{ label: "F_ARCLK1022", value: 12916432, partition: 2 },
	{ label: "F_AEFES1022", value: 660560, partition: 2 },
	{ label: "F_FROTO1222", value: 991660, partition: 2 },
	{ label: "F_EKGYO1122", value: 14229392, partition: 2 },
	{ label: "F_BIMAS1122", value: 2431372, partition: 2 },
	{ label: "F_AKBNK1222", value: 3413132, partition: 2 },
	{ label: "F_CCOLA1122", value: 531308, partition: 2 },
	{ label: "F_ALKIM1222", value: 398876, partition: 2 },
	{ label: "F_ALKIM1022", value: 661020, partition: 2 },
	{ label: "F_GUBRF1122", value: 992240, partition: 2 },
	{ label: "F_GARAN1022", value: 3416532, partition: 2 },
	{ label: "F_ASELS1122", value: 3151728, partition: 2 },
	{ label: "F_EREGL1022", value: 1974320, partition: 2 },
	{ label: "F_ISFIN1122", value: 730616, partition: 2 },
	{ label: "F_CCOLA1022", value: 465772, partition: 2 },
	{ label: "F_ARCLK1222", value: 2561744, partition: 2 },
	{ label: "F_GARAN1222", value: 1450452, partition: 2 },
	{ label: "F_EKGYO1022", value: 11083664, partition: 2 },
	{ label: "F_GUBRF1022", value: 926704, partition: 2 },
	{ label: "F_ECILC1122", value: 794292, partition: 2 },
	{ label: "F_HALKB1222", value: 926744, partition: 2 },
	{ label: "F_DOHOL1222", value: 1056316, partition: 2 },
	{ label: "F_GARAN1122", value: 19145172, partition: 2 },
	{ label: "F_ISCTR1122", value: 10102244, partition: 2 },
	{ label: "F_ISFIN1222", value: 402936, partition: 2 },
	{ label: "F_ISGYO1222", value: 402976, partition: 2 },
	{ label: "F_ISCTR1222", value: 1779172, partition: 2 },
	{ label: "F_ENJSA1222", value: 794602, partition: 2 },
];
