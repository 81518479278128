export default [
	{ label: "F_KCHOL1022", value: 6956936, partition: 3 },
	{ label: "F_TAVHL1022", value: 2568624, partition: 3 },
	{ label: "F_TLREF1M0123", value: 685485, partition: 3 },
	{ label: "F_XLBNK1022", value: 472465, partition: 3 },
	{ label: "F_X10XB1022", value: 472466, partition: 3 },
	{ label: "F_USDTRY0123", value: 1209668, partition: 3 },
	{ label: "F_MGROS1022", value: 207648, partition: 3 },
	{ label: "F_TRGYO1022", value: 668440, partition: 3 },
	{ label: "F_OYAKC1222", value: 1060086, partition: 3 },
	{ label: "F_XU0300223", value: 32060869, partition: 3 },
	{ label: "F_SKBNK1122", value: 864408, partition: 3 },
	{ label: "F_PGSUS1122", value: 9514460, partition: 3 },
	{ label: "F_TURSG1022", value: 668660, partition: 3 },
	{ label: "F_EURTRY1223", value: 357710, partition: 3 },
	{ label: "F_THYAO1222", value: 1716836, partition: 3 },
	{ label: "F_RUBTRY1223", value: 357714, partition: 3 },
	{ label: "F_ULKER1222", value: 668700, partition: 3 },
	{ label: "F_CNHTRY1223", value: 357715, partition: 3 },
	{ label: "F_GBPUSD1223", value: 357717, partition: 3 },
	{ label: "F_EURUSD1223", value: 357720, partition: 3 },
	{ label: "F_XAUTRYM1022", value: 357730, partition: 3 },
	{ label: "F_XAUUSD1022", value: 357740, partition: 3 },
	{ label: "F_XPDUSD0223", value: 357742, partition: 3 },
	{ label: "F_XPTUSD0223", value: 357743, partition: 3 },
	{ label: "F_TAVHL1122", value: 4141488, partition: 3 },
	{ label: "F_KRDMD1222", value: 2828548, partition: 3 },
	{ label: "F_ODAS1022", value: 732276, partition: 3 },
	{ label: "F_VESTL1022", value: 669000, partition: 3 },
	{ label: "F_SASA1222", value: 536548, partition: 3 },
	{ label: "F_PETKM1122", value: 15740340, partition: 3 },
	{ label: "F_ELCBAS0123", value: 162273, partition: 3 },
	{ label: "F_ELCBAS0223", value: 162274, partition: 3 },
	{ label: "F_ELCBAS0323", value: 162275, partition: 3 },
	{ label: "F_ELCBAS0423", value: 162276, partition: 3 },
	{ label: "F_ELCBAS1022", value: 162282, partition: 3 },
	{ label: "F_ELCBAS1122", value: 162283, partition: 3 },
	{ label: "F_ELCBAS1222", value: 162284, partition: 3 },
	{ label: "F_ELCBASQ224", value: 162286, partition: 3 },
	{ label: "F_ELCBASQ324", value: 162287, partition: 3 },
	{ label: "F_ELCBASQ424", value: 162288, partition: 3 },
	{ label: "F_SKBNK1222", value: 536728, partition: 3 },
	{ label: "F_TUPRS1022", value: 11219936, partition: 3 },
	{ label: "F_RUBTRY1022", value: 554322, partition: 3 },
	{ label: "F_CNHTRY1022", value: 554323, partition: 3 },
	{ label: "F_GBPUSD1022", value: 554325, partition: 3 },
	{ label: "F_TTKOM1122", value: 4666256, partition: 3 },
	{ label: "F_SOKM1222", value: 1061166, partition: 3 },
	{ label: "F_TOASO1022", value: 5190384, partition: 3 },
	{ label: "F_TLREF1M1122", value: 554413, partition: 3 },
	{ label: "F_XLBNK1222", value: 341393, partition: 3 },
	{ label: "F_X10XB1222", value: 341394, partition: 3 },
	{ label: "F_USDTRY0423", value: 1078596, partition: 3 },
	{ label: "F_KARSN1122", value: 796492, partition: 3 },
	{ label: "F_VESTL1222", value: 865608, partition: 3 },
	{ label: "F_OYAKC1022", value: 929014, partition: 3 },
	{ label: "F_TUPRS1122", value: 14169056, partition: 3 },
	{ label: "F_MGROS1222", value: 600864, partition: 3 },
	{ label: "F_SAHOL1122", value: 13512556, partition: 3 },
	{ label: "F_ULKER1022", value: 537628, partition: 3 },
	{ label: "F_GBPUSD1122", value: 226645, partition: 3 },
	{ label: "F_XPDUSD1022", value: 226670, partition: 3 },
	{ label: "F_XPTUSD1022", value: 226671, partition: 3 },
	{ label: "F_KOZAA1222", value: 796812, partition: 3 },
	{ label: "F_XAGUSD1222", value: 226673, partition: 3 },
	{ label: "F_KOZAL1222", value: 796832, partition: 3 },
	{ label: "F_SASX101022", value: 226708, partition: 3 },
	{ label: "F_TLREF1M0323", value: 226733, partition: 3 },
	{ label: "F_SISE1222", value: 1257604, partition: 3 },
	{ label: "F_USDTRY0823", value: 750916, partition: 3 },
	{ label: "F_TCELL1222", value: 733656, partition: 3 },
	{ label: "F_KARSN1222", value: 468812, partition: 3 },
	{ label: "F_TTKOM1222", value: 1586064, partition: 3 },
	{ label: "F_SASA1022", value: 405476, partition: 3 },
	{ label: "F_KCHOL1222", value: 468872, partition: 3 },
	{ label: "F_THYAO1122", value: 16265828, partition: 3 },
	{ label: "F_TKFEN1122", value: 733836, partition: 3 },
	{ label: "F_USDTRY0323", value: 1275204, partition: 3 },
	{ label: "F_TRGYO1122", value: 733976, partition: 3 },
	{ label: "F_TSKB1022", value: 734056, partition: 3 },
	{ label: "F_TURSG1122", value: 734196, partition: 3 },
	{ label: "F_USDTRY0124", value: 423236, partition: 3 },
	{ label: "F_EURTRY1222", value: 423246, partition: 3 },
	{ label: "F_EURUSD1222", value: 423256, partition: 3 },
	{ label: "F_XAUTRYM1222", value: 423266, partition: 3 },
	{ label: "F_XAUUSD1222", value: 423276, partition: 3 },
	{ label: "F_KOZAA1022", value: 993420, partition: 3 },
	{ label: "F_PETKM1222", value: 3026356, partition: 3 },
	{ label: "F_KOZAL1022", value: 993440, partition: 3 },
	{ label: "F_SOKM1022", value: 930094, partition: 3 },
	{ label: "F_SAHOL1022", value: 3878764, partition: 3 },
	{ label: "F_XLBNK0223", value: 210321, partition: 3 },
	{ label: "F_X10XB0223", value: 210322, partition: 3 },
	{ label: "F_SISE1022", value: 8532100, partition: 3 },
	{ label: "F_VAKBN1122", value: 21443792, partition: 3 },
	{ label: "F_USDTRY0623", value: 947524, partition: 3 },
	{ label: "F_PGSUS1222", value: 1650140, partition: 3 },
	{ label: "F_ODAS1122", value: 797812, partition: 3 },
	{ label: "F_TRGYO1222", value: 406296, partition: 3 },
	{ label: "F_TCELL1122", value: 15086040, partition: 3 },
	{ label: "F_ELCBASQ123", value: 227821, partition: 3 },
	{ label: "F_ELCBASY23", value: 227825, partition: 3 },
	{ label: "F_USDTRY0523", value: 1471812, partition: 3 },
	{ label: "F_SAHOL1222", value: 3026796, partition: 3 },
	{ label: "F_TOASO1222", value: 930544, partition: 3 },
	{ label: "F_TOASO1122", value: 16462576, partition: 3 },
	{ label: "F_SISE1122", value: 2502788, partition: 3 },
	{ label: "F_TURSG1222", value: 406516, partition: 3 },
	{ label: "F_TSKB1222", value: 930664, partition: 3 },
	{ label: "F_USDTRY1222", value: 1996100, partition: 3 },
	{ label: "F_PETKM1022", value: 9776564, partition: 3 },
	{ label: "F_KRDMD1122", value: 2566404, partition: 3 },
	{ label: "F_ODAS1222", value: 470132, partition: 3 },
	{ label: "F_TLREF1M1222", value: 619949, partition: 3 },
	{ label: "F_VAKBN1022", value: 9385168, partition: 3 },
	{ label: "F_USDTRY1123", value: 1144132, partition: 3 },
	{ label: "F_XU0301222", value: 28194245, partition: 3 },
	{ label: "F_OYAKC1122", value: 994550, partition: 3 },
	{ label: "F_SKBNK1022", value: 798872, partition: 3 },
	{ label: "F_EURTRY1122", value: 292174, partition: 3 },
	{ label: "F_THYAO1022", value: 1651300, partition: 3 },
	{ label: "F_RUBTRY1222", value: 292178, partition: 3 },
	{ label: "F_ULKER1122", value: 603164, partition: 3 },
	{ label: "F_CNHTRY1222", value: 292179, partition: 3 },
	{ label: "F_GBPUSD1222", value: 292181, partition: 3 },
	{ label: "F_EURUSD1122", value: 292184, partition: 3 },
	{ label: "F_XPDUSD1222", value: 292206, partition: 3 },
	{ label: "F_XPTUSD1222", value: 292207, partition: 3 },
	{ label: "F_XAGUSD0223", value: 292209, partition: 3 },
	{ label: "F_USDTRY0223", value: 2192708, partition: 3 },
	{ label: "F_SASX101222", value: 292244, partition: 3 },
	{ label: "F_TUPRS1222", value: 4404192, partition: 3 },
	{ label: "F_TLREF1M0423", value: 292269, partition: 3 },
	{ label: "F_USDTRY1122", value: 816452, partition: 3 },
	{ label: "F_SASA1122", value: 471012, partition: 3 },
	{ label: "F_YKBNK1022", value: 8533640, partition: 3 },
	{ label: "F_TKFEN1222", value: 799372, partition: 3 },
	{ label: "F_USDTRY1223", value: 1340740, partition: 3 },
	{ label: "F_YKBNK1222", value: 1980040, partition: 3 },
	{ label: "F_VAKBN1222", value: 1651920, partition: 3 },
	{ label: "F_TSKB1122", value: 799592, partition: 3 },
	{ label: "F_USDTRY1023", value: 1865028, partition: 3 },
	{ label: "F_USDTRY1022", value: 488772, partition: 3 },
	{ label: "F_EURTRY1022", value: 488782, partition: 3 },
	{ label: "F_XU0301022", value: 20460997, partition: 3 },
	{ label: "F_EURUSD1022", value: 488792, partition: 3 },
	{ label: "F_SOKM1122", value: 995630, partition: 3 },
	{ label: "F_PGSUS1022", value: 3091932, partition: 3 },
	{ label: "F_TTKOM1022", value: 6501264, partition: 3 },
	{ label: "F_TLREF1M1022", value: 488877, partition: 3 },
	{ label: "F_TAVHL1222", value: 4272560, partition: 3 },
	{ label: "F_KARSN1022", value: 730956, partition: 3 },
	{ label: "F_TCELL1022", value: 2896344, partition: 3 },
	{ label: "F_VESTL1122", value: 800072, partition: 3 },
	{ label: "F_ELCBASQ124", value: 293357, partition: 3 },
	{ label: "F_ELCBASQ223", value: 293358, partition: 3 },
	{ label: "F_ELCBASQ323", value: 293359, partition: 3 },
	{ label: "F_ELCBASQ423", value: 293360, partition: 3 },
	{ label: "F_ELCBASY24", value: 293361, partition: 3 },
	{ label: "F_TKFEN1022", value: 995980, partition: 3 },
	{ label: "F_USDTRY0723", value: 1537348, partition: 3 },
	{ label: "F_MGROS1122", value: 535328, partition: 3 },
	{ label: "F_USDTRY0923", value: 161092, partition: 3 },
	{ label: "F_RUBTRY1122", value: 161106, partition: 3 },
	{ label: "F_CNHTRY1122", value: 161107, partition: 3 },
	{ label: "F_KRDMD1022", value: 2107652, partition: 3 },
	{ label: "F_XAUTRYM0223", value: 161122, partition: 3 },
	{ label: "F_XAUUSD0223", value: 161132, partition: 3 },
	{ label: "F_KOZAA1122", value: 731276, partition: 3 },
	{ label: "F_XAGUSD1022", value: 161137, partition: 3 },
	{ label: "F_KOZAL1122", value: 731296, partition: 3 },
	{ label: "F_TLREF1M0223", value: 161197, partition: 3 },
	{ label: "F_YKBNK1122", value: 2700936, partition: 3 },
	{ label: "F_KCHOL1122", value: 15411080, partition: 3 },
	{ label: "F_HEKTS1122N1", value: 1385556, partition: 3 },
];
