export const RAHSANS_UPDATE = "RAHSANS_UPDATE";

export const update = (list) => ({ type: RAHSANS_UPDATE, payload: list });

export default function rahsansReducer(state = [], action) {
	switch (action.type) {
		case RAHSANS_UPDATE:
			return action.payload;
		default:
			return state;
	}
}
