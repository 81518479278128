import React from "react";
import { Field, reduxForm } from "redux-form";
import { stringifyAccountId } from "../../data/accounts";
import { allowedAccounts } from "../../modules/profiles";
import Button from "../Button";
import { DecimalField } from "../OrderBooks/PriceField";
import QuantityField from "../OrderBooks/QuantityField";
import { selectStyle } from "../RuleForm";
import SymbolField from "../SymbolField";

const Row = ({ children, label }) => (
	<div className="mb-4 flex-1 p-2">
		<label
			htmlFor="quantity"
			className="block text-sm font-semibold text-gray-700 mb-1"
		>
			{label}
		</label>

		{children}
	</div>
);

const RahsanForm = ({ handleSubmit }) => {
	const handleSubmitConfirmation = (event) => {
		event.preventDefault();

		if (
			window.confirm(
				"Emin misiniz? Değerler doğru değilse fazla açığa satış yapabilir sistem."
			)
		) {
			handleSubmit();
		}
	};

	return (
		<form onSubmit={handleSubmitConfirmation} className="p-2">
			<div className="flex">
				<Row label="Rüçhan">
					<Field
						name="ruchan_book_id"
						component={SymbolField}
						required
					/>
				</Row>
				<Row label="Hisse">
					<Field
						name="hisse_book_id"
						component={SymbolField}
						required
					/>
				</Row>
				<Row label="Hesap">
					<Field
						component="select"
						className={selectStyle}
						name="account"
					>
						{allowedAccounts.map((account, i) => (
							<option key={`${i}`} value={`${i}`}>
								{stringifyAccountId(account.id)}
							</option>
						))}
					</Field>
				</Row>
			</div>
			<div className="flex">
				<Row label="Rüçhan Oranı">
					<DecimalField
						name="oran"
						decimalScale={5}
						fixedDecimalScale={5}
						required
					/>
				</Row>
				<Row label="Rüçhan Bedeli">
					<DecimalField
						name="bedel"
						decimalScale={5}
						fixedDecimalScale={5}
						required
					/>
				</Row>
				<Row label="Spread">
					<QuantityField name="spread" required />
				</Row>
			</div>

			<Button className="w-full" type="submit">
				Başlat
			</Button>
		</form>
	);
};

export default reduxForm({
	form: "rahsan-form",
	initialValues: {
		account: "0",
	},
	enableReinitialize: true,
})(RahsanForm);
