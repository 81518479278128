import React from "react";
import { useSelector } from "react-redux";
import "./OrderBooks.css";
import BlankRow from "./BlankRow";
import { activeProfile, activeProfileId } from "../../modules/profiles";
import RuleForm from "../RuleForm";
import api, { updatedHere } from "../../api";
import {
	prepareBlankRule,
	prepareRuleForApi,
	prepareRuleForForm,
} from "../../modules/rules";
import { useState } from "react";
import { useMemo } from "react";
import { useEffect } from "react";
import Title from "./Title";
import { createSelector } from "reselect";

const Row = ({ rule, isActive, hideTitle }) => {
	const key = `${rule.id}`;
	const handleRemoveRule = () => api.send("RuleRemove", [rule.id]);

	return (
		<RuleForm
			key={key}
			form={key}
			hideTitle={hideTitle}
			isActive={isActive}
			initialValues={prepareRuleForForm(rule)}
			onSubmit={(values) => {
				updatedHere.push(rule.id);
				api.send(rule.id === 0 ? "RuleAdd" : "RuleUpdate", [
					prepareRuleForApi(values),
				]);
			}}
			onDeactivate={() => api.send("RuleDeactivate", [rule.id])}
			canRemove={!isActive}
			onRemove={handleRemoveRule}
		/>
	);
};

const readySelector = (state) => state.rules.ready;
const activeIdsSelector = (state) => state.rules.activeIds;
const rulesSelector = createSelector(
	(state, id) => id,
	(state) => state.rules.ids,
	(state) => state.rules.byId,
	(id, ids, byId) =>
		ids
			.map((ruleId) => byId[ruleId])
			.filter((rule) => rule.condition.order_book[0] === id)
);

const OrderBookRowContainer = ({ id, onRemove }) => {
	const minRulesPerSide = 2;
	const ready = useSelector(readySelector);
	const rules = useSelector((state) => rulesSelector(state, id));

	const buyRules = useMemo(
		() => rules.filter((rule) => rule.action.order_book[1] === "Buy"),
		[rules]
	);

	const sellRules = useMemo(
		() => rules.filter((rule) => rule.action.order_book[1] !== "Buy"),
		[rules]
	);

	const activeIds = useSelector(activeIdsSelector);

	const canRemove = useMemo(
		() =>
			rules.filter((rule) => activeIds.indexOf(rule.id) > -1).length ===
			0,
		[rules, activeIds]
	);

	useEffect(() => {
		if (!ready) {
			return;
		}

		// Boşsa satırlar doldur
		let newRules = [];
		for (let i = 0; i < minRulesPerSide - buyRules.length; i++) {
			newRules.push(prepareBlankRule(id, "Sell", "Buy"));
		}

		for (let i = 0; i < minRulesPerSide - sellRules.length; i++) {
			newRules.push(prepareBlankRule(id, "Buy", "Sell"));
		}

		if (newRules.length > 0) {
			api.send("RuleAdd", newRules);
		}
	}, [ready, buyRules.length, sellRules.length, id]);

	const [showLevels, setShowLevels] = useState(false);

	return (
		<>
			<tr>
				<Title
					rowSpan={1 + buyRules.length + sellRules.length}
					account="0"
					id={id}
					canRemove={canRemove}
					onRemove={onRemove}
					showLevels={showLevels}
					setShowLevels={setShowLevels}
				/>
			</tr>
			{buyRules.map((rule) => (
				<Row
					key={rule.id}
					rule={rule}
					isActive={activeIds.indexOf(rule.id) > -1}
					hideTitle
				/>
			))}

			{sellRules.map((rule) => (
				<Row
					key={rule.id}
					rule={rule}
					isActive={activeIds.indexOf(rule.id) > -1}
					hideTitle
				/>
			))}
		</>
	);
};

const RuleRowContainer = ({ id }) => {
	const rule = useSelector((state) => state.rules.byId[id]);
	const activeIds = useSelector((state) => state.rules.activeIds);

	if (rule.meta === 1 || rule.meta === 3) return null;

	return <Row index={0} rule={rule} isActive={activeIds.indexOf(id) > -1} />;
};

const headClass =
	"px-2 text-left text-xs font-semibold text-gray-800 uppercase tracking-wider";

const activeBookIdsSelector = createSelector(
	(state) => state.rules.byId,
	(state) => state.rules.activeIds,
	(byId, activeIds) => [
		...new Set(
			activeIds
				.filter((id) => byId[id])
				.map((id) => byId[id].condition.order_book[0])
		),
	]
);

const Rules = () => {
	const [orderBooks, setOrderBooks] = useState([]);
	const activeBookIds = useSelector(activeBookIdsSelector);
	const ruleIds = useSelector((state) => state.rules.ids);

	useEffect(() => {
		let updatedOrderBooks = [...orderBooks];
		activeBookIds.forEach((id) => {
			if (orderBooks.indexOf(id) === -1) {
				updatedOrderBooks.push(id);
			}
		});

		setOrderBooks(updatedOrderBooks);
	}, [activeBookIds]);

	const handleAddOrderBook = (id) =>
		setOrderBooks([...orderBooks.filter((i) => i !== id), id]);

	const handleAddRule = (id) =>
		api.send("RuleAdd", [prepareBlankRule(id, "Sell", "Buy")]);

	const handleRemoveOrderBook = (id) =>
		setOrderBooks(orderBooks.filter((i) => i !== id));

	return (
		<table className="min-w-full divide-y divide-gray-200">
			<thead className="bg-gray-200">
				<tr className="border-b border-gray-300">
					<td className={headClass} style={{ width: 50 }} />
					<td className={headClass} style={{ width: 180 }}>
						Kod
					</td>
					{activeProfileId === "ahlatci" && (
						<td className={headClass}>Hesap</td>
					)}
					<td className={`${headClass} pl-3`} style={{ width: 150 }}>
						Yön
					</td>
					{activeProfile.simpleRuleForm && (
						<td className={headClass} style={{ width: 150 }}>
							Miktar
						</td>
					)}
					<td className={headClass} style={{ width: 120 }}>
						Fiyat
					</td>
					<td
						className={headClass}
						style={{
							width: activeProfile.simpleRuleForm ? 250 : 150,
						}}
					>
						Kala
					</td>
					{activeProfile.simpleRuleForm ? (
						<>
							<td className={headClass} />
						</>
					) : (
						<>
							<td className={headClass}>H. A/S</td>
							<td className={headClass} style={{ width: 180 }}>
								H. Kod
							</td>
							<td className={headClass} style={{ width: 150 }}>
								H. Miktar
							</td>
							<td className={headClass} style={{ width: 120 }}>
								H. Fiyat
							</td>
							<td className={headClass} style={{ width: 100 }}>
								Tip
							</td>
							<td className={headClass}>Hesap</td>
						</>
					)}
					<td className={headClass} />
				</tr>
			</thead>
			<tbody>
				{!activeProfile.simpleRuleForm
					? ruleIds.map((id) => <RuleRowContainer key={id} id={id} />)
					: orderBooks.map((id) => (
							<OrderBookRowContainer
								onRemove={() => handleRemoveOrderBook(id)}
								key={id}
								id={id}
							/>
					  ))}
				<BlankRow
					onSelect={
						activeProfile.simpleRuleForm
							? handleAddOrderBook
							: handleAddRule
					}
					key={
						ruleIds.length ? `${ruleIds[ruleIds.length - 1]}` : null
					}
				/>
			</tbody>
		</table>
	);
};

export default Rules;