import React, { useState } from "react";
import api from "../../api";
import books, { booksByCode } from "../../data";
import { formatPrice, formatQuantity } from "../../modules/formatter";
import { allowedAccounts } from "../../modules/profiles";
import { prepareRuleForExistingSentOrder } from "../../modules/rules";
import store from "../../store";
import AutoExitForm from "../AutoExitForm";
import Button from "../Button";
import Modal, { ModalHeader } from "../Modal";

export const isViop = (orderBookId) =>
	books[orderBookId] && books[orderBookId].label.startsWith("F_");

export const getAccountSuggestion = (orderBookId, defaultValue = "0") =>
	isViop(orderBookId) ? `${allowedAccounts.length - 1}` : defaultValue;

export const getOrderBookSuggestion = (actionOrderBookId) => {
	if (isViop(actionOrderBookId)) {
		const spotCode = books[actionOrderBookId].label.substring(2, 7);
		const spotBook =
			booksByCode[
				spotCode.startsWith("TSKB")
					? "TSKB"
					: spotCode.startsWith("ODAS")
					? "ODAS"
					: spotCode
			];

		if (spotBook) {
			return spotBook.value;
		}
	}

	return actionOrderBookId;
};

const AutoExitStatus = ({ order }) => {
	const [showAutoExitForm, setShowAutoExitForm] = useState(null);

	const handleSubmit = (values) => {
		const orders = values.setup_all
			? Object.values(store.getState().sentOrders.byId).filter(
					(o) =>
						o.order_book_id === order.order_book_id &&
						o.side === order.side &&
						o.executed_so_far === 0 &&
						o.status !== "Close"
			  )
			: [order];

		orders.forEach((o) => {
			const orderStep = isViop(o.order_book_id) ? 1 : 10;
			const conditionStep = isViop(values.order_book_id) ? 1 : 10;
			const stepDifference = (order.price - o.price) / orderStep;
			const autoExitValues = {
				...values,
				price: values.price - stepDifference * conditionStep,
			};

			api.send("SentOrderAssignRule", [
				o.initial_token,
				prepareRuleForExistingSentOrder(o, autoExitValues),
			]);
		});

		setShowAutoExitForm(null);
	};

	const initialValues = {
		account: showAutoExitForm
			? getAccountSuggestion(showAutoExitForm.order_book_id)
			: "0",
		order_book_id:
			showAutoExitForm &&
			getOrderBookSuggestion(showAutoExitForm.order_book_id),
	};

	const handleClick = () => setShowAutoExitForm(order);
	const handleCloseAutoExitForm = () => setShowAutoExitForm(null);

	return (
		<>
			<Modal show={!!showAutoExitForm} onClose={handleCloseAutoExitForm}>
				<ModalHeader
					title="Oto Exit"
					onClose={handleCloseAutoExitForm}
				/>
				<AutoExitForm
					onSubmit={handleSubmit}
					order={order}
					initialValues={initialValues}
				/>
			</Modal>

			<Button
				className="py-0.5 font-bold px-2"
				color={!order.rule && "blue"}
				size="small"
				onClick={handleClick}
			>
				{order.rule && order.rule.auto_exit !== null ? (
					<span>
						{books[order.rule.condition.order_book[0]].label} @
						{formatPrice(
							order.rule.condition.order_book[0],
							order.rule.condition.price
						)}{" "}
						({formatQuantity(order.rule.condition.limit)})
					</span>
				) : (
					"Kur"
				)}
			</Button>
		</>
	);
};

export default AutoExitStatus;
