import React, { useEffect } from "react";
import { CssBaseline } from "@material-ui/core";
import { SnackbarProvider } from "notistack";
import { SnackbarUtilsConfigurator } from "./modules/notifications";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { useSelector } from "react-redux";
import "./index.css";
import Home from "./screens/Home";
import Menu from "./components/Menu";
import Executor from "./components/Executor";
import OldSentOrders from "./components/OldSentOrders";
import OrderBooks from "./components/OrderBooks";
import BrokerageSwitch from "./components/BrokerageSwitch";
import ManualOrderForm from "./components/ManualOrderForm";
import { submitManualOrder } from "./modules/manualOrderForm";
import { getAccountSuggestion } from "./components/SentOrders/AutoExitStatus";

const App = () => {
	const executorPlans = useSelector((state) => state.executor);

	useEffect(() => {
		const listener = (e) => {
			if (e.data.type === "manual-order") {
				submitManualOrder(e.data.values);
			}
		};

		window.addEventListener("message", listener);
		return () => window.removeEventListener("message", listener);
	}, []);

	return (
		<CssBaseline>
			<SnackbarProvider
				maxSnack={10}
				dense
				anchorOrigin={{ vertical: "top", horizontal: "right" }}
			>
				<SnackbarUtilsConfigurator />

				<Router>
					<div
						className="h-screen flex flex-col relative"
						style={{
							zIndex: window.outerWidth < 600 ? 10000 : undefined,
						}}
					>
						<div className="hidden md:block">
							<BrokerageSwitch />
						</div>

						<div className="flex-grow flex">
							<div
								className="w-20 bg-gray-900 h-full relative hidden md:block"
								id="menu-container"
							>
								<Menu />
							</div>
							<div className="flex-grow overflow-y-auto">
								<Switch>
									<Route path="/sent-orders">
										<OldSentOrders />
									</Route>
									<Route path="/rules">
										<OrderBooks />
									</Route>
									<Route path="/order">
										<div className="fixed z-50 bg-white left-0 top-0 w-screen h-screen">
											<ManualOrderForm
												onSubmit={(values) => {
													// Send values to the window that opened this popup
													window.opener.postMessage(
														{
															type: "manual-order",
															values,
														},
														"*"
													);
													window.close();
												}}
												initialValues={{
													time_in_force: "0",
													side:
														new URLSearchParams(
															window.location.search
														).get("side") ?? "Buy",
													order_book_id:
														new URLSearchParams(
															window.location.search
														).get("order_book_id"),
													account:
														getAccountSuggestion(
															70616
														),
												}}
											/>
										</div>
									</Route>
									<Route path="/">
										<Home />
									</Route>
								</Switch>
							</div>

							{executorPlans.map((p, i) => (
								<div
									key={i}
									className="w-40 border-l bg-red-100"
								>
									<Executor {...p} index={i} />
								</div>
							))}
						</div>
					</div>
				</Router>
			</SnackbarProvider>
		</CssBaseline>
	);
};

export default App;