import { hasAccessToRule } from "./profiles";

export const TRIGGERS_REMOVE = "TRIGGERS_REMOVE";
export const TRIGGERS_ADD = "TRIGGERS_ADD";
export const TRIGGERS_UPDATE = "TRIGGERS_UPDATE";

export const remove = (id) => ({ type: TRIGGERS_REMOVE, payload: id });
export const add = (triggers) => ({ type: TRIGGERS_ADD, payload: triggers });
export const update = (id, newLimit) => ({
	type: TRIGGERS_UPDATE,
	payload: { id, newLimit },
});

const initialState = {
	byId: {},
};

export default function ordersReducer(state = initialState, action) {
	switch (action.type) {
		case "RECONNECT":
			return initialState;
		case TRIGGERS_ADD:
			let byId = { ...state.byId };
			action.payload
				.filter((trigger) => hasAccessToRule(trigger.rule))
				.map((trigger) => (byId[JSON.stringify(trigger.id)] = trigger));

			return { ...state, byId };
		case TRIGGERS_REMOVE:
			let removedById = { ...state.byId };
			delete removedById[JSON.stringify(action.payload)];

			return { ...state, byId: removedById };
		case TRIGGERS_UPDATE:
			const id = JSON.stringify(action.payload.id);

			if (state.byId[id] && !hasAccessToRule(state.byId[id].rule)) {
				return state;
			}

			return {
				...state,
				byId: {
					...state.byId,
					[id]: { ...state.byId[id], limit: action.payload.newLimit },
				},
			};
		default:
			return state;
	}
}
