import React from "react";
import Autocomplete, {
	createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import { TextField } from "@material-ui/core";
import books from "../data";

const filterOptions = createFilterOptions({
	matchFrom: "start",
	limit: 10,
	stringify: (id) => books[id].label,
});

const SymbolField = ({ input, disabled, autoFocus }) => (
	<Autocomplete
		filterOptions={filterOptions}
		PopperComponent={({ children }) => (
			<div className="absolute">{children}</div>
		)}
		renderInput={(params) => (
			<TextField
				{...params}
				variant="outlined"
				size="small"
				placeholder="Sembol"
				style={{
					width: "100%",
					padding: 0,
					opacity: disabled ? 0.75 : 1,
				}}
				autoFocus={autoFocus}
			/>
		)}
		options={Object.keys(books)}
		autoHighlight
		disabled={disabled}
		blurOnSelect
		clearOnEscape
		getOptionLabel={(id) => id && books[id].label}
		value={input.value}
		onChange={(e, value) => input.onChange(value)}
	/>
);

export default SymbolField;