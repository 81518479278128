import React, { useState, useEffect, useRef, useCallback } from "react";
import "./executor.css";
import { Button, LinearProgress } from "@material-ui/core";
import classNames from "classnames";
import { formatQuantity } from "../../modules/formatter";
import { clear } from "../../modules/executor";
import { useDispatch } from "react-redux";
import api from "../../api";
import { allowedAccounts } from "../../modules/profiles";
import books from "../../data";

const Executor = ({
	index,
	orderBookId,
	side,
	price,
	quantities,
	millisecondsBetween,
}) => {
	const [active, setActive] = useState(false);
	const [lastSentIndex, setLastSentIndex] = useState(-1);
	const [elapsed, setElapsed] = useState(0);
	const timer = useRef();
	const refreshMs = 200;

	const tick = useCallback(() => {
		const newElapsed = elapsed + refreshMs;

		if (newElapsed > millisecondsBetween) {
			setElapsed(0);

			api.send("SendManualOrder", [
				parseInt(orderBookId),
				side,
				price,
				quantities[lastSentIndex + 1],
				3,
				allowedAccounts[0],
				null,
			]);
			setLastSentIndex(lastSentIndex + 1);
		} else {
			setElapsed(newElapsed);
		}
	}, [
		elapsed,
		millisecondsBetween,
		orderBookId,
		price,
		quantities,
		side,
		lastSentIndex,
	]);

	const startTimer = () => {
		setActive(true);
		timer.current = setTimeout(tick, refreshMs);
	};

	const stopTimer = () => {
		setActive(false);
		if (timer.current) clearInterval(timer.current);
	};

	// Take action when `elapsed` changes
	useEffect(() => {
		if (active && lastSentIndex + 1 < quantities.length) {
			timer.current = setTimeout(tick, refreshMs);
		}
	}, [elapsed, active, lastSentIndex, quantities.length, tick]);

	// Stop timer when unmounted
	useEffect(() => stopTimer, []);

	const dispatch = useDispatch();

	return (
		<div className="executor">
			<div className="text-center border-b border-gray-200 bg-gray-100 text-xs py-1 font-bold">
				{books[orderBookId]?.label}
			</div>
			<div className="executor__action">
				<div className="executor__action__1">
					{!active && (
						<Button
							variant="contained"
							color="primary"
							size="small"
							onClick={startTimer}
						>
							Başlat
						</Button>
					)}
					{active && (
						<Button
							variant="contained"
							color="secondary"
							size="small"
							onClick={stopTimer}
						>
							Durdur
						</Button>
					)}
				</div>
				<div className="executor__action__2">
					<Button
						variant="contained"
						size="small"
						onClick={() => dispatch(clear(index))}
					>
						X
					</Button>
				</div>
			</div>

			<div className="executor__plan">
				{quantities.map((quantity, i) => (
					<div
						className={classNames(
							"executor__plan__item",
							i <= lastSentIndex && "executor__plan__item--sent",
							active &&
								i === lastSentIndex + 1 &&
								"executor__plan__item--active"
						)}
						key={i}
					>
						<span>
							{formatQuantity(quantity)}
							{active && i === lastSentIndex + 1 && (
								<font style={{ float: "right" }}>
									{(
										(millisecondsBetween - elapsed) /
										1000
									).toFixed(1)}
									sn
								</font>
							)}
						</span>
						{active && i === lastSentIndex + 1 && (
							<LinearProgress
								variant="determinate"
								value={
									(elapsed /
										(millisecondsBetween - refreshMs)) *
									100
								}
							/>
						)}
					</div>
				))}
			</div>
		</div>
	);
};

export default Executor;