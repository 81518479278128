import React from "react";
import { Field, getFormValues, reduxForm, submit } from "redux-form";
import PriceField from "../OrderBooks/PriceField";
import QuantityField from "../OrderBooks/QuantityField";
import SymbolField from "../SymbolField";
import {
	activeProfile,
	activeProfileId,
	allowedAccounts,
} from "../../modules/profiles";
import { useDispatch, useSelector } from "react-redux";
import { stringifyAccountId } from "../../data/accounts";
import books from "../../data";
import classNames from "classnames";
import Title from "../OrderBooks/Title";
import api from "../../api";
import { prepareRuleForApi } from "../../modules/rules";
import { useState } from "react";

export const selectStyle = classNames(
	"w-full block focus:ring-indigo-500 focus:border-indigo-500 shadow-sm border-gray-300 rounded-md disabled:opacity-75 disabled:pointer-events-none px-2 text-gray-900",
	activeProfile.simpleRuleForm ? "py-0" : "py-1"
);

const colClass = activeProfile.simpleRuleForm ? "p-0.5" : "p-2";

const RuleForm = ({
	handleSubmit,
	onDeactivate,
	onRemove,
	canRemove,
	form,
	isActive,
	hideTitle,
}) => {
	const [showLevels, setShowLevels] = useState(false);
	const [showLevelsWithTriggers, setShowLevelsWithTriggers] = useState(false);
	const values = useSelector(getFormValues(form));
	const dispatch = useDispatch();

	return (
		<tr
			onDoubleClick={() => {
				if (!showLevels) {
					onDeactivate();
				}
			}}
			onKeyDown={(e) => {
				if (e.metaKey && e.key === "Enter") {
					dispatch(submit(form));
				}

				if (e.key === "F4") {
					// Bir kopya oluştur ve fiyat/miktar kolonlarını sıfırla
					let newRule = prepareRuleForApi(values);
					newRule.condition.price = 0;
					newRule.condition.limit = 0;
					newRule.action.price = 0;
					newRule.action.quantity = 0;
					api.send("RuleAdd", [newRule]);
				} else if (e.key === "F8") {
					// Kuralı sil
					onDeactivate();
					api.send("RuleRemove", [values.id]);
				}
			}}
			className={classNames(
				"transition border-b border-gray-300",
				values.action.order_book[1] === "Buy" &&
					!isActive &&
					"bg-green-50 hover:bg-green-100",
				values.action.order_book[1] === "Sell" &&
					!isActive &&
					"bg-red-50 hover:bg-red-100",
				values.action.order_book[1] === "ShortSell" &&
					!isActive &&
					"bg-red-100 hover:bg-red-200",
				values.action.order_book[1] === "Buy" &&
					isActive &&
					"bg-green-600",
				values.action.order_book[1] === "Sell" &&
					isActive &&
					"bg-red-600",
				values.action.order_book[1] === "ShortSell" &&
					isActive &&
					"bg-red-800"
			)}
		>
			{!hideTitle && (
				<Title
					account={values.action.account}
					id={values.condition.order_book[0]}
					canRemove={canRemove}
					onRemove={onRemove}
					showLevels={showLevels}
					setShowLevels={setShowLevels}
					showLevelsWithTriggers={showLevelsWithTriggers}
					setShowLevelsWithTriggers={setShowLevelsWithTriggers}
				/>
			)}
			{activeProfileId === "ahlatci" && (
				<td className={colClass}>
					<Field
						component="select"
						className={selectStyle}
						name="action.account"
						disabled={isActive}
					>
						{allowedAccounts.map((account, i) => (
							<option key={`${i}`} value={`${i}`}>
								{stringifyAccountId(account.id)}
							</option>
						))}
					</Field>
				</td>
			)}
			<td className="px-3">
				{activeProfile.simpleRuleForm ? (
					<Field
						component="select"
						name="action.order_book.1"
						className={selectStyle}
						disabled={isActive}
						autoFocus
					>
						<option value="Buy">Al</option>
						<option value="Sell">Sat</option>
						<option value="ShortSell">Açığa Sat</option>
					</Field>
				) : (
					<Field
						component="select"
						name="condition.order_book.1"
						className={selectStyle}
						disabled={isActive}
						autoFocus
					>
						<option value="Buy">Düşerken</option>
						<option value="Sell">Kalkarken</option>
					</Field>
				)}
			</td>
			{activeProfile.simpleRuleForm && (
				<td className={colClass}>
					<QuantityField
						disabled={isActive}
						label="Miktar"
						name="action.quantity"
					/>
				</td>
			)}
			<td className={colClass}>
				<PriceField
					disabled={isActive}
					book={books[values.condition.order_book[0]]}
					label="Fiyat"
					name="condition.price"
				/>
			</td>
			<td className={colClass}>
				<div className="flex">
					<QuantityField
						disabled={isActive}
						label="Kala"
						name="condition.limit"
					/>
					{activeProfile.simpleRuleForm && (
						<div style={{ width: 120 }}>
							<QuantityField
								disabled={isActive}
								label="Yüzde"
								name="condition.limit_percentage"
							/>
						</div>
					)}
				</div>
			</td>

			{!activeProfile.simpleRuleForm && (
				<>
					<td className={colClass}>
						<Field
							disabled={isActive}
							component="select"
							className={selectStyle}
							name="action.order_book.1"
						>
							<option value="Buy">Al</option>
							<option value="Sell">Sat</option>
							<option value="ShortSell">Açığa Sat</option>
						</Field>
					</td>
					<td className={colClass}>
						<Field
							disabled={isActive}
							name="action.order_book.0"
							component={SymbolField}
						/>
					</td>
					<td className={colClass}>
						<QuantityField
							disabled={isActive}
							label="Miktar"
							name="action.quantity"
						/>
					</td>
					<td className={colClass}>
						<PriceField
							disabled={isActive}
							book={books[values.action.order_book[0]]}
							label="Fiyat"
							name="action.price"
						/>
					</td>
					<td className={colClass}>
						<Field
							disabled={isActive}
							component="select"
							name="action.time_in_force"
							className={selectStyle}
						>
							<option value="3">KİE</option>
							<option value="0">LİM</option>
						</Field>
					</td>
				</>
			)}

			{!activeProfile.simpleRuleForm && (
				<td className={colClass}>
					<Field
						component="select"
						className={selectStyle}
						name="action.account"
						disabled={isActive}
					>
						{allowedAccounts.map((account, i) => (
							<option key={`${i}`} value={`${i}`}>
								{stringifyAccountId(account.id)}
							</option>
						))}
					</Field>
				</td>
			)}

			{activeProfile.simpleRuleForm && <td />}

			<td className={colClass}>
				{isActive ? (
					<button
						className={classNames(
							"font-bold border bg-gray-300 border-gray-500 block w-full rounded-md hover:bg-gray-400 active:bg-gray-500 transition",
							activeProfile.simpleRuleForm
								? "py-0.5"
								: "pb-1 pt-1.5"
						)}
						onClick={onDeactivate}
					>
						DURDUR
					</button>
				) : (
					<form onSubmit={handleSubmit} className="flex">
						<button
							className={classNames(
								"font-bold border bg-white border-gray-500 block flex-grow rounded-md hover:bg-gray-100 active:bg-gray-200 transition",
								activeProfile.simpleRuleForm
									? "py-0.5"
									: "pb-1 pt-1.5"
							)}
							type="submit"
						>
							BAŞLAT
						</button>
					</form>
				)}
			</td>
		</tr>
	);
};

export default reduxForm({
	enableReinitialize: true,
})(RuleForm);