import React from "react";
import { activeProfileId } from "../modules/profiles";

const options = [
	{ user: "yigit", name: "A1 Capital" },
	{ user: "ahlatci", name: "Ahlatçı Yatırım" },
	{ user: "yfas", name: "Yatırım Finansman" },
];

const BrokerageSwitch = () => {
	return (
		<div className="p-1.5">
			<div className="flex text-sm overflow-hidden rounded border">
				{options.map((brokerage) => (
					<a
						href={`/?user=${brokerage.user}`}
						className={
							activeProfileId === brokerage.user
								? "bg-blue-200 text-blue-900 border-b-2 flex-1 text-center border-gray-300 font-bold py-1"
								: "bg-gray-50 border-b-2 flex-1 text-center border-gray-200 py-1"
						}
					>
						{brokerage.name}
					</a>
				))}
			</div>
		</div>
	);
};

export default BrokerageSwitch;