import React, { useState } from "react";
import DeleteIcon from "@material-ui/icons/Delete";
import api from "../../api";
import Button from "../Button";
import ReplaceSentOrderForm from "../ReplaceSentOrderForm";
import Modal, { ModalHeader } from "../Modal";
import { canCancel } from "../../modules/sentOrders";
import SnackbarUtils from "../../modules/notifications";

const Actions = ({ order }) => {
	const [showReplaceForm, setShowReplaceForm] = useState(null);
	const canCancelOrder = canCancel(order);

	const startIcon = <DeleteIcon style={{ fontSize: 18 }} />;

	const handleOpenReplace = () => setShowReplaceForm(order);

	const handleCancel = () => {
		if (window.confirm("Emri iptal etmek istediğinize emin misiniz?")) {
			// If the time is between 09:55:00 and 10:00:00, we should set a timer to do it on 10:00:02
			// Otherwise, we should send it immediately
			const now = new Date();
			const isBetween = now.getHours() === 9 && now.getMinutes() >= 50;
			const delay = isBetween
				? new Date(
						now.getFullYear(),
						now.getMonth(),
						now.getDate(),
						10,
						0,
						2
				  ) - now
				: 0;

			if (delay) {
				SnackbarUtils.warning(
					`Emir iptali alındı. 10:00'da borsaya iletilecek.`
				);
			}

			setTimeout(() => {
				api.send("SentOrderCancel", order);
			}, delay);
		}
	};

	const handleCloseReplaceForm = () => setShowReplaceForm(null);

	const handleReplace = (values) => {
		api.send("ReplaceSentOrder", [
			values.order_book_id,
			values.initial_token,
			values.price,
			values.quantity,
		]);

		setShowReplaceForm(null);
	};

	return order.reject_code ? (
		<strong className="text-red-600">Hata: {order.reject_code}</strong>
	) : (
		<>
			<Modal show={!!showReplaceForm} onClose={handleCloseReplaceForm}>
				<ModalHeader
					title="Emri Düzenle"
					onClose={handleCloseReplaceForm}
				/>
				<ReplaceSentOrderForm
					onSubmit={handleReplace}
					initialValues={showReplaceForm || {}}
				/>
			</Modal>

			<Button
				color="white"
				disabled={!canCancelOrder}
				size="xxs"
				onClick={handleOpenReplace}
				className="mr-1"
			>
				Düzenle
			</Button>
			<Button
				disabled={!canCancelOrder}
				startIcon={startIcon}
				color="white"
				size="xxs"
				onClick={handleCancel}
			>
				İptal et
			</Button>
		</>
	);
};

export default Actions;