import React from "react";

const Button = ({ children, color, ...otherProps }) => {
	const colorName = color || "blue";

	return (
		<a
			{...otherProps}
			className={`bg-${colorName}-600 hover:bg-${colorName}-700 active:bg-${colorName}-500 transition text-center m-4 rounded-lg text-white cursor-pointer text-2xl flex h-11 justify-center items-center shadow-md`}
		>
			{children}
		</a>
	);
};

export default Button;