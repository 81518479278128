import React from "react";
import classNames from "classnames";

const buttonColors = {
	lightBlue:
		"border-transparent text-blue-700 bg-blue-100 hover:bg-blue-200 focus:ring-blue-500",
	yellow: "border-transparent text-white bg-yellow-100 hover:bg-yellow-300 focus:ring-yellow-200 border-gray-300 text-gray-700",
	blue: "border-transparent text-white bg-blue-600 hover:bg-blue-700 focus:ring-blue-500",
	red: "border-transparent text-white bg-red-600 hover:bg-red-700 focus:ring-red-500",
	transparentGray:
		"text-gray-700 border-gray-300 hover:bg-gray-50 hover:border-gray-400 hover:text-gray-900 focus:ring-blue-500",
	white: "text-gray-700 border-gray-300 hover:bg-gray-50 hover:border-gray-400 bg-white hover:text-gray-900 focus:ring-blue-500",
};

const buttonSizes = {
	xxs: "px-1.5 py-0.5 text-xs",
	xs: "px-2.5 py-1.5 text-xs",
	sm: "px-3 py-2 text-sm leading-4",
	md: "px-4 py-2 text-sm",
	lg: "px-4 py-2 text-base",
};

const Button = ({
	color = "blue",
	className,
	onClick,
	type = "button",
	size = "md",
	ringOffsetClass = "ring-offset-gray-900",
	disabled = false,
	loading = false,
	svg = null,
	children,
}) => {
	const isDisabled = disabled || loading;

	return (
		<button
			onClick={onClick}
			type={type}
			disabled={isDisabled}
			className={classNames(
				"inline-flex items-center justify-center border font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 text-center",
				isDisabled && "cursor-not-allowed opacity-50",
				ringOffsetClass,
				buttonSizes[size],
				buttonColors[color],
				className
			)}
		>
			{svg}
			{loading ? "Yükleniyor..." : children}
		</button>
	);
};

export default Button;
