import React from "react";
import { reduxForm, Field, FieldArray, getFormValues, reset } from "redux-form";
import QuantityField from "../OrderBooks/QuantityField";
import PriceField from "../OrderBooks/PriceField";
import { Button, Dialog, DialogTitle } from "@material-ui/core";
import SymbolField from "../SymbolField";
import { useDispatch, useSelector } from "react-redux";
import books from "../../data";
import api from "../../api";
import { selectStyle } from "../RuleForm";
import { allowedAccounts } from "../../modules/profiles";
import Side from "../../components/Side";
import { stringifyAccountId } from "../../data/accounts";
import { formatPrice, formatQuantity } from "../../modules/formatter";

export const required = (value) => (value ? undefined : "Required");

const Orders = ({ fields }) => {
	const values = useSelector(getFormValues("morning-orders"));

	return (
		<>
			<div className="bg-gray-50 pb-2 border-b">
				<DialogTitle>Yeni Emir</DialogTitle>
				<table className="w-full text-base">
					<thead>
						<tr>
							<th style={{ width: 120 }}>Hesap</th>
							<th style={{ width: 126 }}>Kod</th>
							<th>Tür</th>
							<th>Miktar</th>
							<th>Fiyat</th>
							<th />
						</tr>
					</thead>
					<tbody>
						{fields.map((member, index) => (
							<tr
								className={
									values.orders[index].order_book_id &&
									(values.orders[index].side === "Buy"
										? "bg-green-100"
										: values.orders[index].side ===
										  "ShortSell"
										? "bg-red-200"
										: "bg-red-100")
								}
								key={index}
							>
								<td style={{ width: 120 }} className="pl-4">
									<Field
										component="select"
										className={selectStyle}
										name={`${member}.account`}
										style={{ height: 34 }}
									>
										<option value="">Hesap</option>
										{allowedAccounts.map((account, i) => (
											<option key={`${i}`} value={`${i}`}>
												{stringifyAccountId(account.id)}
											</option>
										))}
									</Field>
								</td>
								<td>
									<div style={{ width: 120, marginLeft: 6 }}>
										<Field
											name={`${member}.order_book_id`}
											component={SymbolField}
										/>
									</div>
								</td>
								<td className="p-2" style={{ width: 130 }}>
									<Field
										component="select"
										name={`${member}.side`}
										className={selectStyle}
										style={{ height: 34 }}
									>
										<option value="Buy">Al</option>
										<option value="Sell">Sat</option>
										<option value="ShortSell">
											Açığa Sat
										</option>
									</Field>
								</td>
								<td className="p-2" style={{ width: 150 }}>
									<QuantityField
										name={`${member}.quantity`}
										style={{ height: 34 }}
									/>
								</td>
								<td style={{ width: 120 }}>
									<PriceField
										name={`${member}.price`}
										style={{ height: 34 }}
									/>
								</td>
								<td className="px-4">
									<Button
										type="submit"
										color="primary"
										variant="contained"
										size="small"
										fullWidth
									>
										Kaydet
									</Button>
								</td>
							</tr>
						))}
					</tbody>
				</table>
			</div>
		</>
	);
};

const Form = ({ handleSubmit }) => (
	<form className="morning-orders" onSubmit={handleSubmit}>
		<FieldArray name="orders" component={Orders} />
	</form>
);

const ConnectedForm = reduxForm({ form: "morning-orders" })(Form);

const MorningOrdersForm = ({ onClose }) => {
	const dispatch = useDispatch();
	const savedOrders = useSelector((state) => state.morningOrders);
	const morningOrders = useSelector((state) => {
		let final = [];

		if (final.length === 0 || final[final.length - 1].order_book_id) {
			for (let i = 0; i < 1; i++) {
				final.push({ side: "Buy" });
			}
		}

		return final;
	});

	const handleSubmit = (values) => {
		const { orders } = values;

		let list = [];

		orders
			.filter((el) => el.order_book_id && el.quantity && el.price)
			.forEach((el) => {
				const id = (Math.random() + 1).toString(36).substring(7);
				console.log(el);

				list.push({
					id,
					account_id: allowedAccounts[el.account],
					order_book_id: parseInt(el.order_book_id),
					price: el.price,
					quantity: el.quantity,
					side: el.side,
					time_in_force: 0,
				});
			});

		// Save single order
		if (list.length > 0) {
			api.send("AddMorningOrder", list[0]);
			dispatch(reset("morning-orders"));
			document.querySelector("[name='orders[0].account']").focus();
		}
	};

	return (
		<Dialog maxWidth="xl" open onClose={onClose}>
			<ConnectedForm
				onSubmit={handleSubmit}
				initialValues={{ orders: morningOrders }}
			/>

			<DialogTitle>Kaydedilmiş Emirler</DialogTitle>
			{savedOrders.length === 0 ? (
				<div className="px-4 pb-4">Kaydedilmiş emir bulunamadı</div>
			) : (
				<table className="w-full text-base text-right">
					<tbody>
						{savedOrders.map((order, index) => (
							<tr
								className={
									order.order_book_id &&
									(order.side === "Buy"
										? "bg-green-100"
										: order.side === "ShortSell"
										? "bg-red-200"
										: "bg-red-100")
								}
								key={index}
							>
								<td
									style={{ width: 120 }}
									className="text-left pl-4"
								>
									{stringifyAccountId(order.account_id.id)}
								</td>
								<td
									className="text-left"
									style={{ width: 126 }}
								>
									{books[order.order_book_id]?.label ??
										order.order_book_id}
								</td>
								<td className="p-2" style={{ width: 130 }}>
									<Side side={order.side} />
								</td>
								<td className="p-2" style={{ width: 150 }}>
									{formatQuantity(order.quantity)}
								</td>
								<td style={{ width: 120 }}>
									{formatPrice(
										order.order_book_id,
										order.price
									)}
								</td>
								<td className="px-4">
									<Button
										onClick={() =>
											api.send(
												"RemoveMorningOrder",
												order.id
											)
										}
										color="primary"
										variant="contained"
										size="small"
										fullWidth
									>
										Sil
									</Button>
								</td>
							</tr>
						))}
					</tbody>
				</table>
			)}
		</Dialog>
	);
};

export default MorningOrdersForm;