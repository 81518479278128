import React, { useState } from "react";
import classNames from "classnames";
import RemoveIcon from "@material-ui/icons/Close";
import books from "../../data";
import Levels from "./Levels";
import ManualOrderForm from "../ManualOrderForm";
import { submitManualOrder } from "../../modules/manualOrderForm";
import Modal, { ModalHeader } from "../Modal";
import { activeProfile, activeProfileId } from "../../modules/profiles";
import { Menu } from "@headlessui/react";
import { getAccountSuggestion } from "../SentOrders/AutoExitStatus";
import LevelsWithTriggers from "./LevelsWithTriggers";

const Title = ({
	id,
	account,
	canRemove,
	onRemove,
	showLevels,
	setShowLevels,
	showLevelsWithTriggers,
	setShowLevelsWithTriggers,
	rowSpan,
}) => {
	const [showManualOrderForm, setShowManualOrderForm] = useState(null);

	const handleManualOrderFormClose = () => setShowManualOrderForm(null);

	const label = books[id].label;

	const openManualOrderForm = (side) => {
		// Open popup for /order
		window.open(
			`/order?user=${activeProfileId}&side=` +
				side +
				"&order_book_id=" +
				id,
			`order-${+new Date()}`,
			"width=250,height=445"
		);
	};

	const buttons = (
		<div className="flex rounded-md overflow-hidden h-8 shadow-sm border border-gray-300">
			<button
				className="flex-1 font-bold bg-gray-900 text-white"
				onClick={() => setShowLevels(true)}
			>
				D
			</button>
			<button
				className="flex-1 font-bold bg-green-600 text-white"
				onClick={() => openManualOrderForm("Buy")}
			>
				A
			</button>
			<button
				className="flex-1 font-bold bg-red-600 text-white"
				onClick={() => openManualOrderForm("Sell")}
			>
				S
			</button>
			<button
				className="flex-1 font-bold bg-red-900 text-white"
				onClick={() => openManualOrderForm("ShortSell")}
			>
				AS
			</button>
		</div>
	);

	return (
		<>
			{showLevelsWithTriggers && (
				<LevelsWithTriggers
					id={id}
					onClose={() => setShowLevelsWithTriggers(false)}
				/>
			)}

			{showLevels && (
				<Levels id={id} onClose={() => setShowLevels(false)} />
			)}

			<Modal
				show={!!showManualOrderForm}
				onClose={handleManualOrderFormClose}
			>
				<ModalHeader
					onClose={handleManualOrderFormClose}
					title={`${books[id].label} Yeni Emir`}
				/>
				<ManualOrderForm
					onSubmit={(values) => {
						submitManualOrder(values);
						setShowManualOrderForm(null);
					}}
					initialValues={{
						time_in_force: "0",
						side: showManualOrderForm,
						order_book_id: id,
						account: getAccountSuggestion(id, account),
					}}
				/>
			</Modal>

			<td
				rowSpan={rowSpan}
				className={classNames(
					"w-3 p-0 text-gray-500 hover:text-gray-900 transition",
					canRemove
						? "bg-gray-100 cursor-pointer text-gray-200"
						: "bg-gray-300 cursor-not-allowed"
				)}
				onClick={() => {
					if (canRemove) onRemove();
				}}
				align="center"
			>
				<RemoveIcon />
			</td>
			<td
				rowSpan={rowSpan}
				className={classNames(
					"px-3 font-bold text-lg transition",
					activeProfile.simpleRuleForm && "bg-gray-100"
				)}
			>
				{activeProfile.simpleRuleForm ? (
					<div className="text-center">
						<div className="text-lg">{label}</div>
						<div>{buttons}</div>
					</div>
				) : (
					<div className="relative">
						<Menu>
							{({ open }) => (
								<>
									<Menu.Button
										className={classNames(
											"flex flex-row focus:outline-none justify-center w-full",
											open &&
												"bg-white text-gray-900 shadow-lg rounded-t-md"
										)}
									>
										<strong>{label}</strong>
										<svg
											className="-mr-1 ml-1 h-6 w-6 mt-0.5"
											xmlns="http://www.w3.org/2000/svg"
											viewBox="0 0 20 20"
											fill="currentColor"
											aria-hidden="true"
										>
											<path
												fillRule="evenodd"
												d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
												clipRule="evenodd"
											/>
										</svg>
									</Menu.Button>
									<Menu.Items className="origin-top-left absolute left-0 right-0 rounded-b-md shadow-lg bg-white z-20 focus:outline-none overflow-hidden">
										<Menu.Item>
											{() => (
												<button
													className="block w-full text-sm font-bold focus:outline-none text-left px-3 py-1 text-white bg-gray-900"
													onClick={(e) =>
														e.metaKey
															? setShowLevelsWithTriggers(
																	true
															  )
															: setShowLevels(
																	true
															  )
													}
												>
													Derinlik
												</button>
											)}
										</Menu.Item>
										<Menu.Item>
											{() => (
												<button
													className="block w-full text-sm font-bold focus:outline-none text-left px-3 py-1 text-white bg-green-600"
													onClick={() =>
														setShowManualOrderForm(
															"Buy"
														)
													}
												>
													Al
												</button>
											)}
										</Menu.Item>
										<Menu.Item>
											{() => (
												<button
													className="block w-full text-sm font-bold focus:outline-none text-left px-3 py-1 text-white bg-red-600"
													onClick={() =>
														setShowManualOrderForm(
															"Sell"
														)
													}
												>
													Sat
												</button>
											)}
										</Menu.Item>
										<Menu.Item>
											{() => (
												<button
													className="block w-full text-sm font-bold focus:outline-none text-left px-3 py-1 text-white bg-red-900"
													onClick={() =>
														setShowManualOrderForm(
															"ShortSell"
														)
													}
												>
													Açığa Sat
												</button>
											)}
										</Menu.Item>
									</Menu.Items>
								</>
							)}
						</Menu>
					</div>
				)}
			</td>
		</>
	);
};

export default Title;