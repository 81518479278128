export const LEVEL_DETAILS_UPDATE = "LEVEL_DETAILS_UPDATE";

const initialState = {};

export const updateLevelDetails = (id, side, price, data) => (dispatch) => {
	dispatch({
		type: LEVEL_DETAILS_UPDATE,
		payload: { id, side, price, data },
	});
};

export default function levelDetailsReducer(state = initialState, action) {
	switch (action.type) {
		case LEVEL_DETAILS_UPDATE:
			return {
				...state,
				[action.payload.id]: {
					...state[action.payload.id],
					[action.payload.side]: {
						[action.payload.price]: action.payload.data,
					},
				},
			};
		default:
			return state;
	}
}
