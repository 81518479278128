import {
	TableContainer,
	Dialog,
	DialogTitle,
	Table,
	TableHead,
	TableBody,
	TableCell,
	TableRow,
} from "@material-ui/core";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import api from "../../api";
import books from "../../data";
import { allowedAccounts } from "../../modules/profiles";
import Form from "./Form";

const Rahsans = ({ onClose }) => {
	const rahsans = useSelector((state) => state.rahsans);
	console.log(rahsans);

	useEffect(() => api.send("RahsanList"), []);

	const handleRemove = (rahsan) => {
		api.send("RahsanStop", rahsan.ruchan_book_id);
		api.send("RahsanList");
	};

	const handleCreate = (values) => {
		api.send("RahsanStart", [
			parseInt(values.ruchan_book_id),
			parseInt(values.hisse_book_id),
			values.oran,
			values.bedel,
			parseInt(values.spread),
			allowedAccounts[parseInt(values.account)],
		]);

		api.send("RahsanList");
	};

	return (
		<Dialog maxWidth="xl" open onClose={onClose}>
			<DialogTitle>Rüçhanlar</DialogTitle>
			<TableContainer className="w-full">
				<Table size="small" stickyHeader>
					<TableHead>
						<TableRow>
							<TableCell>Rüçhan</TableCell>
							<TableCell>Hisse</TableCell>
							<TableCell>Oran</TableCell>
							<TableCell>Bedel</TableCell>
							<TableCell>Spread</TableCell>
							<TableCell />
						</TableRow>
					</TableHead>
					<TableBody>
						{rahsans.map((rahsan, i) => (
							<TableRow key={i}>
								<TableCell>
									{books[rahsan.ruchan_book_id].label}
								</TableCell>
								<TableCell>
									{books[rahsan.hisse_book_id].label}
								</TableCell>
								<TableCell>{rahsan.oran}</TableCell>
								<TableCell>{rahsan.bedel} </TableCell>
								<TableCell>{rahsan.spread} </TableCell>
								<TableCell align="right">
									<button
										className="text-xs py-0.5 px-1 bg-red-500 text-white rounded-md mx-0.5 cursor-pointer"
										onClick={() => handleRemove(rahsan)}
									>
										Sil
									</button>
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>

			<div className="bg-blue-50 p-2">
				<Form onSubmit={handleCreate} />
			</div>
		</Dialog>
	);
};

export default Rahsans;
