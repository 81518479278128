export const LEVELS_WITH_TRIGGERS_UPDATE = "LEVELS_WITH_TRIGGERS_UPDATE";

const initialState = {};

export const updateLevelsWithTriggers =
	(
		id,
		buyLevels,
		sellLevels,
		buyLastExecutionPrice,
		sellLastExecutionPrice,
		buyIsActive,
		sellIsActive
	) =>
	(dispatch) => {
		const buy = buyLevels
			.filter((row) => row[1] > 0)
			.sort((a, b) => b[0] - a[0]);

		const sell = sellLevels
			.filter((row) => row[1] > 0)
			.sort((a, b) => a[0] - b[0]);

		dispatch({
			type: LEVELS_WITH_TRIGGERS_UPDATE,
			payload: {
				id,
				data: {
					buy,
					sell,
					buyLastExecutionPrice,
					sellLastExecutionPrice,
					buyIsActive,
					sellIsActive,
				},
			},
		});
	};

export default function levelsReducer(state = initialState, action) {
	switch (action.type) {
		case LEVELS_WITH_TRIGGERS_UPDATE:
			return { ...state, [action.payload.id]: action.payload.data };
		default:
			return state;
	}
}
