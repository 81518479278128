import React from "react";
import { Field, getFormValues, reduxForm, submit } from "redux-form";
import PriceField from "./OrderBooks/PriceField";
import { selectStyle } from "./RuleForm/index";
import { useDispatch, useSelector } from "react-redux";
import Button from "./Button";
import books from "../data";
import { stringifyAccountId } from "../data/accounts";
import { allowedAccounts } from "../modules/profiles";
import SymbolField from "./SymbolField";
import QuantityField from "./OrderBooks/QuantityField";

const Row = ({ children, label }) => (
	<div className="mb-4">
		<label
			htmlFor="quantity"
			className="block text-sm font-semibold text-gray-700 mb-1"
		>
			{label}
		</label>

		{children}
	</div>
);

const AutoExitForm = ({ order }) => {
	const dispatch = useDispatch();
	const values = useSelector(getFormValues("auto-exit-form"));

	const handleSubmitConfirmation = (event) => {
		event.preventDefault();

		if (window.confirm("Emin misiniz?")) {
			dispatch(submit("auto-exit-form"));
		}
	};

	return (
		<form onSubmit={handleSubmitConfirmation} className="p-4">
			<div className="flex"></div>
			<Row label="Dayanak Varlık">
				<Field name="order_book_id" component={SymbolField} required />
			</Row>
			<Row label="Dayanak Fiyat">
				<PriceField
					book={books[values.order_book_id]}
					name="price"
					required
					autoFocus
				/>
			</Row>
			<Row label="Limit">
				<QuantityField name="limit" />
			</Row>
			<Row label="Hesap">
				<Field
					component="select"
					className={selectStyle}
					name="account"
				>
					{allowedAccounts.map((account, i) => (
						<option key={`${i}`} value={`${i}`}>
							{stringifyAccountId(account.id)}
						</option>
					))}
				</Field>
			</Row>

			{order && (
				<Row
					label={`Tüm ${books[order.order_book_id].label} ${
						order.side === "Buy"
							? "alış"
							: order.side === "ShortSell"
							? "açığa satış"
							: "satış"
					} emirlerini bu değere göre ayarla`}
				>
					<Field
						component="input"
						type="checkbox"
						className="form-checkbox"
						name="setup_all"
					/>
				</Row>
			)}

			<Button className="w-full" type="submit">
				Kaydet
			</Button>
		</form>
	);
};

export default reduxForm({
	form: "auto-exit-form",
	enableReinitialize: true,
})(AutoExitForm);
