export const LEVELS_UPDATE = "LEVELS_UPDATE";

const initialState = {};

export const updateLevels = (id, buyLevels, sellLevels) => (dispatch) => {
	const buy = buyLevels
		.filter((row) => row[1] > 0)
		.sort((a, b) => b[0] - a[0])
		.slice(0, 13);

	const sell = sellLevels
		.filter((row) => row[1] > 0)
		.sort((a, b) => a[0] - b[0])
		.slice(0, 13);

	dispatch({ type: LEVELS_UPDATE, payload: { id, data: { buy, sell } } });
};

export default function levelsReducer(state = initialState, action) {
	switch (action.type) {
		case LEVELS_UPDATE:
			return { ...state, [action.payload.id]: action.payload.data };
		default:
			return state;
	}
}
